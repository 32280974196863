import { authHeader, handleResponse } from '../helpers';
export const WarrantyRegistrationReportServices = {
    fetchWarrantyData,
    approveWarranty,
    rejectWarranty,
    fetchWarrantyDataRpt,
    claimWarranty,
    fetchWarrantyDataClaim,
    fetchWarrantyDataReapprove,
    WarrantyReaprove,
    getWaByQrScan,
    fetchWarrantyRejectDataRpt

};


function getWaByQrScan(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/getWaByQrScan?Qrdata=${data}`, requestOptions)
        .then(handleResponse);
}
function fetchWarrantyData(data) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/GetWarranty`, requestOptions)
        .then(handleResponse);
}

function fetchWarrantyDataRpt(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/GetWarrantyReport`, requestOptions)
        .then(handleResponse);
}

function fetchWarrantyRejectDataRpt(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/GetWarrantyRejectReport`, requestOptions)
        .then(handleResponse);
}
function fetchWarrantyDataClaim(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/GetWarrantyClaim`, requestOptions)
        .then(handleResponse);
}
function fetchWarrantyDataReapprove(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/GetWarrantyReapprove`, requestOptions)
        .then(handleResponse);
}

function approveWarranty(WarrantyId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/ApproveWarranty/${WarrantyId}`, requestOptions)
        .then(handleResponse);
}

function claimWarranty(WarrantyId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/ClaimWarranty/${WarrantyId}`, requestOptions)
        .then(handleResponse);
}


function WarrantyReaprove(WarrantyId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/WarrantyReapprove/${WarrantyId}`, requestOptions)
        .then(handleResponse);
}

function rejectWarranty(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/RejectWarranty/${data.WarrantyId}?reason=${data.Reason}`, requestOptions)
        .then(handleResponse);
}