import { GiftGenerateConstants } from "../constants/GiftGenerateConstants";

export function GiftGenerate(state = {}, action) {
    switch (action.type) {
        case GiftGenerateConstants.LOADING_REQUEST:
            return {
                ...state,
                loading: action.isbool
            };
        case GiftGenerateConstants.DIALOGUE_REQUEST:
            return {
                ...state,
                showDialogue: action.isbool
            };
        case GiftGenerateConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GiftGenerateConstants.GETALL_SUCCESS:
            return {
                ...state,
                TableData: action.datas,
                loading: false
            };
        case GiftGenerateConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case GiftGenerateConstants.GETROLEDDL_SUCCESS:
            return {
                ...state,
                quarterDDL: action.datas
            };
        case GiftGenerateConstants.GETROLEDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
