import React, { Component } from "react";
import { connect } from "react-redux";
import { OrderBookingActions } from "../../actions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "@material-ui/core";
import tableRequest from "../../models/tableRequest";
import { Dropdown } from "primereact/dropdown";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
class SODetails extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.tblResponse.type = 1;
    this.tblResponse.SortColumn = "InsertDate";
    this.ref = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.state = {
      file: [null],
      first: 0,
      SelectedOption: 1,
      Options: [
        { label: "All", value: 1 },
        { label: "Today", value: 2 },
        { label: "Last 7 Days", value: 3 },
        { label: "Last 30 Days", value: 4 },
      ],
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      fileName: "Choose Excel file...",
      formError: {
        file: "",
      },
    };
  }

  componentDidMount() {
    const userDetails = sessionStorage.getItem("user");
    const userdetailsObject = JSON.parse(userDetails);
    this.setState({
      UserId: userdetailsObject.UserId,
    });
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (this.props.logData) {
      if (prevProps.logData !== this.props.logData) {
        // this.props.myProp has a different value
        if (this.props.logData) {
          var e = this.props.logData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "InsertDate"
        ? "InsertDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.props.fetchApi(this.tblResponse);
  }

  handleChange(event) {
    ;
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "file":
        this.setState({
          file: event.target.files[0],
          fileName: event.target.files[0].name,
        });
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  resetForm() {
    this.setState({
      file: [null],
      fileName: "Choose Excel file...",
      formError: {
        file: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  // Upload Submit Form
  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      var data = new FormData();
      data.append(
        "Inputfile",
        document.querySelector('input[type="file"]').files[0]
      );
      data.append("CreatedBy", this.state.UserId);
      this.props.UploadSODetails(data, this.tblResponse);
      setTimeout(() => {
        this.resetForm();
      }, 1000);
    }
  }

  DropChange(event) {
    this.handleChange(event);
    this.tblResponse.type = event.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //Slider Validate Form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "file":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.file = "Please Select Excel file.";
        } else errors.file = "";
        break;
      default:
        break;
    }

    return IsValid;
  }

  render() {
    const header = (
      <div className="table-header row">
        <div className="col-md-6">
          <h4>Insert Log</h4>
        </div>
        <div className="col-md-3">
          <Dropdown
            value={this.state.SelectedOption}
            onChange={(e) => {
              this.DropChange(e);
            }}
            options={this.state.Options}
            optionValue="value"
            optionLabel="label"
            className="form-control"
            placeholder="Select"
            name="SelectedOption"
          />
        </div>
        <div className="col-md-3 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    );
    return (
      <>
        <div className="mt10 mb10">
          <h2 className="text-center">
            Insert Sales Order Details (Using Excel)
          </h2>
          <div
            style={{
              marginTop: 20,
            }}
            className="row centerAlign"
          >
            <div class="col-md-4 col-xl-4">
              <label class="file">
                <input
                  type="file"
                  name="file"
                  onChange={this.handleChange}
                  id="file"
                  aria-label="File browser example"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ></input>
                <span
                  data-content={this.state.fileName}
                  class="file-custom"
                ></span>
              </label>
              {this.state.formError.file !== "" ? (
                <div className="error mb-2">{this.state.formError.file}</div>
              ) : (
                ""
              )}
              <ExcelFile
                filename={"Sample"}
                element={
                  <Button className="btn btn-warning btn-block centerAlign">
                    Download Sample Excel
                  </Button>
                }
              >
                <ExcelSheet
                  name={"Sample"}
                  data={[
                    {
                      SONo: "Remove this and Put values in this column for Sales Order Number",
                      OrderNo:
                        "Remove this and Put values in this column for Order Booking Number",
                      DealerCode:
                        "Remove this and Put values in this column for Dealer Code",
                    },
                  ]}
                >
                  <ExcelColumn label="Order No" value="OrderNo" />
                  <ExcelColumn label="SO No" value="SONo" />
                  <ExcelColumn label="Dealer Code" value="DealerCode" />
                </ExcelSheet>
              </ExcelFile>
              <button
                type="button"
                style={{ marginTop: 5 }}
                className="btn btn-warning btn-block centerAlign"
                onClick={this.submitForm}
              >
                Upload
              </button>
            </div>
          </div>
          <div className="card mt-3">
            <DataTable
              value={this.props.logData}
              scrollable
              responsive={true}
              globalFilter={this.state.globalFilter}
              header={header}
              emptyMessage="No data found."
              sortMode="multiple"
              paginator={true}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={this.state.rows}
              style={{ width: "100%" }}
              rowsPerPageOptions={[5, 10, 20]}
              totalRecords={parseInt(this.state.totalRecords)}
              lazy={true}
              first={this.state.first}
              onPage={this.onPage}
              onSort={this.onSort}
              loading={this.props.loading}
              ref={(el) => {
                this.dt = el;
              }}
            >
              <Column field="UserName" header="InsertedBy" sortable></Column>
              <Column
                field="TotalInsertions"
                header="Total Insertions"
                sortable
              ></Column>
              <Column field="InsertDate" header="Insert Date" sortable></Column>
              {this.state.IsEdit === true ? (
                <Column
                  field="Action"
                  header="Action"
                  style={{ width: "120px" }}
                  body={this.actionBodyTemplate}
                ></Column>
              ) : (
                <Column
                  field="Action"
                  header="Action"
                  style={{ width: "120px", display: "none" }}
                ></Column>
              )}
            </DataTable>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logData:
      state.OrderBooking.logData == (null || undefined)
        ? null
        : state.OrderBooking.logData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHideShow: (isbool) => {
      dispatch(OrderBookingActions.onHideShow(isbool));
    },
    UploadSODetails: (obj, tblResponse) => {
      dispatch(OrderBookingActions.UploadSODetails(obj, tblResponse));
    },
    fetchApi: (obj) => {
      dispatch(OrderBookingActions.GetSOInsertLog(obj));
    },
    loadingData: (isLoding) =>
      dispatch(OrderBookingActions.loadingData(isLoding)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SODetails);
