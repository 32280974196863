import { authHeader, handleResponse } from "../helpers";
export const FeedbackRptService = {
  fetchFeedbackData,
};

function fetchFeedbackData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}FeedbackRpt/GetFeedbackReport`,
    requestOptions
  ).then(handleResponse);
}
