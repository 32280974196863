import { GiftGenerateService } from '../services/GiftGenerateService';
import { GiftGenerateConstants } from '../constants/GiftGenerateConstants';
import { alertActions } from '.';
import { history } from '../helpers';

export const GiftGenerateActions = {
    getAll,
    ApproveRedeem,
    CancelRedeem,
    createRedemption,
    UserData,
    GetUserPoints,
    inActiveRow,
    onHideShow,
    onHideShoww,
    onHideclose,
    loadingData,
    onHideShowIsActive
};

function getAll(data) {
    return dispatch => {
        dispatch(request());
        GiftGenerateService.fetchSchemeData(data).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: GiftGenerateConstants.GETALL_REQUEST } }
    function success(datas) { return { type: GiftGenerateConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: GiftGenerateConstants.GETALL_FAILURE, error } }
}

function ApproveRedeem(data) {
    return dispatch => {
        dispatch(request(data));

        GiftGenerateService.ApproveRedeem(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(onHideShowIsActive(false));
                        dispatch(onHideShoww(false));
                        dispatch(onHideclose(false));
                        
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: GiftGenerateConstants.APPROVE_REQUEST, data } }
    function success(data) { return { type: GiftGenerateConstants.APPROVE_SUCCESS, data } }
    function failure(error) { return { type: GiftGenerateConstants.APPROVE_FAILURE, error } }
};


function CancelRedeem(data) {
    return dispatch => {
        dispatch(request(data));

        GiftGenerateService.CancelRedeem(data).then(
            response => {
                //history.push('/');                
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShowIsActive(false));
                        dispatch(onHideShoww(false));
                        dispatch(onHideclose(false));
                      
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: GiftGenerateConstants.CANCEL_REQUEST, data } }
    function success(data) { return { type: GiftGenerateConstants.CANCEL_SUCCESS, data } }
    function failure(error) { return { type: GiftGenerateConstants.CANCEL_FAILURE, error } }
};

function createRedemption(data) {
    return dispatch => {
        dispatch(request(data));

        GiftGenerateService.createRedemption(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(onHideShow(false));
                        dispatch(onHideShoww(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: GiftGenerateConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: GiftGenerateConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: GiftGenerateConstants.CREATE_FAILURE, error } }
};

function GetUserPoints(data) {
    return dispatch => {
        dispatch(request(data));

        GiftGenerateService.GetUserPoints(data).then(
            response => {
            
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(loadingData(false));
                        
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: GiftGenerateConstants.GETALL_USERPT_REQUEST, data } }
    function success(data) { return { type: GiftGenerateConstants.GETALL_USERPT_SUCCESS, data } }
    function failure(error) { return { type: GiftGenerateConstants.GETALL_USERPT_FAILURE, error } }
};

function UserData(obj) {
    return dispatch => {
        dispatch(request());

        GiftGenerateService.fetchUserData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: GiftGenerateConstants.GETALL_USER_REQUEST } }
    function success(datas) { return { type: GiftGenerateConstants.GETALL_USER_SUCCESS, datas } }
    function failure(error) { return { type: GiftGenerateConstants.GETALL_USER_FAILURE, error } }
}



function inActiveRow(data) {
    return dispatch => {
        dispatch(request());

        GiftGenerateService.inActiveRow(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShowIsActive(false));
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: GiftGenerateConstants.ISACTIVE_REQUEST } }
    function success(data) { return { type: GiftGenerateConstants.ISACTIVE_SUCCESS, data } }
    function failure(error) { return { type: GiftGenerateConstants.ISACTIVE_FAILURE, error } }
};

function onHideShow(isbool) {
    return {
        type: GiftGenerateConstants.DIALOG_REQUEST, isbool
    }
}
function onHideShoww(isbool) {
    return {
        type: GiftGenerateConstants.DIALOGUE_REQUEST, isbool
    }
}

function onHideclose(isbool) {
    return {
        type: GiftGenerateConstants.REJECT_DIALOGUE_REQUEST, isbool
    }
}

function loadingData(isbool) {
    return {
        type: GiftGenerateConstants.LOADING_REQUEST, isbool
    }
}

function onHideShowIsActive(isbool) {
    return {
        type: GiftGenerateConstants.DIALOG_ISACTIVE_REQUEST, isbool
    }
}



