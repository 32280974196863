import { TertiaryPackingConstants } from "../constants/TertiaryPackingConstants";
import { TertiaryPackingService } from "../services/TertiaryPackingService";
import { alertActions } from ".";

export const TertiaryPackingActions = {
  getProductByQrScan,
  CartonData,
  updateScannedList,
  saveTertiaryPacking,
  getTertiaryPacking,
  GetTertiaryPackingScan,
  warning,
  alreadyPacked,
};

//fetchCartonData

function getProductByQrScan(data, scannedList) {
  return (dispatch) => {
    dispatch(request(data));

    TertiaryPackingService.getProductByQrScan(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            const resdata = response.ResponseData;
            if (resdata.ProductID > 0) {
              let checkseqNo = scannedList?.filter(
                (y) => y.SeqNo == resdata.SeqNo
              );
              if (checkseqNo && checkseqNo.length > 0) {
                dispatch(warning(response.ResponseMessage));
                dispatch(alertActions.warning("already Scanned"));
                return;
              }
              let IscheckSameProduct = scannedList?.filter(
                (y) => y.ProductID == resdata.ProductID
              );
              if (IscheckSameProduct && IscheckSameProduct.length == 0) {
                dispatch(
                  alertActions.warning("You can pack only same product")
                );
                return;
              }

              dispatch(success(response.ResponseData));
            }
          } else if (response.ResponseCode === "400") {
            ;
            dispatch(warning(response.ResponseMessage));
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: TertiaryPackingConstants.ProductByQrScan_REQUEST, data };
  }
  function success(data) {
    return { type: TertiaryPackingConstants.ProductByQrScan_SUCCESS, data };
  }
  function warning(data) {
    return {
      type: TertiaryPackingConstants.ProductByQrScan_Warning,
      data,
    };
  }
  function failure(error) {
    return { type: TertiaryPackingConstants.ProductByQrScan_FAILURE, error };
  }
}

function CartonData(obj) {
  return (dispatch) => {
    dispatch(request());
    TertiaryPackingService.fetchCartonData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: TertiaryPackingConstants.GETALL_CARTON_REQUEST };
  }
  function success(datas) {
    return { type: TertiaryPackingConstants.GETALL_CARTON_SUCCESS, datas };
  }
  function failure(error) {
    return { type: TertiaryPackingConstants.GETALL_CARTON_FAILURE, error };
  }
}

function updateScannedList(updatedList) {
  return {
    type: TertiaryPackingConstants.UPDATE_SCANNED_REQUEST,
    updatedList,
  };
}

function saveTertiaryPacking(data) {
  return (dispatch) => {
    dispatch(request(data));

    TertiaryPackingService.saveTertiaryPacking(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(alertActions.success(response.ResponseMessage));
            var scanItems = [];
            updateScannedList(scanItems);
            getTertiaryPacking(scanItems);
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return {
      type: TertiaryPackingConstants.SAVE_TERTIARY_PACKING_RESUEST,
      data,
    };
  }
  function failure(error) {
    return {
      type: TertiaryPackingConstants.SAVE_TERTIARY_PACKING_FAILURE,
      error,
    };
  }
}

function getTertiaryPacking(tblobj) {
  return (dispatch) => {
    dispatch(request());
    TertiaryPackingService.getTertiaryPacking(tblobj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: TertiaryPackingConstants.GETALL_REQUEST };
  }
  function success(data) {
    return { type: TertiaryPackingConstants.GETALL_SUCCESS, data };
  }
  function failure(error) {
    return { type: TertiaryPackingConstants.GETALL_FAILURE, error };
  }
}

//GetTertiaryPackingScan
function GetTertiaryPackingScan(qr, flag, scannedItem) {
  return (dispatch) => {debugger
    dispatch(request());
    TertiaryPackingService.GetTertiaryPackingScan(qr, flag).then(
      (response) => {debugger
        dispatch(loadingData(false));
        if (response.ResponseCode == "200") {
          dispatch(success(response));
        } else {
          ;
          dispatch(warning(response.ResponseMessage));
          //dispatch(alertActions.warning(response.ResponseMessage))
          dispatch(success(response));
        }
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: TertiaryPackingConstants.GETALL_SCAN_REQUEST };
  }
  function success(data) {
    return { type: TertiaryPackingConstants.GETALL_SCAN_SUCCESS, data };
  }
  function failure(error) {
    return { type: TertiaryPackingConstants.GETALL_SCAN_FAILURE, error };
  }
}

function alreadyPacked(isbool) {
  return {
    type: TertiaryPackingConstants.Alreadypacked,
    isbool,
  };
}

function warning(isbool) {
  return {
    type: TertiaryPackingConstants.Warning,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: TertiaryPackingConstants.LOADING_REQUEST,
    isbool,
  };
}
