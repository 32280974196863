import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TextField from "@material-ui/core/TextField";
import { Calendar } from "primereact/calendar";
import {
  alertActions,
  OrderDispatchRptActions,
  OrderDispatchActions,
} from "../../actions";
import { connect } from "react-redux";
import tableRequest from "../../models/tableRequest.js";
import { Toast } from "primereact/toast";
import { CSVLink } from "react-csv";
import { Button } from "primereact/button";
import PrintOrderDispatchService from "../../services/PrintOrderDispatchService";

class OrderDispatchReport extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.PrintOrderDispatchService = new PrintOrderDispatchService();
    this.handleChange = this.handleChange.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.state = {
      loading: false,
      FromDate: new Date(),
      ToDate: new Date(),
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      visible: false,
      sortOrder: "",
      datalist: [],
      IsEdit: true,
      DispatchedTo: "",
      Id: 0,
    };
  }
  componentDidMount() {
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - 7);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.setState({
      FromDate: date,
      ToDate: new Date(),
    });
    this.props.fetchApi(this.tblResponse);
    //this.props.exportingData(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.DispatchList !== this.props.DispatchList) {
      // this.props.myProp has a different value
      if (this.props.DispatchList) {
        var e = this.props.DispatchList;
        this.setState({
          totalRecords: e[0].TotalRows,
        });
      }
    }
  }

  GetDispatch() {
    let ToDt = new Date();
    let FrmDt = new Date();
    FrmDt.setDate(ToDt.getDate() - 7);

    const myFmDt = this.getCurrentDateYYYYMMDD(FrmDt);
    const myToDt = this.getCurrentDateYYYYMMDD(ToDt);

    this.tblResponse.FromDate = myFmDt;
    this.tblResponse.ToDate = myToDt;
    this.props.fetchApi(this.tblResponse);
  }

  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;

    this.props.fetchApi(this.tblResponse);
  }

  getCurrentDateYYYYMMDD(dt) {
    var dd = String(dt.getDate()).padStart(2, "0");
    var mm = String(dt.getMonth() + 1).padStart(2, "0");
    var yyyy = dt.getFullYear();

    dt = yyyy + "/" + mm + "/" + dd;
    return dt;
  }

  handleChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case "FromDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          // this.refs.Messchild.showError("Please select from date.");
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please select from date.",
            life: 3000,
          });

          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please enter only date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.toast.show({
              severity: "error",
              summary: "Error Message",
              detail: "From date must be less than to date.",
              life: 3000,
            });
            // this.refs.Messchild.showError("From date must be less than to date.");
            return;
          }
        }

        break;
      case "ToDate":
        if (value === null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please select to date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.toast.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please enter only date.",
            life: 3000,
          });
          // this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.toast.show({
              severity: "error",
              summary: "Error Message",
              detail: "To date must be greater than from date.",
              life: 3000,
            });
            // this.refs.Messchild.showError("To date must be greater than from date.");
            return;
          }
        }

        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }
  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    // this.setState({
    //     loading: true
    // });
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDt"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  actionBodyTemplate(rowData, column) {
    //to print
    const print = (e) => {
      this.props.getDispatchToPrint(rowData);
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      setTimeout(() => {
        const resdata = this.props.PrintData;
        this.PrintOrderDispatchService.Printpdf(resdata.ResponseData);
      }, 2000);
      ////PRINT SLIP
    };
    return (
      <Button
        className="mr-2"
        icon="pi pi-print"
        label="Print"
        onClick={print}
      />
    );
  }

  // actionBodyTemplate(rowData) {
  //     ;
  //     const print = (e) => {

  //         //console.log(rowData);

  //         //let printDocument = new printDocument();
  //         //this.printDocument(rowData);
  //         //this.printDocument(rowData);
  //
  //         // const editMode = (e) => {
  //         this.setState({

  //             //buttonLabel: "Update",
  //             //formHeading: "Update Mapping",
  //             displayModal: true,
  //             Id: rowData.Id,
  //             DispatchedTo: rowData.DispatchedTo,

  //         });
  //         this.printDocument(rowData);
  //         //console.log(rowData);
  //     };
  //     return (
  //         <Button
  //             className="mr-2"
  //             icon="pi pi-print"
  //             label="Print"
  //             onClick={print}
  //         />
  //     );
  // }

  render() {
    return (
      <div className="datatable-filter-demo">
        <Toast ref={(el) => (this.toast = el)} />
        <div className="row mb-3">
          <div className="col-md-2">
            <h4>Dispatch Report</h4>
          </div>
          <div className="col-md-10">
            <div className="row text-right">
              <div className="col-md-3">
                <Calendar
                  placeholder="From Date"
                  name="FromDate"
                  showIcon="true"
                  value={this.state.FromDate}
                  onChange={this.handleChange}
                ></Calendar>
              </div>
              <div className="col-md-3">
                <Calendar
                  placeholder="To Date"
                  name="ToDate"
                  showIcon="true"
                  value={this.state.ToDate}
                  onChange={this.handleChange}
                ></Calendar>
              </div>
              <div className="col-md-3 text-center ">
                <button
                  type="button"
                  onClick={this.FilterData}
                  className="btn btn-primary p-2 pl-2 pr-2 buttone"
                >
                  Submit
                </button>
                &nbsp;
                <CSVLink
                  data={this.props.List}
                  headers={this.state.headers}
                  filename={"Dispatch report.csv"}
                  target="_blank"
                >
                  <button className="btn btn-success p-2 pr-2 pl-2 buttone">
                    Export
                  </button>
                </CSVLink>
              </div>
              <div className="col-md-3">
                <TextField
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Search"
                  onInput={this.searchFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <DataTable
            value={this.props.DispatchList}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column
              field="DispatchId"
              disabled
              header="Dispatch Number "
              sortable
            ></Column>
            <Column
              field="TotalCarton"
              disabled
              header="Total Carton"
              sortable
            ></Column>
            <Column
              field="CreatedDt"
              disabled
              header="Dispatched Date"
              sortable
            ></Column>
            <Column
              field="userName"
              disabled
              header="Dispatched To"
              sortable
            ></Column>
            {this.state.IsEdit === true ? (
              <Column body={this.actionBodyTemplate} header="Action" />
            ) : (
              <Column
                body={this.actionBodyTemplate}
                style={{ display: "none" }}
                header="Action"
              />
            )}
          </DataTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  ////console.log('state.DispatchReport.items', state.DispatchReport.items);
  return {
    DispatchList: state.Dispatch.items,
    List:
      state.DispatchReport.exportitems == undefined
        ? []
        : state.DispatchReport.exportitems,
    loading: state.verification.loding,
    PrintData:
      state.DispatchReport.printdata == undefined
        ? []
        : state.DispatchReport.printdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(OrderDispatchActions.getAll(obj));
    },
    loadingData: (isLoding) =>
      dispatch(OrderDispatchRptActions.loadingData(isLoding)),
    exportingData: (obj) => dispatch(OrderDispatchRptActions.exportData(obj)),
    getDispatchToPrint: (data) =>
      dispatch(OrderDispatchRptActions.GetDispatchToPrint(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDispatchReport);
