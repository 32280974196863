import { authHeader, handleResponse } from '../helpers';
export const BinMasterService = {
    createBin,   
    updateBin,
    fetchBinData,   
    inActiveRow,
    fetchWarehouseDDL,
    fetchAllBinData
};

async function fetchWarehouseDDL() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}WarehouseMaster/GetwarehouseDDL`, requestOptions);
    return handleResponse(response
        //     return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetRoleDLL/${userId}`, requestOptions)
        //         .then(handleResponse);
        // }
    );
}
async function createBin(data) {;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}BinMaster/CreateLocationMst`, requestOptions);
    return handleResponse(response
        //     return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetRoleDLL/${userId}`, requestOptions)
        //         .then(handleResponse);
        // }
    );
}

// function fetchRoleDLL(userId) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetRoleDLL/${userId}`, requestOptions)
//         .then(handleResponse);
// }

async function updateBin(data) {
;
console.log(data)
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}BinMaster/BinlocationUpdate`, requestOptions);
    return handleResponse(response
        //     return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetRoleDLL/${userId}`, requestOptions)
        //         .then(handleResponse);
        // }
    );
}

async function fetchBinData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}BinMaster/getLocatAlloMst`, requestOptions);
    return handleResponse(response
        //     return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetRoleDLL/${userId}`, requestOptions)
        //         .then(handleResponse);
        // }
    );
}


async function inActiveRow(BinId, isActive) {;
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}BinMaster/InActiveRow/${BinId}/${isActive}`, requestOptions);
    return handleResponse(response
        //     return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetRoleDLL/${userId}`, requestOptions)
        //         .then(handleResponse);
        // }
    );
}


async function fetchAllBinData() {;
    const requestOptions = {
        method: 'GET',
        headers: authHeader() 
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}BinMaster/getGetAllBinMst`, requestOptions);
    return handleResponse(response
        //     return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetRoleDLL/${userId}`, requestOptions)
        //         .then(handleResponse);
        // }
    );
}



