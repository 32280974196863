import { categoryService } from "../services/categoryService";
import { categoryConstants } from "../constants/categoryConstants";
import { alertActions } from ".";

export const categoryActions = {
  getAll,
  getSubAll,
  createCatagory,
  updateCategory,
  openDialog,
  inActiveRow,
  inActiveRow2,
  onHideShow,
  onHideShow2,
  loadingData,
  loadingData2,
  onHideShowIsActive,
  onHideShowIsActive2,
  getCategoryDDL,
  createSubCategory,
  SubUpdateCategory,
  getSubCategoryDDL,
};

function getAll(data) {
  return (dispatch) => {
    dispatch(request());
    categoryService.getCategory(data).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: categoryConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: categoryConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: categoryConstants.GETALL_FAILURE, error };
  }
}


function getSubAll(data) {
  return (dispatch) => {
    dispatch(request());
    categoryService.getSubCategory(data).then(
      (response) => {
        dispatch(loadingData2(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData2(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: categoryConstants.GETSUBALL_REQUEST };
  }
  function success(datas) {
    return { type: categoryConstants.GETSUBALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: categoryConstants.GETSUBALL_FAILURE, error };
  }
}

function createCatagory(data) {
  return (dispatch) => {
    dispatch(request(data));
    categoryService.createCategory(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            ;
            dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: categoryConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: categoryConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: categoryConstants.CREATE_FAILURE, error };
  }
}

function createSubCategory(data) {
  return (dispatch) => {
    dispatch(request(data));
    categoryService.createSubCategory(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            ;
            dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: categoryConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: categoryConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: categoryConstants.CREATE_FAILURE, error };
  }
}



function updateCategory(data) {
  return (dispatch) => {
    dispatch(request(data));
    categoryService.updateCategory(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          ;
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: categoryConstants.UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: categoryConstants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: categoryConstants.UPDATE_FAILURE, error };
  }
}

function SubUpdateCategory(data) {
  return (dispatch) => {
    dispatch(request(data));
    categoryService.SubUpdateCategory(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          ;
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: categoryConstants.UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: categoryConstants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: categoryConstants.UPDATE_FAILURE, error };
  }
}


function inActiveRow(category, isActive) {
  var data = {
    CategoryId: category,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    categoryService.inActiveRow(category, isActive).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: categoryConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: categoryConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: categoryConstants.ISACTIVE_FAILURE, error };
  }
}

function inActiveRow2(category, isActive) {
  var data = {
    SubCatId: category,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    categoryService.inActiveRow2(category, isActive).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: categoryConstants.ISACTIVE2_REQUEST };
  }
  function success(data) {
    return { type: categoryConstants.ISACTIVE2_SUCCESS, data };
  }
  function failure(error) {
    return { type: categoryConstants.ISACTIVE2_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: categoryConstants.DIALOG_REQUEST,
    isbool,
  };
}


function onHideShow2(isbool) {
  return {
    type: categoryConstants.DIALOG_REQUEST2,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: categoryConstants.LOADING_REQUEST,
    isbool,
  };
}
function loadingData2(isbool) {
  return {
    type: categoryConstants.LOADING_REQUEST2,
    isbool,
  };
}
function onHideShowIsActive(isbool) {
  return {
    type: categoryConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}
function onHideShowIsActive2(isbool) {
  return {
    type: categoryConstants.DIALOG_ISACTIVE_REQUEST2,
    isbool,
  };
}

function openDialog(data) {
  return {
    type: categoryConstants.OPEN_DIALOG_REQUEST,
    data,
  };
}

function getCategoryDDL(data, flag) {
  return (dispatch) => {
    dispatch(request());
    categoryService.fetchCategoryDLL(data).then(
      (response) => {
        

        // if (flag == 1) {
        //   response.ResponseData.splice(0, 0, { label: "All", value: "0" });
        // }
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: categoryConstants.GETCATEGORYDDL_REQUEST };
  }
  function success(datas) {
    return { type: categoryConstants.GETCATEGORYDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: categoryConstants.GETCATEGOYRDDL_FAILURE, error };
  }
}


function getSubCategoryDDL(data) {
  return (dispatch) => {
    dispatch(request());
    categoryService.fetchSubCategoryDLL(data).then(
      (response) => {

        // if (flag == 1) {
        //   response.ResponseData.splice(0, 0, { label: "All", value: "0" });
        // }
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: categoryConstants.GETSUBCATEGORYDDL_REQUEST };
  }
  function success(datas) {
    return { type: categoryConstants.GETSUBCATEGORYDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: categoryConstants.GETSUBCATEGORYDDL_FAILURE, error };
  }
}