import { BinMasterConstants } from "../constants";

export function BinMaster(state = {}, action) {
  switch (action.type) {
    case BinMasterConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case BinMasterConstants.DIALOG_REQUEST:
      return {
        ...state,
        showDialog: action.isbool,
      };
    case BinMasterConstants.DIALOG_ISACTIVE_REQUEST:
      return {
        ...state,
        showIsActiveDialog: action.isbool,
      };
    case BinMasterConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BinMasterConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case BinMasterConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case BinMasterConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case BinMasterConstants.CREATE_SUCCESS:
      return {
        ...state,
        items:
          state.items == null ? [action.data] : [action.data, ...state.items],
      };
    case BinMasterConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case BinMasterConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map((item) =>
          item.BinId == action.data.BinId ? { ...item, updating: true } : item
        ),
      };
    case BinMasterConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.BinId === action.data.BinId ? action.data : item
        ),
      };
    case BinMasterConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case BinMasterConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case BinMasterConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.BinId === action.data
            ? {
                BinId: item.BinId,
                RowName: item.RowName,
                RowNo: item.ColNo,
                ColNo: item.ColNo,
                ColAddress: item.ColAddress,
                ColStatus : item.ColStatus,               
                IsActive: item.isActive,
                
              }
            : item
        ),
      };

    case BinMasterConstants.GETWHDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BinMasterConstants.GETWHDDL_SUCCESS:
      return {
        ...state,
        WarehouseDDL: action.datas,
      };
    case BinMasterConstants.GETWHDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

     

    case BinMasterConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case BinMasterConstants.ISACTIVE_REQUEST:
      return {
        ...state,
        activating: true,
      };
    case BinMasterConstants.ISACTIVE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.BinId === action.data.BinId
            ? {
              BinId: item.BinId,
              RowName: item.RowName,
              RowNo: item.ColNo,
              ColNo: item.ColNo,
              ColAddress: item.ColAddress,
              ColStatus : item.ColStatus,               
              IsActive: item.isActive,
              }
            : item
        ),
      };
    case BinMasterConstants.ISACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case BinMasterConstants.GETEXPORT_REQUEST:
      return {
        ...state,
        exporting: true,
      };
    case BinMasterConstants.GETEXPORT_SUCCESS:
      return {
        ...state,
        exportitems: action.datas,
      };
    case BinMasterConstants.GETEXPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

      case BinMasterConstants.GETALLBIN_REQUEST:
        return {
            ...state,
            loading: true
        };
    case BinMasterConstants.GETALLBIN_SUCCESS:
        return {
            ...state,
            Binitems: action.datas
        };
    case BinMasterConstants.GETALLBIN_FAILURE:
    default:
      return state;
  }
}
