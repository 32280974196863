import { LoyaltySchemeRptService } from "../services";
import { LoyaltySchemeRptConstants } from "../constants";
import { alertActions } from ".";

export const LoyaltySchemeRptActions = {
  getAll,
  loadingData,
  exportData,
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());

    LoyaltySchemeRptService.fetchLoyaltySchemeData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));

        var data = response.ResponseData;   //Changes
        if (data != null) {
            data.forEach(function (v) {  delete v.TotalRows;
            delete v.CreatedBy;
            delete v.DocURL;
            delete v.LoyaltySchemeId;
            delete v.ProductID;
            delete v.Schemedescription;
            delete v.UserTypeId;
            delete v.product;
            delete v.Qty;
            delete v.loyaltypnt;
            delete v.IsActive;
            });
        }
        dispatch(successExport(data))
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: LoyaltySchemeRptConstants.GETALL_REQUEST };
  } //Changes
  function success(datas) {
    return { type: LoyaltySchemeRptConstants.GETALL_SUCCESS, datas };
  }
  function successExport(datas) {
    return { type: LoyaltySchemeRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: LoyaltySchemeRptConstants.GETALL_FAILURE, error };
  }
}

function exportData(obj) {;
  return (dispatch) => {
    dispatch(request());
    LoyaltySchemeRptService.fetchLoyaltySchemeData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.CreatedBy;
            delete v.DocURL;
            delete v.LoyaltySchemeId;
            delete v.ProductID;
            delete v.Schemedescription;
            delete v.UserTypeId;
            delete v.product;
            delete v.Qty;
            delete v.loyaltypnt;
            delete v.IsActive;
          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: LoyaltySchemeRptConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: LoyaltySchemeRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: LoyaltySchemeRptConstants.GETEXPORT_FAILURE, error };
  }
}

function loadingData(isbool) {
  return {
    type: LoyaltySchemeRptConstants.LOADING_REQUEST,
    isbool,
  };
}
