import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import Moment from "react-moment";
import { roleMasterActions, alertActions } from "../../actions";
import tableRequest from "../../models/tableRequest.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

class roleMaster extends React.Component {
  constructor(props) {
    super();
    this.tblResponse = new tableRequest();
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.editPermission = this.editPermission.bind(this);
    this.createdDateTemplate = this.createdDateTemplate.bind(this);
    this.updatedDateTemplate = this.updatedDateTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.checkboxIsView = this.checkboxIsView.bind(this);
    this.checkboxIsAdd = this.checkboxIsAdd.bind(this);
    this.checkboxIsEdit = this.checkboxIsEdit.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.onchangeCheckbox = this.onchangeCheckbox.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);

    const UserTypeData = [
      { value: 1, label: "Web" },
      { value: 2, label: "Mobile" },
    ];

    this.state = {
      IsAdd: true,
      IsEdit: true,
      loading: false,
      switchAction: false,
      node: [],
      confirm: false,
      formHeading: "Add Role",
      buttonLabel: "Submit",
      displayConfirmation: false,
      switchAction: false,
      RoleId: 0,
      RoleName: "",
      RoleDesc: "",
      UserTypeId: "",
      UserTypeData: [
        { value: 1, label: "Web" },
        { value: 2, label: "Mobile" },
      ],
      Address: "",
      IsActive: true,
      CreatedDate: null,
      allViewCheck: false,
      allAddCheck: false,
      allEditCheck: false,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      formError: {
        RoleName: "",
        RoleDesc: "",
        UserTypeId: "",
      },
    };
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        } else {
          this.setState({
            IsEdit: true,
          });
        }

        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (this.props.RoleData) {
      if (prevProps.RoleData !== this.props.RoleData) {
        // this.props.myProp has a different value
        if (this.props.RoleData) {
          var e = this.props.RoleData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.RoleId, this.state.switchAction);
    setTimeout(() => {
      this.props.fetchApi(this.tblResponse);
    }, 1000);
  };

  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "IsActive":
        value = event.target.checked;
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  updateRolePermission(event) {
    event.preventDefault();
    let permissionPage = [];
    const permissionArray = this.props.Pages;

    if (permissionArray && permissionArray.length > 0) {
      this.props.updateRolePermission(permissionArray);
    } else {
      this.props.warning("No page is selected");
    }
  }

  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;

    switch (name) {
      case "RoleName":
        if (value.length < 1) {
          IsValid = false;
          errors.RoleName = "Please enter role name.";
        } else errors.RoleName = "";
        break;
      case "UserTypeId":
        if (!value) {
          IsValid = false;
          errors.UserTypeId = "Please select user type.";
        } else errors.UserTypeId = "";
        break;
      case "RoleDesc":
        if (value.length < 1) {
          IsValid = false;
          errors.RoleDesc = "Please enter role remarks.";
        } else errors.RoleDesc = "";
        break;
      default:
        break;
    }

    return IsValid;
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  updatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    }
  }

  createdDateTemplate(rowData) {
    return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
  }

  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const formData = {
        RoleId: this.state.RoleId,
        RoleName: this.state.RoleName,
        RoleDesc: this.state.RoleDesc,
        UserType: this.state.UserTypeId,
        IsActive: this.state.IsActive,
        CreatedDate:
          this.state.RoleId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.RoleId > 0 ? this.getCurrentDateMMDDYYY() : null,
        TotalRows: this.state.totalRecords,
      };

      if (this.state.RoleId == 0) {
        this.props.createRole(formData);
      } else {
        this.props.updateRole(formData);
      }
      setTimeout(() => {
        this.props.fetchApi(this.tblResponse);
      }, 1000);
      if (!this.props.showDialog) {
        this.resetForm();
      }
    }
  }

  resetForm() {
    this.setState({
      RoleId: 0,
      RoleName: "",
      RoleDesc: "",
      UserType: "",
      IsActive: true,
      formError: {
        RoleName: "",
        RoleDesc: "",
        UserType: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  checkAll(event) {
    this.props.updatePageStore(null);
    var ischecked = event.currentTarget.checked;
    var name = event.currentTarget.name;
    var pagelist = this.props.Pages;

    for (let i = 0; i < pagelist.length; i++) {
      pagelist[i][name] = ischecked;
    }
    if (name === "IsView") {
      this.setState({ allViewCheck: ischecked });
    } else if (name === "IsAdd") {
      this.setState({ allAddCheck: ischecked });
    } else if (name === "IsEdit") {
      this.setState({ allEditCheck: ischecked });
    }

    this.props.updatePageStore(pagelist);
  }
  onchangeCheckbox(rowdate, event, key) {
    this.props.updatePageStore(null);
    var ischecked = event.currentTarget.checked;
    var name = event.currentTarget.id;
    var pagelist = this.props.Pages;

    for (let i = 0; i < pagelist.length; i++) {
      if (pagelist[i].PageId === parseInt(name)) {
        pagelist[i][key] = ischecked;
      }
    }

    this.props.updatePageStore(pagelist);
  }

  checkboxIsView(rowData) {
    return (
      <Checkbox
        checked={rowData.IsView}
        onChange={(e) => this.onchangeCheckbox(rowData, e, "IsView")}
        id={rowData.PageId}
        color="primary"
        inputProps={{ "aria-label": "first checkbox" }}
      />
    );
  }
  checkboxIsAdd(rowData) {
    return rowData.IsReport == true ? (
      <Checkbox
        disabled={true}
        id={rowData.PageId}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    ) : rowData.ActionName == "product-configuration" ? (
      <Checkbox
        disabled={true}
        id={rowData.PageId}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    ) : (
      <Checkbox
        onChange={(e) => this.onchangeCheckbox(rowData, e, "IsAdd")}
        checked={rowData.IsAdd}
        id={rowData.PageId}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    );
  }

  checkboxIsEdit(rowData) {
    return rowData.IsReport == true ? (
      <Checkbox
        disabled={true}
        id={rowData.PageId}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    ) : rowData.ActionName == "mapping" ||
      rowData.ActionName == "de-mapping" ||
      rowData.ActionName == "damage" ? (
      <Checkbox
        disabled={true}
        id={rowData.PageId}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    ) : (
      <Checkbox
        onChange={(e) => this.onchangeCheckbox(rowData, e, "IsEdit")}
        checked={rowData.IsEdit}
        id={rowData.PageId}
        color="primary"
        inputProps={{ "aria-label": "thirds checkbox" }}
      />
    );
  }

  statusBodyTemplate(rowData) {
    return (
      <Checkbox
        defaultChecked
        disabled
        checked={rowData.IsActive}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    );
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }

  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add Role" });
  }

  editPermission(rowData, RoleName) {
    const roleId = rowData.currentTarget.value;

    if (rowData.currentTarget.value) {
      this.setState({ RoleName: RoleName });
      this.props.getPages(rowData.currentTarget.value);
    }
  }
  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      this.props.onHideShow(true);
      this.setState({
        formHeading: "Update Role",
        buttonLabel: "Update",
        RoleId: rowData.RoleId,
        RoleName: rowData.RoleName,
        RoleDesc: rowData.RoleDesc,
        UserTypeId: rowData.UserType == "Mobile" ? 2 : 1,
        IsActive: rowData.IsActive,
        CreatedDate: rowData.CreatedDate,
      });
    };
    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        RoleId: rowData.RoleId,
      });
      this.props.onHideShowIsActive(true);
    };

    return (
      <>
        {this.state.IsEdit === true ? (
          <div>
            <Switch
              checked={rowData.IsActive}
              color="primary"
              name={rowData.RoleId}
              onChange={switchHandleChange}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <Button icon="pi pi-pencil" className="mr-2" onClick={editMode} />
            <Button
              disabled={(rowData.UserType == 'Mobile' ? true : false)}
              icon="pi pi-cog"
              value={rowData.RoleId}
              className="p-button-sm"
              onClick={(e) => {
                this.editPermission(e, rowData.RoleName);
              }}
            />
          </div>
        ) : (
          <Button
            icon="pi pi-cog"
            value={rowData.RoleId}
            className="p-button-sm"
            onClick={(e) => {
              this.editPermission(e, rowData.RoleName);
            }}
          />
        )}
      </>
    );
  }

  render() {
    var listPages = this.props.Pages;
    if (listPages) {
      var isAllView = listPages.find((x) => x.IsView == false);
      if (isAllView) {
        this.state.allViewCheck = false;
      } else {
        this.state.allViewCheck = true;
      }

      var isAllAdd = listPages.find((x) => x.IsAdd == false);
      if (isAllAdd) {
        this.state.allAddCheck = false;
      } else {
        this.state.allAddCheck = true;
      }

      var isAllEdit = listPages.find((x) => x.IsEdit == false);
      if (isAllEdit) {
        this.state.allEditCheck = false;
      } else {
        this.state.allEditCheck = true;
      }
    }

    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };
    const header = (
      <div className="table-header row">
        <div className="col-md-3">
          <h4 className="user">User Role</h4>
        </div>
        <div className="col-md-9 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
          {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              icon="pi pi-plus"
              label="Add"
              onClick={() => this.openDialog()}
            />
          ) : (
            <Button
              className="mr-2"
              icon="pi pi-plus"
              label="Add"
              style={{ display: "none" }}
            />
          )}
        </div>
      </div>
    );
    const header2 = (
      <div className="table-header row">
        <div className="col-md-6">
        <h4 className="user">Build Menus List</h4>
        </div>
        <div className="col-md-6 text-right">{this.state.RoleName}</div>
      </div>
    );
    const viewHeading = (
      <div>
        View{" "}
        <Checkbox
          checked={this.state.allViewCheck}
          onChange={(e) => {
            this.checkAll(e);
          }}
          name="IsView"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>
    );

    const addHeading = (
      <div>
        Add{" "}
        <Checkbox
          checked={this.state.allAddCheck}
          onChange={(e) => {
            this.checkAll(e);
          }}
          name="IsAdd"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>
    );

    const updateHeading = (
      <div>
        Update{" "}
        <Checkbox
          checked={this.state.allEditCheck}
          onChange={(e) => {
            this.checkAll(e);
          }}
          name="IsEdit"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>
    );

    return (
      <div className="datatable-filter-demo dash-margin">
        <div className="row">
          <div className="col-md-7">
            <div className="card">
              <DataTable
                value={this.props.RoleData}
                scrollable
                responsive={true}
                globalFilter={this.state.globalFilter}
                header={header}
                emptyMessage="No data found."
                sortMode="multiple"
                paginator={true}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={this.state.rows}
                style={{ width: "100%" }}
                rowsPerPageOptions={[5, 10, 20]}
                totalRecords={parseInt(this.state.totalRecords)}
                lazy={true}
                first={this.state.first}
                onPage={this.onPage}
                onSort={this.onSort}
                loading={this.props.loading}
                ref={(el) => {
                  this.dt = el;
                }}
              >
                <Column field="RoleName"   style={{ width: '20%' }} header="Role Name" sortable></Column>
                <Column field="RoleDesc"  style={{ width: '30%' }}  header="Remarks" sortable></Column>
                <Column field="UserType"   style={{ width: '20%' }} header="User Type" sortable></Column>
                <Column
                  field="Action"
                  header="Action"
                  style={{ width: "30%" }}
                  body={this.actionBodyTemplate}
                ></Column>
              </DataTable>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card page-list-scroll">
              <DataTable
                value={this.props.Pages}
                ref={(el) => {
                  this.dt = el;
                }}
                header={header2}
                globalFilter={this.state.globalFilter}
                className="p-datatable-customers"
                // scrollable
                scrollHeight="200px"
                rowHover={true}
                loading={this.state.loading}
                emptyMessage="No customers found."
                sortMode="multiple"
              >
                <Column
                  field="PageName"
                  header="Menu Name"
                 
                  /*style={{ width: '155px' }} */
                ></Column>
                <Column
                  field="IsView"
                 
                  header={viewHeading}
                  body={this.checkboxIsView}
                ></Column>
                <Column
                  field="IsAdd"
                  
                  header={addHeading}
                  body={this.checkboxIsAdd}
                ></Column>
                <Column
                  field="IsEdit"
                
                  header={updateHeading}
                  body={this.checkboxIsEdit}
                ></Column>
              </DataTable>
              <div className="col-md-12 mt-3 mb-3 text-center">
                {this.state.IsEdit === true ? (
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    onClick={(e) => {
                      this.updateRolePermission(e);
                    }}
                  />
                ) : (
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    style={{ display: "none" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={false}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <form onSubmit={this.submitForm}>
            <div className="row">
              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.RoleName}
                  name="RoleName"
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Role Name*"
                />
                {this.state.formError.RoleName !== "" ? (
                  <div className="error">{this.state.formError.RoleName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.RoleDesc}
                  name="RoleDesc"
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Remarks*"
                />
                {this.state.formError.RoleDesc !== "" ? (
                  <div className="error">{this.state.formError.RoleDesc}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <FormControl
                  variant="outlined"
                  size="small"
                  className="formControl col-md-12"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select User Type*
                  </InputLabel>
                  <Select
                    onChange={this.handleChange}
                    name="UserTypeId"
                    value={this.state.UserTypeId}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Select User Type"
                  >
                    <MenuItem>
                      <em>None</em>
                    </MenuItem>
                    {this.state.UserTypeData.map((key) => (
                      <MenuItem value={key.value}>{key.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {this.state.formError.UserTypeId !== "" ? (
                  <div className="error">{this.state.formError.UserTypeId}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <Checkbox
                  onClick={this.handleChange}
                  checked={this.state.IsActive}
                  color="primary"
                  name="IsActive"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <label>Is Active</label>
              </div>
              <div className="col-md-12 mt-3 text-center">
                {this.state.IsEdit === true ? (
                  <Button label={this.state.buttonLabel} icon="pi pi-check" />
                ) : (
                  <Button
                    label={this.state.buttonLabel}
                    icon="pi pi-check"
                    style={{ display: "none" }}
                  />
                )}
              </div>
              <div className="col-md-12">
                Note: Fields marked as (*) are required
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //////console.log('Data', state.role.items);

  return {
    RoleData: state.role.items,
    showDialog: state.role.showDialog,
    Pages: state.role.pages,
    loading: state.role.loding,
    showIsActiveDialog: state.role.showIsActiveDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (data) => {
      dispatch(roleMasterActions.getAll(data));
    },
    openPermissionDialog: (data) =>
      dispatch(roleMasterActions.openDialog(data)),
    createRole: (data) => dispatch(roleMasterActions.createRole(data)),
    updateRole: (data) => dispatch(roleMasterActions.updateRole(data)),
    inActiveRow: (roleId, isActive) =>
      dispatch(roleMasterActions.inActiveRow(roleId, isActive)),
    onHideShow: (isbool) => dispatch(roleMasterActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(roleMasterActions.onHideShowIsActive(isbool)),
    getPages: (roleId) => dispatch(roleMasterActions.getPages(roleId)),
    updatePageStore: (pagelist) =>
      dispatch(roleMasterActions.updatePageStore(pagelist)),
    warning: (message) => dispatch(alertActions.warning(message)),
    updateRolePermission: (data) =>
      dispatch(roleMasterActions.updateRolePermission(data)),
    loadingData: (isLoding) =>
      dispatch(roleMasterActions.loadingData(isLoding)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(roleMaster);
