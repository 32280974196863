import { DemappingRptService } from "../services/DemappingRptService";
import { DemappingRptConstants } from "../constants/DemappingRptConstants";
import { alertActions } from ".";

export const DemappingRptActions = {
  getAll,
  loadingData,
  exportData,
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());

    DemappingRptService.fetchDemappingData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));

        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.CreatedBy;
            delete v.DocURL;
            delete v.DemappingId;
            delete v.ProductID;
          });
        }
        dispatch(successExport(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: DemappingRptConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: DemappingRptConstants.GETALL_SUCCESS, datas };
  }
  function successExport(datas) {
    return { type: DemappingRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: DemappingRptConstants.GETALL_FAILURE, error };
  }
}

function exportData(obj) {
  return (dispatch) => {
    dispatch(request());
    DemappingRptService.fetchDemappingData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.CreatedBy;
            delete v.DocURL;
            delete v.DemappingId;
            delete v.ProductID;
          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: DemappingRptConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: DemappingRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: DemappingRptConstants.GETEXPORT_FAILURE, error };
  }
}

function loadingData(isbool) {
  return {
    type: DemappingRptConstants.LOADING_REQUEST,
    isbool,
  };
}
