import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="row footer">
        <div className="col-md-6 copyright">
          Copyright © 2024 | Powered by Xtra Power Tools
        </div>
        <div className="col-md-6 text-right designed">
          Design & Developed by{" "}
          <a
            href="https://holostik.com/"
            target="_blank"
            className="desinged-by"
          >
            Holostik India Limited
          </a>
        </div>
      </div>
    );
  }
}
