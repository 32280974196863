import { WarrantyRegistrationReportServices } from "../services";
import { WarrantyRegistrationReportConstants,QRCodeTrackingRptConstants } from "../constants";
import { alertActions } from ".";

export const WarrantyRegistrationReportActions = {
  getAll,
  loadingData,
  exportData,
  approveWarranty,
  rejectWarranty,
  getAllForApproval,
  onHideShow,
  inActiveRow,
  claimWarranty,
  getAllclaim,
  WarrantyReaprove,
  getAllReaprove,
  GetScanInfo,
};
function GetScanInfo(data, scannedList) {
  return (dispatch) => {
    dispatch(request(data));

    WarrantyRegistrationReportServices.getWaByQrScan(data).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        } else {
          dispatch(alertActions.error("Invalid response received"));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: QRCodeTrackingRptConstants.TRACK_SCAN_INFO_REQUEST };
  }
  function success(datas) {
    return { type: QRCodeTrackingRptConstants.TRACK_SCAN_INFO_SUCCESS, datas };
  }
  function failure(error) {
    return { type: QRCodeTrackingRptConstants.TRACK_SCAN_INFO_FAILURE, error };
  }
}

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());
    WarrantyRegistrationReportServices.fetchWarrantyDataRpt(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));

        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.ProductId;
          });
        }
        dispatch(successExport(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: WarrantyRegistrationReportConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: WarrantyRegistrationReportConstants.GETALL_SUCCESS, datas };
  }
  function successExport(datas) {
    return { type: WarrantyRegistrationReportConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.GETALL_FAILURE, error };
  }
}
function getAllclaim(obj) {
  return (dispatch) => {
    dispatch(request());
    WarrantyRegistrationReportServices.fetchWarrantyDataClaim(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));

        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.ProductId;
          });
        }
        dispatch(successExport(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: WarrantyRegistrationReportConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: WarrantyRegistrationReportConstants.GETALL_SUCCESS, datas };
  }
  function successExport(datas) {
    return { type: WarrantyRegistrationReportConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.GETALL_FAILURE, error };
  }
}

function getAllReaprove(obj) {
  return (dispatch) => {
    dispatch(request());
    WarrantyRegistrationReportServices.fetchWarrantyDataReapprove(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));

        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.ProductId;
          });
        }
        dispatch(successExport(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: WarrantyRegistrationReportConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: WarrantyRegistrationReportConstants.GETALL_SUCCESS, datas };
  }
  function successExport(datas) {
    return { type: WarrantyRegistrationReportConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.GETALL_FAILURE, error };
  }
}
function exportData(obj) {
  return (dispatch) => {
    dispatch(request());
    WarrantyRegistrationReportServices.fetchWarrantyDataRpt(obj).then(
      (response) => {
        dispatch(loadingData(false));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.ProductId;
          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: WarrantyRegistrationReportConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: WarrantyRegistrationReportConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.GETEXPORT_FAILURE, error };
  }
}

function loadingData(isbool) {
  return {
    type: WarrantyRegistrationReportConstants.LOADING_REQUEST,
    isbool,
  };
}

function approveWarranty(warranty) {
  var data = {
    WarrantyId: warranty
  };
  return (dispatch) => {
    dispatch(request());

    WarrantyRegistrationReportServices.approveWarranty(warranty).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_FAILURE, error };
  }
}
function claimWarranty(warranty) {
  var data = {
    WarrantyId: warranty
  };
  return (dispatch) => {
    dispatch(request());

    WarrantyRegistrationReportServices.claimWarranty(warranty).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_FAILURE, error };
  }
}
function WarrantyReaprove(warranty) {
  var data = {
    WarrantyId: warranty
  };
  return (dispatch) => {
    dispatch(request());

    WarrantyRegistrationReportServices.WarrantyReaprove(warranty).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_FAILURE, error };
  }
}
function rejectWarranty(warranty,reason) {
  var data = {
    WarrantyId: warranty,
    Reason: reason, // Add the reason to the data object
  };
  return (dispatch) => {
    dispatch(request());

    WarrantyRegistrationReportServices.rejectWarranty(data).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_FAILURE, error };
  }
}

function getAllForApproval(obj) {
  return (dispatch) => {
    dispatch(request());

    WarrantyRegistrationReportServices.fetchWarrantyData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: WarrantyRegistrationReportConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: WarrantyRegistrationReportConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.GETALL_FAILURE, error };
  }
}

function onHideShowIsActive(isbool) {
  return {
    type: WarrantyRegistrationReportConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}

function onHideShow(isbool) {
  return {
    type: WarrantyRegistrationReportConstants.DIALOG_REQUEST,
    isbool,
  };
}

function inActiveRow(warranty, isActive) {
  var data = {
    WarrantyId: warranty,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    WarrantyRegistrationReportServices.inActiveRow(warranty, isActive).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: WarrantyRegistrationReportConstants.ISACTIVE_FAILURE, error };
  }
}
