import { authHeader, handleResponse } from '../helpers';
export const GiftMasterService = {
    createScheme,  
    fetchSchemeData,
    inActiveRow,
    fetchDDL,
    ProductDDL,

};

function createScheme(data) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}Gift/CreateGift`, requestOptions)
        .then(handleResponse);
}

function fetchDDL() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}Gift/QuartersDLL`, requestOptions)
        .then(handleResponse);
}



function ProductDDL() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}ProductMaster/GetProductDLL`, requestOptions)
        .then(handleResponse);
}

function fetchSchemeData(data) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}Gift/GetGiftData`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(userId, isActive) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}Loyalityscheme/InActiveRow/${userId}/${isActive}`, requestOptions)
        .then(handleResponse);
}






