import { authHeader, handleResponse } from '../helpers';
export const productAuthenticationService = {
    verifyProduct,
    UpdateFeedback,
    updateRating,
    UpdateWarranty,
    warranty,
};

function UpdateWarranty(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/WarrantyRegistration`, requestOptions)
        .then(handleResponse);
}

function verifyProduct(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}ProductAuthentication/VerifyProduct`, requestOptions)
        .then(handleResponse);
}

function UpdateFeedback(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}ProductAuthentication/UpdateFeedback`, requestOptions)
        .then(handleResponse);
}
function updateRating(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}ProductAuthentication/UpdateRating`, requestOptions)
        .then(handleResponse);
}
function warranty(data) {
    // Constructing the fetch request
    return fetch(`${process.env.REACT_APP_API_URL}WarrantyRegistration/Scanwa?uid=${data.uid}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })
        .then(handleResponse);
}







