export const OrderDispatchConstants = {
  CREATE_REQUEST: "DISPATCH_CREATE_REQUEST",
  CREATE_SUCCESS: "DISPATCH_CREATE_SUCCESS",
  CREATE_FAILURE: "DISPATCH_CREATE_FAILURE",

  UPDATE_REQUEST: "DISPATCH_UPDATE_REQUEST",
  UPDATE_SUCCESS: "DISPATCH_UPDATE_SUCCESS",
  UPDATE_FAILURE: "DISPATCH_UPDATE_FAILURE",

  GETALL_REQUEST: "DISPATCH_GETALL_REQUEST",
  GETALL_SUCCESS: "DISPATCH_GETALL_SUCCESS",
  GETALL_FAILURE: "DISPATCH_GETALL_FAILURE",

  GETDISPATCHDDL_REQUEST: "DISPATCH_DDL_REQUEST",
  GETDISPATCHDDL_SUCCESS: "DISPATCH_DDL_SUCCESS",
  GETDISPATCHDDL_FAILURE: "DISPATCH_DDL_FAILURE",

  GETALL_AVAILABLE_REQUEST: "DISPATCH_GETALL_AVAILABLE_REQUEST",
  GETALL_AVAILABLE_SUCCESS: "DISPATCH_GETALL_AVAILABLE_SUCCESS",
  GETALL_AVAILABLE_FAILURE: "DISPATCH_GETALL_AVAILABLE_FAILURE",

  DELETE_REQUEST: "DISPATCH_DELETE_REQUEST",
  DELETE_SUCCESS: "DISPATCH_DELETE_SUCCESS",
  DELETE_FAILURE: "DISPATCH_DELETE_FAILURE",

  ISACTIVE_REQUEST: "DISPATCH_ISACTIVE_REQUEST",
  ISACTIVE_SUCCESS: "DISPATCH_ISACTIVE_SUCCESS",
  ISACTIVE_FAILURE: "DISPATCH_ISACTIVE_FAILURE",

  SO_DETAILS_REQUEST: "SO_DETAILS_REQUEST",
  SO_DETAILS_SUCCESS: "SO_DETAILS_SUCCESS",
  SO_DETAILS_FAILURE: "SO_DETAILS_FAILURE",

  DIALOG_REQUEST: "DIALOG_REQUEST",

  //DIALOGUE_REQUEST:'DIALOGUE_REQUEST',

  LOADING_REQUEST: "LOADING_REQUEST",

  DIALOG_ISACTIVE_REQUEST: "DIALOG_ISACTIVE_REQUEST",

  Warning: "Warning",
  Alreadypacked: "Alreadypacked",
};
