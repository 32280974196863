import { productAuthenticationService } from '../services';
import { productAuthenticationConstants } from '../constants';
import { alertActions } from '.';

export const productAuthenticationActions = {
    verifyProduct,
    UpdateFeedback,
    updateRating,
    onHideShow,
    UpdateWarranty,
    onHideShowWarranty,
    Warranty,
};

function UpdateWarranty(data) {
    return dispatch => {
        dispatch(request(data));
        productAuthenticationService.UpdateWarranty(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    dispatch(alertActions.success(response.ResponseMessage));
                    dispatch(success(response.ResponseData));
                    dispatch(onHideShowWarranty(false));
                    // response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
                    //     : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
                    //         : dispatch(alertActions.error(response.ResponseMessage))
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.UPDATE_WARRANTY_REQUEST, data } }
    function success(data) { return { type: productAuthenticationConstants.UPDATE_WARRANTY_SUCCESS, data } }
    function failure(error) { return { type: productAuthenticationConstants.UPDATE_WARRANTY_FAILURE, error } }
};

function verifyProduct(data) {
    return dispatch => {
        dispatch(request(data));
        productAuthenticationService.verifyProduct(data).then(
            response => {
                dispatch(success(response.ResponseData));
                //history.push('/');
                if (response && response.ResponseCode) {
                    // response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
                    //     : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
                    //         : dispatch(alertActions.error(response.ResponseMessage))
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: productAuthenticationConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: productAuthenticationConstants.CREATE_FAILURE, error } }
};

function UpdateFeedback(data) {
    return dispatch => {
        productAuthenticationService.UpdateFeedback(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        //dispatch(success(response.ResponseData));
                        dispatch(onHideShow(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.UPDATE_FEEDBACK_REQUEST, data } }
    function success(data) { return { type: productAuthenticationConstants.UPDATE_FEEDBACK_SUCCESS, data } }
    function failure(error) { return { type: productAuthenticationConstants.UPDATE_FEEDBACK_FAILURE, error } }
};

function updateRating(data) {
    return dispatch => {
        productAuthenticationService.updateRating(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(alertActions.error(error.toString()));
                failure(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.UPDATE_RATING_REQUEST, data } }
    function success(data) { return { type: productAuthenticationConstants.UPDATE_RATING_SUCCESS, data } }
    function failure(error) { return { type: productAuthenticationConstants.UPDATE_RATING_FAILURE, error } }
};

function onHideShow(isbool) {
    return {
        type: productAuthenticationConstants.FEEDBACK_DIALOG_REQUEST, isbool
    }
}

function onHideShowWarranty(isbool) {
    return {
        type: productAuthenticationConstants.WARRANTY_DIALOG_REQUEST, isbool
    }

}
function Warranty(data) {
    return dispatch => {
        dispatch(request(data));
        productAuthenticationService.warranty(data).then(
            (response) => {
                if (response.ResponseCode == "200")
                    dispatch(success(response.ResponseData[0]));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };
    function request(data) { return { type: productAuthenticationConstants.WGETALL_REQUEST, data } }
    function success(datas) { return { type: productAuthenticationConstants.WGETALL_SUCCESS, datas } }
    function failure(error) { return { type: productAuthenticationConstants.WGETALL_FAILURE, error } }
};




