export const GiftMasterConstants = {
    CREATE_REQUEST: 'GiftMaster_CREATE_REQUEST',
    CREATE_SUCCESS: 'GiftMaster_CREATE_SUCCESS',
    CREATE_FAILURE: 'GiftMaster_CREATE_FAILURE',

    UPDATE_REQUEST: 'GiftMaster_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'GiftMaster_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'GiftMaster_UPDATE_FAILURE',

    GETALL_REQUEST: 'GiftMaster_GETALL_REQUEST',
    GETALL_SUCCESS: 'GiftMaster_GETALL_SUCCESS',
    GETALL_FAILURE: 'GiftMaster_GETALL_FAILURE',

    GETQDDL_REQUEST: 'GiftMaster_GETQDDL_REQUEST',
    GETQDDL_SUCCESS: 'GiftMaster_GETQDDL_SUCCESS',
    GETQDDL_FAILURE: 'GiftMaster_GETQDDL_FAILURE',

    GETPDDL_REQUEST: 'GiftMaster_GETPDDL_REQUEST',
    GETPDDL_SUCCESS: 'GiftMaster_GETPDDL_SUCCESS',
    GETPDDL_FAILURE: 'GiftMaster_GETPDDL_FAILURE',

    GETALL_AVAILABLE_REQUEST: 'GiftMaster_GETALL_AVAILABLE_REQUEST',
    GETALL_AVAILABLE_SUCCESS: 'GiftMaster_GETALL_AVAILABLE_SUCCESS',
    GETALL_AVAILABLE_FAILURE: 'GiftMaster_GETALL_AVAILABLE_FAILURE',

    DELETE_REQUEST: 'GiftMaster_DELETE_REQUEST',
    DELETE_SUCCESS: 'GiftMaster_DELETE_SUCCESS',
    DELETE_FAILURE: 'GiftMaster_DELETE_FAILURE',
    
    ISACTIVE_REQUEST: 'GiftMaster_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'GiftMaster_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'GiftMaster_ISACTIVE_FAILURE',
    
    DIALOG_REQUEST:'DIALOG_REQUEST',

    DIALOGUE_REQUEST:'DIALOGUE_REQUEST',
    
    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST'
};