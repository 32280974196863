import { WarrantyRegistrationReportConstants } from "../constants";

export function warranty(state = {}, action) {
  switch (action.type) {
    case WarrantyRegistrationReportConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case WarrantyRegistrationReportConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WarrantyRegistrationReportConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case WarrantyRegistrationReportConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case WarrantyRegistrationReportConstants.GETEXPORT_REQUEST:
      return {
        ...state,
        exporting: true,
      };
    case WarrantyRegistrationReportConstants.GETEXPORT_SUCCESS:
      return {
        ...state,
        exportitems: action.datas,
      };
    case WarrantyRegistrationReportConstants.GETEXPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
