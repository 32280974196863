import React, { Component } from "react";
import Avtar from "./../assets/images/avatar.jpg";
import TextField from "@material-ui/core/TextField";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { loginActions, changePasswordActions, alertActions } from "../actions";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: "",
      Email: "",
      MobileNo: "",
      OldPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
      UserId: 0,

      formError: {
        OldPassword: null,
        NewPassword: null,
        ConfirmPassword: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  // submit form and create or update user
  submitForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }

      return isfrmvalid;
    });

    if (isfrmvalid) {
      let userCollector = JSON.parse(sessionStorage.getItem("UserData"));

      if (isfrmvalid) {
        const formmData = {
          Email: this.state.UserName,
          Password: this.state.OldPassword,
        };
        this.props.checkUser(formmData);
      }

      const formData = {
        // UserName: this.state.UserName,
        OldPassword: this.state.OldPassword,
        NewPassword: this.state.NewPassword,
        UserId: userCollector,
      };
      this.props.changePassword(formData);
    }
  }

  //to validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "OldPassword":
        if (value.length < 1) {
          IsValid = false;
          errors.OldPassword = "Please enter old password.";
        } else if (value.length >= 1 && this.state.NewPassword.length >= 1) {
          errors.OldPassword = "";
          errors.NewPassword = "";
          if (value === this.state.NewPassword) {
            IsValid = false;
            errors.OldPassword =
              "Old password and new password should not be same.";
          }
        } else errors.OldPassword = "";
        break;

      case "NewPassword":
        if (value.length < 1) {
          IsValid = false;
          errors.NewPassword = "Please enter new password.";
        } else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,10}$/)) {
          IsValid = false;
          errors.NewPassword =
            "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
        } else if (value.length >= 1 && this.state.OldPassword.length >= 1) {
          errors.OldPassword = "";
          errors.NewPassword = "";
          if (value === this.state.OldPassword) {
            IsValid = false;
            errors.NewPassword =
              "New password and old password should not be same.";
          } else if (
            !value &&
            value.length >= 1 &&
            this.state.ConfirmPassword.length >= 1
          ) {
            errors.NewPassword = "";
            errors.ConfirmPassword = "";
            if (!(value === this.state.ConfirmPassword)) {
              IsValid = false;
              errors.NewPassword = "password does not match";
            } else errors.NewPassword = "";
          } else errors.NewPassword = "";
          break;
        } else errors.NewPassword = "";
        break;

      case "ConfirmPassword":
        if (!value && value.length < 1) {
          IsValid = false;
          errors.ConfirmPassword = "Please enter confirm password.";
        } else if (value.length >= 1 && this.state.NewPassword.length >= 1) {
          errors.NewPassword = "";
          errors.ConfirmPassword = "";
          if (value !== this.state.NewPassword) {
            IsValid = false;
            errors.ConfirmPassword =
              "New password and Confirm password should be same.";
          }
        } else if (
          !value &&
          value.length >= 1 &&
          this.state.NewPassword.length >= 1
        ) {
          errors.NewPassword = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.NewPassword)) {
            IsValid = false;
            errors.ConfirmPassword = "Confirm password does not match";
          } else errors.ConfirmPassword = "";
        } else errors.ConfirmPassword = "";
        break;
      default:
        break;
    }

    return IsValid;
  }
  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  componentDidMount() {
    let userNameCollector = sessionStorage.getItem("UserNameData");
    let userEmailCollector = sessionStorage.getItem("UserEmailData");
    let userMobileCollector = sessionStorage.getItem("UserMobileData");

    this.setState({
      UserName: userNameCollector,
      Email: userEmailCollector,
      MobileNo: userMobileCollector,
    });
  }
  render() {
    return (
      <section className="mt-5 m-button dash-margin">
        <div className="col-md-8 offset-md-2">
          <div className="card card-outline-secondary card-box">
            <div className="card-header">
              <h3 className="mb-0 changepass">Change Password</h3>
            </div>
            <div className="card-body">
              <form onSubmit={this.submitForm}>
                <div className=" row profile-frm">
                  <label className="col-lg-3 col-form-label form-control-label">
                    Name
                  </label>
                  <div className="col-lg-9">
                    <input type="text" value={this.state.UserName} disabled />
                  </div>
                </div>
                <div className="profile-frm row">
                  <label className="col-lg-3 col-form-label form-control-label">
                    Email
                  </label>
                  <div className="col-lg-9">
                    <input type="text" value={this.state.Email} disabled />
                  </div>
                </div>
                <div className="row profile-frm">
                  <label className="col-lg-3 col-form-label form-control-label">
                    Mobile No.
                  </label>
                  <div className="col-lg-9">
                    <input type="text" value={this.state.MobileNo} disabled />
                  </div>
                </div>
                <div className="profile-frm row">
                  <label className="col-lg-3 col-form-label form-control-label">
                    Old Password
                  </label>
                  <div className="col-lg-9">
                    <input
                      type="password"
                      name="OldPassword"
                      onChange={this.handleChange}
                      value={this.state.OldPassword}
                    />
                    {this.state.formError.OldPassword !== "" ? (
                      <div className="error">
                        {this.state.formError.OldPassword}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="profile-frm row">
                  <label className="col-lg-3 col-form-label form-control-label">
                    New Password
                  </label>
                  <div className="col-lg-9">
                    <input
                      type="password"
                      name="NewPassword"
                      onChange={this.handleChange}
                      value={this.state.NewPassword}
                    />
                    {this.state.formError.NewPassword !== "" ? (
                      <div className="error">
                        {this.state.formError.NewPassword}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="profile-frm row">
                  <label className="col-lg-3 col-form-label form-control-label">
                    Confirm Password
                  </label>
                  <div className="col-lg-9">
                    <input
                      type="password"
                      name="ConfirmPassword"
                      onChange={this.handleChange}
                      value={this.state.ConfirmPassword}
                    />
                    {this.state.formError.ConfirmPassword !== "" ? (
                      <div className="error">
                        {this.state.formError.ConfirmPassword}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="profile-frm row">
                  <label className="col-lg-3 col-form-label form-control-label"></label>
                  <div className="col-lg-9">
                    <button className="btn btn-lsl">
                      {" "}
                      <span className="pi pi-check p-c p-button-icon-left"></span>{" "}
                      Change Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserName: state.changePassword.items?.filter((x) => x.UserName !== null)[0]
      ?.UserName,
    Email: state.changePassword.items?.filter((x) => x.Email !== null)[0]
      ?.Email,
    MobileNo: state.changePassword.items?.filter((x) => x.MobileNo !== null)[0]
      .MobileNo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserApi: () => {
      dispatch(changePasswordActions.fetchUserData());
    },

    checkUser: (login) => dispatch(loginActions.checkUser(login)),
    changePassword: (changePassword) =>
      dispatch(changePasswordActions.changePassword(changePassword)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
