import React, { Component } from "react";

export default class WarrantyTerms extends Component {
  render() {
    return (
      <div className="m-5">
        <h2 className="text-center">TERMS & CONDITIONS</h2>
        Congratulations on purchasing XTRA-POWER Power Tools, which are designed
        and manufactured with High Quality, Reliability and Safety Standards. In
        the event of failure of tools due to material & manufacturing defects,
        we undertake to repair the tools (with reparable & replaceable parts)
        free of charge within six months from the date of purchase, subject to
        the following:
        <h5 className="mt-3">Terms & Conditions:</h5>
        <ol>
          <li>
            To be returned to our authorized service centre at his/her own cost
            and risk.
          </li>
          <li>
            We cannot be held responsible for any repair other than those
            carried out by us or one of our authorized service centres.
          </li>
          <li>The Power Tool has not been tampered with.</li>
          <li>
            The Power Tool has been purchased from an accredited
            dealer/retailer/online portal.
          </li>
          <li>
            The claim should be referred to one of the authorized service
            centres along with Power Tool, stamped warranty card and original
            purchase valid invoice with GST number.
          </li>
          <li>
            The power tool shall be used strictly for the intended purpose in
            accordance with the user
          </li>
        </ol>
        <h5 className="mt-3">The Warranty Does Not Cover:</h5>
        <ol>
          <li>
            Repairs have been made or attempted by unauthorised personnel.
          </li>
          <li>
            All wear and tear like carbon brushes, bearings, O-rings, Oil Seals,
            Mechanical Parts, Trigger, Pump units, Plastic Parts, Heating
            Elements & Accessories that are consumable in nature.
          </li>
          <li>
            Power Tools have been abused or involved in accidents, misuse by
            overloading, installation to wrong voltage, overload, wrong
            accessories, and wrong application.
          </li>
          <li>Power Tool used after partial failure.</li>
          <li>
            Damage due to any unit caused by accident, misuse, wilful damage,
            and normal wear claims arising from loss, fire or other calamities
            caused by nature or any way tampered with.
          </li>
          <li>Use of spurious spares and look alike or non-genuine spares.</li>
          <li>
            Original Serial Number is deleted/defected/defaced or altered.
          </li>
        </ol>
        <h5>Note:</h5>
        <ol>
          <li>
            Armature, Field Coil & Motor will be changed only once during the
            warranty period.
          </li>
        </ol>
      </div>
    );
  }
}
