import React, { Component } from "react";
import { connect } from "react-redux";
import { productAuthenticationActions } from "../actions";
import logo from "./../assets/images/lp-logo.png";

import AS from "./../assets/images/AS.png";
import GP from "./../assets/images/GP.png";
import "./wa.css";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

export class Wa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalRecords: 0,
      rows: 5,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("uid");
    this.props.verifyProduct({ uid: urlParams });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.ProductAuthenticationData !==
      this.props.ProductAuthenticationData
    ) {
      const { FeedbackList } = this.props;
    }
  }

  render() {
    const { ProductAuthenticationData } = this.props;
    console.log("ProductAuthenticationData:", ProductAuthenticationData);

    // Check if ProductAuthenticationData is not null or undefined
    if (!ProductAuthenticationData) {
      return (
        <div className="datatable-filter-demo">
          <div className="row m-3 text-center">
            <h4>
              Invalid QR Code or Warranty is not Registered for this QR Code
            </h4>
          </div>
        </div>
      );
    }

    return (
      <div className="datatable-filter-demo">
        <div className="wa_AuthPageTopbox">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="AuthPageTopimg">
                  <img src={logo} />
                </div>
              </div>
              <div className="col-md-6 contactdetail">
                <div className="pa-contacte papapage">
                  <h4>
                    <i class="fa fa-envelope" aria-hidden="true"></i>:
                    sales@xtrapowertools.com{" "}
                    <i class="fa fa-phone-square" aria-hidden="true"></i>:
                    +91-1244000608
                  </h4>
                </div>
              </div>
              <div className="col-md-3 googleplays">
                <div className="success-message google success-msg1">
                  <img class="" src={GP} />
                  <img class="" src={AS} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container card mt-3 mb-3">
          <div className="row W_card_row mb-3">
            <div className="col-md-12 ">
              <div className="W_card">
                <h4>Warranty Card Details</h4>
              </div>
            </div>
          </div>

          <div
            id="testId"
            className="text-center image-wraper product-auth-img-box col-md-12 mt-3"
          >
            <div className="producimgalign">
              <img
                className="_waproductimg"
                src={ProductAuthenticationData.productImage}
                alt="Product"
              />
            </div>
          </div>
          <div className="row pr-5 pl-5">
            <div className="col-md-12 p_name_col">
              <p style={{ margin: "0.5rem" }}>
                {ProductAuthenticationData.ProductName}
              </p>
            </div>
          </div>
          <div className="row p-4">
            <div className="col-md-12 ">
              <table className="wa_table">
                <tr>
                  <td style={{ width: "30%" }}>
                    <strong>Customer Name:</strong>
                  </td>
                  <td>{ProductAuthenticationData.CustomerName}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Mobile No.:</strong>
                  </td>
                  <td>{ProductAuthenticationData.MobNo}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Purchased From:</strong>
                  </td>
                  <td>{ProductAuthenticationData.purchasedFrom}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Warranty Register Date:</strong>
                  </td>
                  <td>{ProductAuthenticationData.RegisterDate}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Warranty Invoice Date:</strong>
                  </td>
                  <td>{ProductAuthenticationData.InvoiceDate}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Warranty Invoice Image:</strong>
                  </td>
                  <td>
                    <a
                      href={ProductAuthenticationData.Invoice}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click Here{" "}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Warranty Card Image:</strong>
                  </td>
                  <td>
                    <a
                      href={ProductAuthenticationData.PhysicalCard}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click Here{" "}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Warranty Expiry Date:</strong>
                  </td>
                  <td>{ProductAuthenticationData.ExpireDate}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Warranty Claimed Date:</strong>
                  </td>
                  {ProductAuthenticationData.claimdate ? (
                    <td>{ProductAuthenticationData.claimdate}</td>
                  ) : (
                    <td>No warranty claimed</td>
                  )}
                </tr>
              </table>
              <div className="row social_row_wa p-5">
                <div className="col-md-12 social_col_wa">
                  <div className="share-wraper">
                    <div className="Demo__some-network">
                      <FacebookShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </div>
                    <div className="Demo__some-network">
                      <TwitterShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </div>
                    <div className="Demo__some-network">
                      <WhatsappShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    </div>
                    <div className="Demo__some-network">
                      <LinkedinShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                    </div>
                    <div className="Demo__some-network">
                      <EmailShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ProductAuthenticationData: state.productAuthentication?.warranty,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyProduct: (productAuth) =>
      dispatch(productAuthenticationActions.Warranty(productAuth)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wa);
