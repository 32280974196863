import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TextField from "@material-ui/core/TextField";
import { Calendar } from "primereact/calendar";
import { connect } from "react-redux";
import tableRequest from "../../models/tableRequest.js";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import CsvDownloader from "react-csv-downloader";
import { CSVLink } from "react-csv";
import { alertActions, WarrantyRegistrationReportActions } from "../../actions";

class WarrantyReaprove extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.FilterData = this.FilterData.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.state = {
            IsAdd: true,
            IsEdit: true,
            loading: false,
            FromDate: new Date(),
            ToDate: new Date(),
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            visible: false,
            sortOrder: "",
            datalist: [],
        };
    }
    componentDidMount() {
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() - 7);
        this.tblResponse.FromDate = date.toLocaleDateString();
        this.tblResponse.ToDate = new Date().toLocaleDateString();
        this.setState({
            FromDate: date,
            ToDate: new Date(),
        });
        this.props.fetchApi(this.tblResponse);
        // this.props.exportingData(this.tblResponse);
    }

    onChange = (date) => this.setState({ FromDate: "", ToDate: "" });

    // GetVerification()
    // {
    //     let ToDt = new Date();
    //     let FrmDt = new Date();
    //     FrmDt.setDate(ToDt.getDate() - 7);

    //     const myFmDt = this.getCurrentDateYYYYMMDD(FrmDt);
    //     const myToDt = this.getCurrentDateYYYYMMDD(ToDt);

    //     this.tblResponse.FromDate =this.state.FromDate;
    //     this.tblResponse.ToDate = this.state.ToDate;
    //     this.props.fetchApi(this.tblResponse);
    // }
    FilterData() {
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;
        this.props.fetchApi(this.tblResponse);
    }

    getCurrentDateYYYYMMDD(dt) {
        var dd = String(dt.getDate()).padStart(2, "0");
        var mm = String(dt.getMonth() + 1).padStart(2, "0");
        var yyyy = dt.getFullYear();

        dt = yyyy + "/" + mm + "/" + dd;
        return dt;
    }

    handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case "FromDate":
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please select from date.");
                    this.toast.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please select from date.",
                        life: 3000,
                    });

                    return;
                } else if (
                    !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
                ) {
                    this.formIsValid = false;
                    this.toast.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please enter only date.",
                        life: 3000,
                    });
                    // this.refs.Messchild.showError("Please enter only date.");
                    return;
                } else if (value instanceof Date && this.state.ToDate instanceof Date) {
                    const startDate = value;
                    const endDate = this.state.ToDate;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "From date must be less than to date.",
                            life: 3000,
                        });
                        // this.refs.Messchild.showError("From date must be less than to date.");
                        return;
                    }
                }

                break;
            case "ToDate":
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please select to date.",
                        life: 3000,
                    });
                    // this.refs.Messchild.showError("Please select to date.");
                    return;
                } else if (
                    !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
                ) {
                    this.formIsValid = false;
                    this.toast.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please enter only date.",
                        life: 3000,
                    });
                    // this.refs.Messchild.showError("Please enter only date.");
                    return;
                } else if (
                    value instanceof Date &&
                    this.state.FromDate instanceof Date
                ) {
                    const startDate = this.state.FromDate;
                    const endDate = value;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "To date must be greater than from date.",
                            life: 3000,
                        });
                        // this.refs.Messchild.showError("To date must be greater than from date.");
                        return;
                    }
                }

                break;
            default:
                break;
        }

        this.setState({ [name]: value });
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;
    }

    onPage(event) {
        this.props.loadingData(true);
        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    handleProductName(rowData) {
        if (rowData.ProductName == null) {
            let list;
            list = rowData.QrData;
            return list;
        } else {
            return rowData.ProductName;
        }
    }

    //to search
    searchFilter(e) {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField =
            event.multiSortMeta[0].field === "CreatedDate"
                ? "CreatedDt"
                : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    componentDidUpdate(prevProps) {
        if (this.props.warrantyList) {
            if (prevProps.warrantyList !== this.props.warrantyList) {
                // this.props.myProp has a different value
                if (this.props.warrantyList) {
                    var e = this.props.warrantyList;
                    this.setState({
                        totalRecords: e[0].TotalRows,
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0,
                });
        }
    }
    actionBodyTemplate = (rowData) => {
        
        const claimWarranty = () => {
            // Call the claimWarranty action and pass the WarrantyId
            this.props.claimWarranty(rowData.WarrantyId, true);
            setTimeout(() => {
                this.props.fetchApi(this.tblResponse);
            }, 1000);
        };

        return (
            <button type="button" className="btn btn-success" onClick={claimWarranty}>
                Approve
            </button>
        );
    };


    render() {
        return (
            <div className="datatable-filter-demo">
                {/* <ToastContainer /> */}
                <Toast ref={(el) => (this.toast = el)} />
                <div className="row mb-3">
                    <div className="col-md-3 report">
                        <h4>Warranty Re-Approval</h4>
                    </div>
                    <div className="col-md-9">
                        <div className="row text-right">
                            <div className="col-md-3">
                                <Calendar
                                    placeholder="From Date"
                                    name="FromDate"
                                    showIcon="true"
                                    value={this.state.FromDate}
                                    onChange={this.handleChange}
                                ></Calendar>
                            </div>
                            <div className="col-md-3">
                                <Calendar
                                    placeholder="To Date"
                                    name="ToDate"
                                    showIcon="true"
                                    value={this.state.ToDate}
                                    onChange={this.handleChange}
                                ></Calendar>
                            </div>
                            <div className="col-md-3 text-center report">
                                <button
                                    type="button"
                                    onClick={this.FilterData}
                                    className="btn btn-primary p-2 pl-3 pr-3 mr-3"
                                >
                                    Submit
                                </button>
                                &nbsp;

                            </div>
                            <div className="col-md-3">
                                <TextField
                                    id="standard-basic"
                                    size="small"
                                    variant="outlined"
                                    className="fullwidth"
                                    label="Search"
                                    onInput={this.searchFilter}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <DataTable
                        value={this.props.warrantyList}
                        scrollable
                        responsive={true}
                        globalFilter={this.state.globalFilter}
                        emptyMessage="No data found."
                        sortMode="multiple"
                        paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows}
                        style={{ width: "100%" }}
                        rowsPerPageOptions={[5, 10, 20]}
                        totalRecords={this.state.totalRecords}
                        lazy={true}
                        first={this.state.first}
                        onPage={this.onPage}
                        onSort={this.onSort}
                        loading={this.props.loading}
                        ref={(el) => {
                            this.dt = el;
                        }}
                    >
                        <Column field="SeqNo" header="Seq No" sortable style={{ width: '6%' }}></Column>
                        <Column field="ProductName" header="Product Name" sortable style={{ width: '14%' }}></Column>
                        <Column field="CustomerName" header="Customer Name" sortable style={{ width: '10%' }}></Column>
                        <Column field="Email" header="Email" sortable style={{ width: '12%' }}></Column>
                        <Column field="MobNo" header="Mobile" sortable style={{ width: '10%' }}></Column>
                        <Column field="InvoiceDate" header="Purchased Date" sortable style={{ width: '14%' }}></Column>
                        <Column field="RegisterDate" header="Warranty Registered Date" sortable style={{ width: '16%' }}></Column>
                        <Column field="purchasedFrom" header="Dealer Name" sortable style={{ width: '10%' }}> </Column>
                        {/*   */}

                        <Column
                            field="Action"
                            onClick={(event) => { event.stopPropagation() }}
                            header="Action"
                            body={this.actionBodyTemplate}
                            style={{ width: '10%' }}
                        ></Column>
                    </DataTable>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        warrantyList: state.warranty.items,
        List:
            state.warranty.exportitems == undefined
                ? []
                : state.warranty.exportitems,
        loading: state.warranty.loding,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(WarrantyRegistrationReportActions.getAllReaprove(obj));
        },
        claimWarranty: (WarrantyId, isActive) =>
            dispatch(WarrantyRegistrationReportActions.WarrantyReaprove(WarrantyId, isActive)),
        loadingData: (isLoding) =>
            dispatch(WarrantyRegistrationReportActions.loadingData(isLoding)),
        exportingData: (obj) => dispatch(WarrantyRegistrationReportActions.exportData(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyReaprove);
