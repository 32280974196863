import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";
import {
  damageActions,
  mappingActions,
  productMasterActions,
} from "../../actions";
import tableRequest from "../../models/tableRequest.js";
// import Loaders from '../../templates/Loaders';
import Switch from "@material-ui/core/Switch";

class Damage extends React.Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    // this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
    this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);

    this.state = {
      IsAdd: true,
      IsEdit: true,
      confirm: false,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      buttonLabel: "Submit",
      formHeading: "Add Mapping",
      displayModal: false,
      switchAction: false,
      showSnackbar: false,
      displayConfirmation: false,
      ProductName: "",
      updateLoader: false,
      MappingId: 0,
      CreatedDt: null,
      UpdatedDt: null,
      ProductId: 0,
      DamageId: 0,
      FromNo: "",
      ToNo: "",
      Qty: "",
      refrenceFromNo: 0,
      refrenceToNo: 0,
      Reason: "",
      IsActive: true,
      formError: {
        ProductName: "",
        FromNo: "",
        ToNo: "",
        Qty: "",
        Reason: "",
        CreatedDt: null,
        UpdatedDt: null,
      },
    };
  }
  //to trigger api on page call
  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        }
      }
    }
    this.props.fetchApi(this.tblResponse);
    this.setState({ updateLoader: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.MappingData) {
      if (prevProps.MappingData !== this.props.MappingData) {
        // this.props.myProp has a different value
        if (this.props.MappingData) {
          var e = this.props.MappingData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }

    if (prevProps.MaxFromNo !== this.props.MaxFromNo) {
      this.setState({
        FromNo: this.props.MaxFromNo,
      });
    }
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }
  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    this.getLazyLoadingValues(event);
    this.props.fetchApi(this.tblResponse);
  }

  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  getDropValue() {
    // let productId = '';
    // this.props.ProductData.filter(x => {
    //     if (x.ProductId === this.state.ProductId) {
    //         productId = x.ProductId;
    //     }
    // });
    // return productId;
  }

  //to manage switch toggle confirm box
  switchToggleConfirm = (e) => {
    this.setState({ confirm: true, displayConfirmation: false });
    const formData = {
      MachineId: this.state.MachineId,
    };
    this.props.inActiveRow(this.state.MachineId);
  };

  //to handle form
  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    // switch (name) {
    //     case 'IsActive':
    //         value = event.target.checked;
    //         break;
    //     default:
    //         break;
    // }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }
  //to validate form data
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;

    switch (name) {
      case "Reason":
        if (!value) {
          IsValid = false;
          errors.Reason = "Please enter Reason";
        } else errors.Reason = "";
        break;
      case "FromNo":
        if (value < 1) {
          IsValid = false;
          errors.FromNo = "Please enter your from number.";
        } else {
          if (!value.toString().match(/^[0-9]+$/)) {
            IsValid = false;
            errors.FromNo = "Please enter only number.";
            this.setState({ Qty: 0 });
          } else {
            errors.FromNo = null;
            errors.ToNo = null;
            errors.Qty = null;
            const fromNumber =
              value.toString() === "" ? 0 : parseInt(value.toString());
            const toNumber =
              this.state.ToNo === "" ? 0 : parseInt(this.state.ToNo);
            if (
              fromNumber >= this.state.refrenceFromNo &&
              fromNumber <= this.state.refrenceToNo &&
              toNumber >= this.state.refrenceFromNo &&
              toNumber <= this.state.refrenceToNo
            ) {
              if (fromNumber <= toNumber) {
                const qty = toNumber - fromNumber + 1;
                if (qty >= 0) {
                  this.setState({ Qty: qty });
                } else {
                  IsValid = false;
                  errors.FromNo = "From No. should be less than to number.";
                  this.setState({ Qty: 0 });
                }
              } else {
                IsValid = false;
                errors.FromNo = "From No. should be less than to number.";
                this.setState({ Qty: 0 });
              }
            } else {
              IsValid = false;
              errors.FromNo =
                "From No. should be less than To no. and numbers must exist between mapped series";
              this.setState({ Qty: 0 });
            }
          }
        }
        break;
      case "ToNo":
        if (value < 1) {
          IsValid = false;
          errors.ToNo = "Please enter your to number.";
        } else {
          if (!value.toString().match(/^[0-9]+$/.exec(value))) {
            IsValid = false;
            errors.FromNo = "Please enter only number.";
            this.setState({ Qty: 0 });
          } else {
            errors.FromNo = null;
            errors.ToNo = null;
            errors.Qty = null;
            const toNumber =
              value.toString() === "" ? 0 : parseInt(value.toString());
            const fromNumber =
              this.state.FromNo === "" ? 0 : parseInt(this.state.FromNo);
            if (
              fromNumber >= this.state.refrenceFromNo &&
              fromNumber <= this.state.refrenceToNo &&
              toNumber >= this.state.refrenceFromNo &&
              toNumber <= this.state.refrenceToNo
            ) {
              if (fromNumber <= toNumber) {
                const qty = toNumber - fromNumber + 1;
                if (qty >= 0) {
                  this.setState({ Qty: qty });
                } else {
                  IsValid = false;
                  errors.ToNo = "To No. should be greater than from number.";
                  this.setState({ Qty: 0 });
                }
              } else {
                IsValid = false;
                errors.ToNo = "To No. should be greater than from number.";
                this.setState({ Qty: 0 });
              }
            } else {
              IsValid = false;
              errors.ToNo =
                "To No. should be greater than from no. and numbers must exist between mapped series.";
              this.setState({ Qty: 0 });
            }
          }
        }
        break;
      case "Qty":
        if (value < 1) {
          IsValid = false;
          errors.Qty = "Please enter your quantity";
        } else errors.Qty = null;
        break;

      default:
        break;
    }

    return IsValid;
  }
  //to get current date
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }
  //to submit form data
  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const ddlValu = this.getDropValue();

      const formData = {
        FromNo: this.state.FromNo,
        ToNo: this.state.ToNo,
        Qty: this.state.Qty,
        Reason: this.state.Reason,
        CreatedBy: JSON.parse(sessionStorage.getItem("UserData")),
        UpdatedBy: JSON.parse(sessionStorage.getItem("UserData")),
      };
      this.setState({ updateLoader: false });

      if (this.state.MappingId == 0) {
        this.props.createDamage(formData);
        this.props.fetchApi();

        this.setState({
          updateLoader: true,
        });
      } else {
        this.props.createDamage(formData);
        this.props.fetchApi();

        this.setState({
          updateLoader: true,
        });
      }
    }
  }

  //to reset form
  resetForm() {
    this.setState({
      DamageId: 0,
      FromNo: "",
      ToNo: "",
      Qty: "",
      Reason: "",
      formError: {
        FromNo: "",
        ToNo: "",
        Qty: "",
        Reason: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  //to hide function

  onHide() {
    this.resetForm();
    //this.setState({ displayModal: false })
    this.props.onHideShow(false);
  }
  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Damage", formHeading: "Damage" });
  }

  //to get format of date
  UpdatedDtTemplate(rowData) {
    if (rowData.UpdatedDt !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDt}</Moment>;
    }
  }
  //to get format of date
  CreatedDtTemplate(rowData) {
    if (rowData.CreatedDt !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDt}</Moment>;
    }
  }
  //to disable checkbox
  // statusBodyTemplate(rowData) {
  //     return (
  //         <Checkbox
  //             defaultChecked
  //             disabled
  //             checked={rowData.IsActive}
  //             color="primary"
  //             inputProps={{ 'aria-label': 'secondary checkbox' }}
  //         />
  //     )
  // }
  //to edit rowdata
  actionBodyTemplate(rowData) {
    const editMode = (e) => {
      this.setState({
        ProductId: rowData.ProductId,
        ProductName: rowData.ProductName,
        MappingId: rowData.MappingId,
        FromNo: rowData.FromNo,
        ToNo: rowData.ToNo,
        Qty: rowData.Qty,
        Reason: rowData.Reason,
        refrenceFromNo: rowData.FromNo,
        refrenceToNo: rowData.ToNo,
      });
    };
    return (
      <Button
        label="Damage"
        onClick={(e) => {
          editMode(e);
          this.openDialog();
        }}
      />
    );
  }
  //to export data
  exportCSV() {
    this.dt.exportCSV();
  }

  //to render html
  render() {
    const renderFooter = (name) => {
      return (
        <div>
          {/* <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayConfirmation: false })} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus /> */}
        </div>
      );
    };

    const header = (
      <div className="table-header row">
        <div className="col-md-6">
        <h4 className="user">Damage</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
          {/* <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={() => this.setState({ displayModal: true, buttonLabel: 'Submit', formHeading: 'Add Mapping' })} /> */}
          {/* <Button type="button" icon="pi pi-external-link" label="Export" onClick={this.exportCSV}></Button> */}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        {/* <Loaders loader={this.state.updateLoader} /> */}

        <ToastContainer />
        <div className="card">
          <DataTable
            value={this.props.MappingData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            {/* <Column field="MappingId" header="Mapping Id" sortable></Column> */}
            <Column  style={{ width: "20%" }} field="ProductName" header="Product Name" sortable></Column>
            <Column  style={{ width: "20%" }} field="FromNo" header="From No" sortable></Column>
            <Column  style={{ width: "20%" }} field="ToNo" header="To No" sortable></Column>
            <Column  style={{ width: "20%" }} field="Qty" header="Quantity" sortable></Column>
            {this.state.IsAdd === true ? (
              <Column
                field="Action"
                header="Action"
                style={{ width: "20%" }}
                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column></Column>
            )}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={false}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <form onSubmit={this.submitForm}>
            <div className="row">
              {/* <div className="col-md-6 pt-2">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select Product</InputLabel>
                                    <Select onChange={this.handleChange}  name="ProductId" value={this.state.ProductId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Product">
                                        <MenuItem>
                                            <em>None</em>
                                        </MenuItem>

                                        {this.props.ProductData == (undefined || null) ? "" : this.props.ProductData.map(key => (
                                            <MenuItem value={key.ProductId}>{key.ProductName}</MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                                {this.state.formError.ProductId !== '' ?
                                    <div className='error'>{this.state.formError.ProductId}</div> : ''}
                            </div> */}

              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.FromNo}
                  name="FromNo"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="From No*"
                />
                {this.state.formError.FromNo !== "" ? (
                  <div className="error">{this.state.formError.FromNo}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.ToNo}
                  name="ToNo"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="To No*"
                />
                {this.state.formError.ToNo !== "" ? (
                  <div className="error">{this.state.formError.ToNo}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.Qty}
                  disabled
                  name="Qty"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Qty*"
                />
                {this.state.formError.Qty !== "" ? (
                  <div className="error">{this.state.formError.Qty}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.Reason}
                  name="Reason"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Reason*"
                />
                {this.state.formError.Reason !== "" ? (
                  <div className="error">{this.state.formError.Reason}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12 mt-3 text-center">
                {this.state.IsAdd === true ? (
                  <Button label={this.state.buttonLabel} icon="pi pi-check" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12">
                Note: Fields marked as (*) are required
              </div>
            </div>
          </form>
        </Dialog>

        <Dialog
          header="Confirmation"
          visible={this.state.displayConfirmation}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.setState({ displayConfirmation: false })}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>Are you sure you want to InActive?</span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    MappingData: state.damage.items,
    ProductData: state.product.items,
    showDialog: state.damage.showDialog,
    loading: state.company.loding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(damageActions.getAll(obj));
    },
    fetchProductApi: () => {
      dispatch(productMasterActions.getProductName());
    },
    createDamage: (machine) => dispatch(damageActions.createDamage(machine)),
    updateDamage: (machine) => dispatch(damageActions.updateDamage(machine)),
    onHideShow: (isbool) => dispatch(damageActions.onHideShow(isbool)),
    loadingData: (isLoding) => dispatch(mappingActions.loadingData(isLoding)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Damage);
