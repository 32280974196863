import { OrderDispatchRptService } from "../services";
import { OrderDispatchRptConstants } from "../constants";
import { alertActions } from ".";

export const OrderDispatchRptActions = {
  getAll,
  loadingData,
  exportData,
  GetDispatchToPrint,
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());
    OrderDispatchRptService.fetchDispatchData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));

        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
          });
        }
        dispatch(successExport(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: OrderDispatchRptConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: OrderDispatchRptConstants.GETALL_SUCCESS, datas };
  }
  function successExport(datas) {
    return { type: OrderDispatchRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: OrderDispatchRptConstants.GETALL_FAILURE, error };
  }
}

function exportData(obj) {
  return (dispatch) => {
    dispatch(request());
    OrderDispatchRptService.fetchDispatchData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: OrderDispatchRptConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: OrderDispatchRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: OrderDispatchRptConstants.GETEXPORT_FAILURE, error };
  }
}

function loadingData(isbool) {
  return {
    type: OrderDispatchRptConstants.LOADING_REQUEST,
    isbool,
  };
}

function GetDispatchToPrint(obj) {
  return (dispatch) => {
    dispatch(request());
    OrderDispatchRptService.GetDispatchToPrint(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: OrderDispatchRptConstants.GETPRINT_REQUEST };
  }
  function success(datas) {
    return { type: OrderDispatchRptConstants.GETPRINT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: OrderDispatchRptConstants.GETPRINT_FAILURE, error };
  }
}
