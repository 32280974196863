import { WarrantyRejectRegistrationReportConstants } from "../constants";

export function warrantyReject(state = {}, action) {
  switch (action.type) {
    case WarrantyRejectRegistrationReportConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case WarrantyRejectRegistrationReportConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WarrantyRejectRegistrationReportConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case WarrantyRejectRegistrationReportConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case WarrantyRejectRegistrationReportConstants.GETEXPORT_REQUEST:
      return {
        ...state,
        exporting: true,
      };
    case WarrantyRejectRegistrationReportConstants.GETEXPORT_SUCCESS:
      return {
        ...state,
        exportitems: action.datas,
      };
    case WarrantyRejectRegistrationReportConstants.GETEXPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
