import { CartonService } from "../services/CartonService";
import { CartonConstants } from "../constants/CartonConstants";
import { alertActions } from ".";

export const CartonActions = {
  getAll,
  GetActiveCartonDDL,
  CreateCarton,
  UpdateCarton,
  openDialog,
  inActiveRow,
  onHideShow,
  onHideShoww,
  loadingData,
  onHideShowIsActive,
};

function getAll(data) {
  return (dispatch) => {
    dispatch(request());
    CartonService.GetCarton(data).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: CartonConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: CartonConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: CartonConstants.GETALL_FAILURE, error };
  }
}

function GetActiveCartonDDL() {
  return (dispatch) => {
    dispatch(request());

    CartonService.GetActiveCartonDDL().then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: CartonConstants.GETCARTONDDL_REQUEST };
  }
  function success(datas) {
    return { type: CartonConstants.GETCARTONDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: CartonConstants.GETCARTONDDL_FAILURE, error };
  }
}

function CreateCarton(data) {
  return (dispatch) => {
    dispatch(request(data));
    CartonService.CreateCarton(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            //dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: CartonConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: CartonConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: CartonConstants.CREATE_FAILURE, error };
  }
}

function UpdateCarton(data) {
  return (dispatch) => {
    dispatch(request(data));
    CartonService.UpdateCarton(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            //dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: CartonConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: CartonConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: CartonConstants.CREATE_FAILURE, error };
  }
}

function inActiveRow(user, isActive) {
  var data = {
    UserId: user,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    CartonService.inActiveRow(user, isActive).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: CartonConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: CartonConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: CartonConstants.ISACTIVE_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: CartonConstants.DIALOG_REQUEST,
    isbool,
  };
}
function onHideShoww(isbool) {
  return {
    type: CartonConstants.DIALOGUE_REQUEST,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: CartonConstants.LOADING_REQUEST,
    isbool,
  };
}

function onHideShowIsActive(isbool) {
  return {
    type: CartonConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}

function openDialog(data) {
  return {
    type: CartonConstants.OPEN_DIALOG_REQUEST,
    data,
  };
}
