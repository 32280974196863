import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { userMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Row } from "reactstrap";

class userApproval extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onSort = this.onSort.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.state = {
      IsAdd: true,
      IsEdit: true,
      totalRecords: 0,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
    };
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (this.props.UserData) {
      if (prevProps.UserData !== this.props.UserData) {
        // this.props.myProp has a different value
        if (this.props.UserData) {
          var e = this.props.UserData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);
    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  onHide() {
    this.props.onHideShow(false);
  }

  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add User" });
  }

  actionBodyTemplate(rowData) {
    const approve = (e) => {
      this.props.approveUser(rowData.UserId);
      setTimeout(() => {
        this.props.fetchApi(this.tblResponse);
      }, 1000);
    };

    const reject = (e) => {
      this.props.rejectUser(rowData.UserId);
      setTimeout(() => {
        this.props.fetchApi(this.tblResponse);
      }, 1000);
    };

    const viewDetails = (e) => {
      ;
      // var rowData = e;
      this.tblResponse.DealerId =
        this.state.UserType > 1 ? this.state.DealerId : 0;
      //this.props.readRow(rowData.OrderBookingNo, false, this.tblResponse);
      this.setState({
        formHeading: "User Approval",
        UserName: rowData.UserName,
        Email: rowData.Email,
        RoleName: rowData.RoleName,
        MobileNo: rowData.MobileNo,
        gstno: rowData.gstno,
        GstCertificate: rowData.GstCertificate,
        Nature_Firm: rowData.Nature_Firm,
        GstCertificate: rowData.GstCertificate,
        Ship_City_Name: rowData.Ship_City_Name,
        Ship_State_Name: rowData.Ship_State_Name,
        Ship_Pincode: rowData.Ship_Pincode,
        Ship_Address: rowData.Ship_Address,

      });
      this.props.onHideShow(true);
    };


    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Button
                className="btn m-1 success"
                icon="pi pi-check"
                label=" "
                onClick={approve}
              />
              <Button
                className="btn m-1 danger "
                icon="pi pi-times"
                label=" "
                onClick={reject}
              />
              <Button
                className="btn m-1 primary "
                icon="pi pi-eye"
                label=" "

                onClick={viewDetails}
              />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  render() {
    const header = (
      <div className="table-header row">
        <div className="col-md-6">
        <h4 className="user">User Approval</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>

    );


    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card">
          <DataTable
            value={this.props.UserData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20, parseInt(this.state.totalRecords)]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            // onRowClick={viewDetails}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column style={{ width: '10%' }} field="UserName" header="User Name" sortable></Column>
            <Column  style={{ width: '10%' }} field="RoleName" header="Role Name" sortable></Column>
            <Column style={{ width: '15%' }} field="Email" header="Email" sortable></Column>
            <Column style={{ width: '15%' }} field="MobileNo" header="Mobile Number" sortable></Column>
            <Column  style={{ width: '10%' }} field="Ship_City_Name" header="City" sortable></Column>
           
            <Column  style={{ width: '10%' }} field="Nature_Firm" header="Firm Name" sortable></Column>
            <Column  style={{ width: '10%' }} field="gstno" header="GST No" sortable></Column>


            {this.state.IsEdit === true ? (
              <Column
                field="Action"
                onClick={(event) => { event.stopPropagation() }}
                header="Action"
                className=""
                body={this.actionBodyTemplate}
                style={{ width: '20%' }}
              ></Column>
            ) : (
              ""
            )}
          </DataTable>
        </div>


        {/* ---------- */}


        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <div className="mainblock">
            <div className="row">
              <div className="col-md-6">
               
                <table id="customers">
                  <tr>
                    <td className="tdnamesize"><b>Name :</b></td>
                    <td>{this.state.UserName}</td>
                  </tr>
                  <tr>
                    <td><b>RoleName :</b></td>
                    <td>{this.state.RoleName}</td>
                  </tr>
                  <tr>
                    <td><b>Email :</b></td>
                    <td>{this.state.Email}</td>
                  </tr>
                  <tr>
                    <td><b>Mobile No :</b></td>
                    <td>{this.state.MobileNo}</td>
                  </tr>
                  <tr>
                    <td><b>Gst no :</b></td>
                    <td>{this.state.gstno}</td>
                  </tr>
                  <tr>
                    <td><b>Nature Firm: </b></td>
                    <td>{this.state.Nature_Firm}</td>
                  </tr>
                  <tr>
                    <td><b>City: </b></td>
                    <td>{this.state.Ship_City_Name}</td>
                  </tr>
                  <tr>
                    <td><b>State: </b></td>
                    <td>{this.state.Ship_State_Name}</td>
                  </tr>
                  <tr>
                    <td><b>Address: </b></td>
                    <td>{this.state.Ship_Address}</td>
                  </tr>
                  <tr>
                    <td><b>Pincode: </b></td>
                    <td>{this.state.Ship_Pincode}</td>
                  </tr>
                </table>
              </div>
              <div className="col-md-6 GstCertificate">
                <a href={this.state.GstCertificate} target="_blank">
                  <img src={this.state.GstCertificate}></img></a>

              </div>
            </div>

          </div>


        </Dialog>



      </div>

    );
  }
}



const mapStateToProps = (state) => {
  return {
    UserData: state.user.items,
    showDialog: state.role.showDialog,
    showIsActiveDialog: state.user.showIsActiveDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(userMasterActions.getAllForApproval(obj));
    },

    inActiveRow: (user, isActive) =>
      dispatch(userMasterActions.inActiveRow(user, isActive)),
    approveUser: (user, isActive) =>
      dispatch(userMasterActions.approveUser(user, isActive)),
    rejectUser: (user, isActive) =>
      dispatch(userMasterActions.rejectUser(user, isActive)),
    onHideShow: (isbool) => dispatch(userMasterActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(userMasterActions.onHideShowIsActive(isbool)),
    loadingData: (isLoding) =>
      dispatch(userMasterActions.loadingData(isLoding)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(userApproval);
