import { authHeader, handleResponse } from '../helpers';
export const SecondaryPackingRptService = {
    fetchSecondaryPackingData,
    GetVendorListDDL,
    GetSecondaryPackingReportExport
};

function fetchSecondaryPackingData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}SecondaryPackingRpt/GetSecondaryPackingReport`, requestOptions)
        .then(handleResponse);
}

function GetSecondaryPackingReportExport(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}SecondaryPackingRpt/GetSecondaryPackingReportExport`, requestOptions)
        .then(handleResponse);
}

function GetVendorListDDL(UserId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${process.env.REACT_APP_API_URL}SecondaryPackingRpt/GetVendorListDDL?UserId=${UserId}`, requestOptions)
        .then(handleResponse);
}