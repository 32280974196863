export const OrderDispatchRptConstants = {
  GETALL_REQUEST: "DISPATCH_GETALL_REQUEST",
  GETALL_SUCCESS: "DISPATCH_GETALL_SUCCESS",
  GETALL_FAILURE: "DISPATCH_GETALL_FAILURE",

  GETEXPORT_REQUEST: "DISPATCH_GETEXPORT_REQUEST",
  GETEXPORT_SUCCESS: "DISPATCH_GETEXPORT_SUCCESS",
  GETEXPORT_FAILURE: "DISPATCH_GETEXPORT_FAILURE",

  GETPRINT_REQUEST: "DISPATCH_GETPRINT_REQUEST",
  GETPRINT_SUCCESS: "DISPATCH_GETPRINT_SUCCESS",
  GETPRINT_FAILURE: "DISPATCH_GETPRINT_FAILURE",

  LOADING_REQUEST: "LOADING_REQUEST",
};
