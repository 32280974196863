import { DispatchRptConstants } from '../constants';

export function DispatchReport(state = {}, action) {
  switch (action.type) {
    case DispatchRptConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool
      };
    case DispatchRptConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DispatchRptConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas
      };
    case DispatchRptConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case DispatchRptConstants.GETEXPORT_REQUEST:
      return {
        ...state,
        exporting: true
      };
    case DispatchRptConstants.GETEXPORT_SUCCESS:
      return {
        ...state,
        exportitems: action.datas
      };
    case DispatchRptConstants.GETEXPORT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case DispatchRptConstants.GETPRINT_REQUEST:
      return {
        ...state,
        loding: true
      };
    case DispatchRptConstants.GETPRINT_SUCCESS:
      return {
        ...state,
        printdata: action.datas
      };
    case DispatchRptConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state
  }

}