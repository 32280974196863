export const SecondaryPackingRptConstants = {
    GETALL_REQUEST: 'SECONDARYPACKING_GETALL_REQUEST',
    GETALL_SUCCESS: 'SECONDARYPACKING_GETALL_SUCCESS',
    GETALL_FAILURE: 'SECONDARYPACKING_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'SECONDARYPACKING_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'SECONDARYPACKING_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'SECONDARYPACKING_GETEXPORT_FAILURE',

    GETVENDORDDL_REQUEST: 'GETVENDORDDL_REQUEST',
    GETVENDORDDL_SUCCESS: 'GETVENDORDDL_SUCCESS',
    GETVENDORDDL_FAILURE: 'GETVENDORDDL_FAILURE',

    LOADING_REQUEST: 'LOADING_REQUEST'
}