import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import VerifyProduct from "../models/VerifyProduct";
import { productAuthenticationActions } from "../actions";
import { handleResponse } from "../helpers";
import reviewIcon from "./../assets/images/write-review-icon.png";
import shareIcon from "./../assets/images/share-icon.png";
import sucessIcon from "./../assets/images/sucess-code.png";
import fakeIcon from "./../assets/images/wrong-code.png";
import { Rating } from "primereact/rating";
import Lightbox from "react-image-lightbox";
import InstaIcon from "./../assets/images/instagram.png";
import LinkedInIcon from "./../assets/images/linkedIn.png";
import OtherLinkIcon from "./../assets/images/link.png";
import Logo from "./../assets/images/login_logo.png";
import logo from "./../assets/images/lp-logo.png";
import logogg from "./../assets/images/success.png";
import thanku from "./../assets/images/t4.png";
import { InputText } from "primereact/inputtext";
import AS from "./../assets/images/AS.png";
import GP from "./../assets/images/GP.png";

import PinterestIcon from "./../assets/images/pinterest.png";
import ReactPlayer from "react-player";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

export class ProductAuthentication extends Component {
  constructor(props) {
    super(props);
    this.clsVerifyProduct = new VerifyProduct();
    this.state = {
      Promo: false,
      formIsValid: false,
      IsLocation: false,
      ratingBox: false,
      shareBox: false,
      VerifyProduct: {},
      rating: null,
      showDialog: false,
      VerificationMode: "Web",
      QrData: window.location.href,
      VerificationType: 2,
      VerificationId: 0,
      ProductId: "",
      CompId: "",
      displayBasic: false,
      UserId: "",
      SeqNo: "",
      Name: "",
      CustomerName: "",
      email: "",
      MobileNo: "",
      UserFeedback: "",
      showVideo: true,
      photoIndex: 0,
      isOpen: false,
      socialLinks: "",
      dealeraddress: "",
      purchasedfrom: "",
      IsWarranty: 0,
      ProductId: "",
      Invoice: "",
      companyId: "",
      InvoiceDate: "",
      showVerifyDialog: true,
      scratchCode: "",
      verificationResult: null,
      formError: {
        CustomerName: null,
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
        MobNo: null,
        dealeraddress: null,
        purchasedfrom: null,
        Invoice: "",
        InvoiceDate: "",
      },
      ScratchError: {
        scratchCode: null,
      },
      warrantyError: {
        Name: null,
        CustomerName: null,
        MobNo: null,
        email: null,
        dealeraddress: null,
        purchasedfrom: null,
        Invoice: "",
      },
    };

    this.submitForm = this.submitForm.bind(this);
    this.submitWarranty = this.submitWarranty.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onHideWarranty = this.onHideWarranty.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.openWarranty = this.openWarranty.bind(this);
    this.updateRating = this.updateRating.bind(this);
    this.ImgModal = this.ImgModal.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.reverseGeocodeLatLngAndVerifyProduct =
      this.reverseGeocodeLatLngAndVerifyProduct.bind(this);
    const urlParams = new URLSearchParams(window.location.search);
    const parmUid = urlParams.get("uid");
    //const curl = window.location.href;
    this.clsVerifyProduct.QrData = this.state.QrData;
    this.clsVerifyProduct.ScratchCd = this.state.scratchCode;
    this.clsVerifyProduct.VerificationType = this.state.VerificationType;
    this.clsVerifyProduct.VerificationMode = this.state.VerificationMode;
  }

  //get reverse geo code
  reverseGeoCode(lat, long) {
    // tslint:disable-next-line:max-line-length
    const requestOptions = {
      method: "GET",
    };
    return fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`,
      requestOptions
    ).then(handleResponse);
    //   return fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`);
  }

  //to trigger api on page call
  componentDidMount() {
    const caches = window.caches;
    if (window.caches) {
      window.caches.keys().then(async function (names) {
        await Promise.all(names.map((name) => window.caches.delete(name)));
      });
    }

    // const urlParams = new URLSearchParams(window.location.search);
    // const parmUid = urlParams.get('uid');

    // const curl = window.location.href;

    // this.clsVerifyProduct.verificationMode = this.state.verificationMode;
    // this.clsVerifyProduct.qrData = this.state.qrData;
    // this.clsVerifyProduct.verificationType = this.state.verificationType;
    // this.clsVerifyProduct.UID = parmUid;

    // setTimeout(() => {
    //     // this.findMe();
    // }, 1000);
    // this.findMe();
  }

  // get verify product list data
  GetVerifyProduct(productAuthReq) {
    this.setState({
      IsLocation: false,
    });

    this.props.verifyProduct(productAuthReq);
  }
  //to hide function
  hideFunc() {
    this.setState({
      displayBasic: false,
    });
  }
  // to validate Scratch Form
  validateScratch(value, name) {
    let errors = this.state.ScratchError;
    let IsValid = true;
    switch (name) {
      case "scratchCode":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.scratchCode = "Please enter your Scratch Code.";
        } else errors.scratchCode = "";
        break;
      default:
        break;
    }
    return IsValid;
  }

  //to validate feedback form
  validateFeedback(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "Name":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Name = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            IsValid = false;
            errors.Name = "Please enter only alphabet.";
          } else errors.Name = "";
        } else errors.Name = "";
        break;
      case "email":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.email = "Please enter your email-id.";
        } else if (value && value.length > 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.email = "Please enter valid email-id.";
          } else errors.email = "";
        } else errors.email = "";
        break;
      case "MobileNo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter your mobile no.";
        } else if (value && value.length > 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = null;
        } else errors.MobileNo = "";
        break;
      case "UserFeedback":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.UserFeedback = "Please enter your feedback.";
        } else errors.UserFeedback = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  validateWarranty(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "CustomerName":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.CustomerName = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            IsValid = false;
            errors.CustomerName = "Please enter only alphabet.";
          } else errors.CustomerName = "";
        } else errors.CustomerName = "";
        break;
      case "email":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.email = "Please enter your email-id.";
        } else if (value && value.length > 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.email = "Please enter valid email-id.";
          } else errors.email = "";
        } else errors.email = "";
        break;
      case "MobNo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.MobNo = "Please enter your mobile no.";
        } else if (value && value.length > 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobNo = "Please enter valid mobile no.";
          } else errors.MobNo = null;
        } else errors.MobNo = "";
        break;
      // case "UserWarranty":
      //   if (!value || value.length < 1) {
      //     IsValid = false;
      //     errors.UserFeedback = "Please enter your warranty.";
      //   } else errors.UserFeedback = "";
      //   break;
      case "Invoice":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.Invoice = "Please upload product image.";
        } else errors.Invoice = "";
        break;
      case "InvoiceDate":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.InvoiceDate = "Please enter an Invoice Date.";
        } else errors.InvoiceDate = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  ImgModal() {
    var modal = document.getElementById("myModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    // var captionText = document.getElementById("caption");
    img.onclick = function () {
      modal.style.display = "block";
      modalImg = document.getElementById("img01");
      // captionText.innerHTML = this.alt;
    };

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    };
  }

  submitWarranty(event) {
    // const { name, value } = event.target;
    event.preventDefault();

    let iswarrantyvalid = true;
    let checkerror = this.state.formError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateWarranty(val, key);
      if (!valid) {
        this.setState({ checkerror: iswarrantyvalid });
        iswarrantyvalid = false;
      }

      return iswarrantyvalid;
    });

    const warrantyData = {
      CustomerName: this.state.CustomerName,
      email: this.state.email,
      MobNo: this.state.MobNo,
      dealeraddress: this.state.dealeraddress,
      purchasedfrom: this.state.purchasedfrom,
      Invoice: this.state.Invoice,
      VerificationId: this.props.ProductAuthenticationData.VerificationId,
      ProductId: this.props.ProductAuthenticationData.ProductId,
      CompId: this.props.ProductAuthenticationData.CompId,
      SeqNo: this.props.ProductAuthenticationData.SeqNo,
      InvoiceDate: this.state.InvoiceDate,
    };

    if (iswarrantyvalid) {
      this.props.UpdateWarranty(warrantyData);
    }

    this.props.verifyProduct();
  }

  photoBodyTemplate(e) {
    if (e.Invoice) {
      return <img src={e.Invoice} width="50px" />;
    }
  }

  setImagePath(event) {
    let errors = this.state.formError;

    errors.Invoice = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              Invoice: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.Invoice = "Only image format is accepted.";
            this.setState({
              Invoice: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  submitForm(event) {
    // const { name, value } = event.target;
    event.preventDefault();

    let isfeedfrmvalid = true;
    let checkerror = this.state.formError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateFeedback(val, key);
      if (!valid) {
        this.setState({ checkerror: isfeedfrmvalid });
        isfeedfrmvalid = false;
      }

      return isfeedfrmvalid;
    });

    const feedbackData = {
      Name: this.state.Name,
      email: this.state.email,
      MobileNo: this.state.MobileNo,
      UserFeedback: this.state.UserFeedback,
      VerificationId: this.props.ProductAuthenticationData?.VerificationId,
    };

    if (isfeedfrmvalid) {
      this.props.UpdateFeedback(feedbackData);
    }

    this.setState({ [feedbackData]: "" });
  }

  formReset() {
    this.setState({
      Name: "",
      email: "",
      MobileNo: "",
      UserFeedback: "",
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
        MobNo: null,
        dealeraddress: null,
        purchasedfrom: null,
        Invoice: "",
        InvoiceDate: "",
        CustomerName: ",",
      },
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  updateRating(productId, verificationId, event) {
    this.setState({ rating: event.value });

    var data = {
      ProductId: productId,
      ProductRating: event.value,
      SeqNo: event.value,
      VerificationId: verificationId,
    };

    if (productId) {
      this.props.updateRating(data);
    } else {
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.scratchError;

    this.validateFeedback(value, name);
    this.setState({ errors, [name]: value });
  }

  handleChange1(event) {
    const { name, value } = event.target;
    let errors = this.state.scratchError;

    this.validateWarranty(value, name);
    this.setState({ errors, [name]: value });
  }

  onHide() {
    this.props.onHideShow(false);
  }
  onHideWarranty() {
    this.props.onHideShowWarrnty(false);
  }

  openDialog() {
    this.props.onHideShow(true);
  }

  openWarranty() {
    this.props.onHideShowWarrnty(true);
  }

  //to find  location
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.showPosition(position);
        },
        (denied) => {
          // alert(JSON.stringify(denied));
          this.GetVerifyProduct(this.clsVerifyProduct);
        },
        { enableHighAccuracy: true, maximumAge: 10000 }
      );
    } else {
      this.GetVerifyProduct(this.clsVerifyProduct);
    }
  }
  //to get exact longitude and latitude
  showPosition(position) {
    this.clsVerifyProduct.Userlongitude = position.coords.longitude;
    this.clsVerifyProduct.Userlatitude = position.coords.latitude;
    this.reverseGeocodeLatLngAndVerifyProduct();
  }
  //to get location
  reverseGeocodeLatLngAndVerifyProduct() {
    // tslint:disable-next-line: max-line-length
    this.reverseGeoCode(
      this.clsVerifyProduct.Userlatitude,
      this.clsVerifyProduct.Userlongitude
    ).then(
      (response) => {
        const res = response;
        this.clsVerifyProduct.UserCity = res.address.state_district;
        this.clsVerifyProduct.UserState = res.address.state;
        this.clsVerifyProduct.UserCountry = res.address.country;
        this.clsVerifyProduct.UserZip = res.address.postcode;
        this.clsVerifyProduct.UserAddress = res.display_name;
        this.setState({
          Location: res.display_name,
        });
        this.GetVerifyProduct(this.clsVerifyProduct);
      },
      (error) => {
        // alert(JSON.stringify(error))
        this.GetVerifyProduct(this.clsVerifyProduct);
      }
    );
  }
  handleScratchCodeChange = (e) => {
    this.setState({ scratchCode: e.target.value });
    this.clsVerifyProduct.ScratchCd = e.target.value;
  };

  handleVerifyClick = async (event) => {
    event.preventDefault();
    let isScratchfrmvalid = true;
    let checkerror = this.state.ScratchError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateScratch(val, key);
      if (!valid) {
        this.setState({ checkerror: isScratchfrmvalid });
        isScratchfrmvalid = false;
      }
      return isScratchfrmvalid;
    });

    if (isScratchfrmvalid) {
      try {
        // Wait for the asynchronous verification
        // this.GetVerifyProduct(this.clsVerifyProduct)
        this.findMe();
        // Handle the response
        // const verificationResponse = {
        //   success: response && response.success, // Adjust based on the actual response structure
        // };

        this.setState((prevState) => ({ scratchCode: "" }));
        //this.setState({ verificationResult: verificationResponse.success });

        // Close the dialog
        this.setState({ showVerifyDialog: false });
      } catch (error) {
        // Handle any error during verification
        console.error("Error during scratch verification:", error);
      }
    }
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    let links = null;
    links =
      this.props.ProductAuthenticationData &&
      this.props.ProductAuthenticationData.SocialLinks
        ? JSON.parse(this.props?.ProductAuthenticationData?.SocialLinks)
        : [];
    let share = null;
    return (
      <section>
        <div className="wapperbgColor">
          <div className="AuthPageTopbox">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="AuthPageTopimg">
                    <img src={logo} />
                  </div>
                </div>
                <div className="col-md-6 contactdetail">
                  <div class="pa-contacte papapage">
                    <h4>
                      <i class="fa fa-envelope" aria-hidden="true"></i> :
                      sales@xtrapowertools.com{" "}
                      <i class="fa fa-phone-square" aria-hidden="true"></i>:
                      +91-1244000608
                    </h4>
                  </div>
                </div>
                <div className="col-md-3 googleplays">
                  <div className="success-message google success-msg1">
                    <img class="" src={GP} />
                    <img class="" src={AS} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.showVerifyDialog && (
            <Dialog
              header="Scratch Verification"
              visible={this.state.showVerifyDialog}
              onHide={() => this.setState({ showVerifyDialog: false })}
            >
              <div className="p-fluid">
                <div className="p-field">
                  <label htmlFor="scratchCode">Enter Scratch Code:</label>
                  <InputText
                    id="scratchCode"
                    value={this.state.scratchCode}
                    onChange={this.handleScratchCodeChange}
                  />
                </div>
              </div>
              <div className="p-mt-2">
                <Button
                  className="verified"
                  label="Verify"
                  onClick={this.handleVerifyClick}
                />
              </div>
            </Dialog>
          )}

          {this.props.ProductAuthenticationData != null &&
          this.props.ProductAuthenticationData.ResponseCode == "200" ? (
            <div className="container">
              {(this.props.ProductAuthenticationData.GenuineAudio != null) ===
              true ? (
                <audio
                  controls
                  autoPlay
                  src={this.props.ProductAuthenticationData.GenuineAudio}
                  id="pm3paly"
                  hidden
                />
              ) : (
                ""
              )}

              {(this.props.ProductAuthenticationData.PromotionalMessage !=
                null) ===
              true ? (
                <div hidden={this.state.Promo} id="myModal" className="modal">
                  <span
                    onClick={() => {
                      this.setState({ Promo: true });
                    }}
                    className="close mt-3 mr-3"
                  >
                    &times;
                  </span>
                  <img
                    className="modal-content"
                    src={
                      this.props.ProductAuthenticationData.PromotionalMessage
                    }
                    id="img01"
                  />
                  <div id="caption"></div>
                </div>
              ) : (
                ""
              )}

              <div className="row  newsletter-box ">
                <div className="col-md-1 success-message1 ">
                  <img class="" src={logogg} />
                </div>

                <div className="col-md-5 success-msg">
                  <h2 className="success-msg1">
                    {this.props.ProductAuthenticationData.ResponseMessage}
                  </h2>
                </div>

                <div className="col-md-6 wr">
                  {this.props.ProductAuthenticationData.IsWarranty != null &&
                  this.props.ProductAuthenticationData.IsWarranty == "1" ? (
                    <div className="warrantyreg">
                      (
                      <span className="cursor" on>
                        Click on the App store and Play Store Icon For Warranty
                        Registration
                      </span>
                      )
                    </div>
                  ) : (
                    this.state.onHideWarranty
                  )}
                </div>
              </div>

              <div className="Productinfo0 card">
                <div className="Productinfo">
                  <div className="row">
                    <div className=" col-md-6 col-xl-6 col-xs-12 productename">
                      <div className="productDetils ">
                        <img
                          src={
                            this.props.ProductAuthenticationData.ProductImage
                          }
                        />
                        <h4>
                          {this.props.ProductAuthenticationData.ProductName}
                        </h4>

                        <hr></hr>

                        <div className="row socilbox">
                          <div className="col-md-4">
                            {this.props.ProductAuthenticationData.IsFeedback !=
                              null &&
                            this.props.ProductAuthenticationData.IsFeedback !=
                              "0" ? (
                              <span
                                className="poineter"
                                onClick={() => this.openDialog()}
                              >
                                <i
                                  className="fa fa-comments-o"
                                  aria-hidden="true"
                                ></i>{" "}
                                Feedback
                              </span>
                            ) : null}
                          </div>

                          <div className="col-md-4 warrantyreg2">
                            {this.props.ProductAuthenticationData.IsRating !=
                              null &&
                            this.props.ProductAuthenticationData.IsRating !=
                              "0" ? (
                              <span
                                className="poineter"
                                onClick={() =>
                                  this.setState({
                                    ratingBox: !this.state.ratingBox,
                                  })
                                }
                              >
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                Rating &nbsp;
                                {this.props.Rating &&
                                this.props.Rating.ProductRating
                                  ? this.props.Rating.ProductRating
                                  : this.props.ProductAuthenticationData
                                      .ProductRating}
                                (
                                {this.props.Rating &&
                                this.props.Rating.TotalRating
                                  ? this.props.Rating.TotalRating
                                  : this.props.ProductAuthenticationData
                                      .TotalRating == null
                                  ? 0
                                  : this.props.ProductAuthenticationData
                                      .TotalRating}
                                )
                              </span>
                            ) : null}
                          </div>
                          <div className="col-md-4 warrantyreg3">
                            {this.props.ProductAuthenticationData.IsShare !=
                              null &&
                            this.props.ProductAuthenticationData.IsShare !=
                              "0" ? (
                              <span
                                className="poineter"
                                onClick={() =>
                                  this.setState({
                                    shareBox: !this.state.shareBox,
                                  })
                                }
                              >
                                <i
                                  className="fa fa-share"
                                  aria-hidden="true"
                                ></i>
                                Share
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="">
                          {this.state.ratingBox ? (
                            <div className="rating-wraper">
                              {this.props.ProductAuthenticationData.isRated ==
                              0 ? (
                                <Rating
                                  cancel={false}
                                  value={this.state.rating}
                                  onChange={(e) =>
                                    this.updateRating(
                                      this.props.ProductAuthenticationData
                                        .ProductId,
                                      this.props.ProductAuthenticationData
                                        .VerificationId,
                                      e
                                    )
                                  }
                                />
                              ) : (
                                <Rating
                                  cancel={false}
                                  readonly
                                  value={
                                    this.props.ProductAuthenticationData
                                      .ProductRating
                                  }
                                  onChange={(e) =>
                                    this.updateRating(
                                      this.props.ProductAuthenticationData
                                        .ProductId,
                                      this.props.ProductAuthenticationData
                                        .VerificationId,
                                      e
                                    )
                                  }
                                />
                              )}
                            </div>
                          ) : null}
                        </div>

                        <div className="row socialink">
                          {/* <div className="col-md-6">
                            {this.props.ProductAuthenticationData != null &&
                              this.props.ProductAuthenticationData.SocialLinks != null ? (
                              <span
                                onClick={() =>
                                  this.setState({ socialLinks: !this.state.socialLinks })
                                }
                              >
                                <i className="fa fa-link" aria-hidden="true"></i>
                                Social Links
                              </span>
                            ) : null}
                          </div> */}
                        </div>

                        <div>
                          {this.state.shareBox ? (
                            <div className="share-wraper">
                              <div className="Demo__some-network">
                                <FacebookShareButton
                                  url={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  quote={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  className="Demo__some-network__share-button"
                                >
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>
                              </div>
                              <div className="Demo__some-network">
                                <TwitterShareButton
                                  url={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  quote={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  className="Demo__some-network__share-button"
                                >
                                  <TwitterIcon size={32} round />
                                </TwitterShareButton>
                              </div>
                              <div className="Demo__some-network">
                                <WhatsappShareButton
                                  url={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  quote={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  className="Demo__some-network__share-button"
                                >
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                              </div>
                              <div className="Demo__some-network">
                                <LinkedinShareButton
                                  url={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  quote={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  className="Demo__some-network__share-button"
                                >
                                  <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                              </div>
                              <div className="Demo__some-network">
                                <EmailShareButton
                                  url={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  quote={
                                    this.props.ProductAuthenticationData
                                      .ShareURL
                                  }
                                  className="Demo__some-network__share-button"
                                >
                                  <EmailIcon size={32} round />
                                </EmailShareButton>
                              </div>
                            </div>
                          ) : null}
                        </div>

                        <div>
                          {this.state.socialLinks ? (
                            <div className="share-wraper">
                              <span className="share-span">
                                {this.props.ProductAuthenticationData != null &&
                                this.props.ProductAuthenticationData
                                  .SocialLinks != null ? (
                                  <>
                                    <span className="share-span">
                                      {Object.keys(links).map((x) => {
                                        switch (x) {
                                          case "Facebook": {
                                            return (
                                              <span className="share-span">
                                                <a
                                                  href={links[x]}
                                                  target="_blank"
                                                >
                                                  <FacebookIcon
                                                    size={27}
                                                    round
                                                  />
                                                </a>
                                              </span>
                                            );
                                          }
                                          case "Instagram": {
                                            return (
                                              <span className="share-span">
                                                <a
                                                  href={links[x]}
                                                  target="_blank"
                                                >
                                                  <img
                                                    width="27px"
                                                    height="27px"
                                                    src={InstaIcon}
                                                  ></img>
                                                </a>
                                              </span>
                                            );
                                          }
                                          case "LinkedIn": {
                                            return (
                                              <span className="share-span">
                                                <a
                                                  href={links[x]}
                                                  target="_blank"
                                                >
                                                  <img
                                                    width="27px"
                                                    height="27px"
                                                    src={LinkedInIcon}
                                                  ></img>
                                                </a>
                                              </span>
                                            );
                                          }
                                          case "Twitter": {
                                            return (
                                              <span className="share-span">
                                                <a
                                                  href={links[x]}
                                                  target="_blank"
                                                >
                                                  <img
                                                    width="27px"
                                                    height="27px"
                                                    src={TwitterIcon}
                                                  ></img>
                                                </a>
                                              </span>
                                            );
                                          }
                                          case "Pinterest": {
                                            return (
                                              <span className="share-span">
                                                <a
                                                  href={links[x]}
                                                  target="_blank"
                                                >
                                                  <img
                                                    width="27px"
                                                    height="27px"
                                                    src={PinterestIcon}
                                                  ></img>
                                                </a>
                                              </span>
                                            );
                                          }
                                          default: {
                                            return (
                                              <span className="share-span">
                                                <a
                                                  href={links[x]}
                                                  target="_blank"
                                                >
                                                  <img
                                                    width="27px"
                                                    height="27px"
                                                    src={OtherLinkIcon}
                                                  ></img>
                                                </a>
                                              </span>
                                            );
                                          }
                                        }
                                      })}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-6 col-xs-6">
                      <table className="authTableProduct responsive socile">
                        <div className="h_mobile1">
                          <div className="left_social">
                            <a
                              href="https://www.facebook.com/toolsxtrapower"
                              target="_blank"
                            >
                              <img src="site-img/social/169200081794528350264d9e231a92cc.jpg" />
                              <span>Facebook</span>
                            </a>
                            <a
                              href="https://www.instagram.com/xtra_power_tools/"
                              target="_blank"
                            >
                              <img src="site-img/social/1692000897176265785264d9e281b2458.jpg" />
                              <span>Instagram</span>
                            </a>
                            <a
                              href="https://www.linkedin.com/company/xtrapower-tools-pvt-ltd/mycompany"
                              target="_blank"
                            >
                              <img src="site-img/social/169200125419182265364d9e3e61feee.jpg" />
                              <span>linkedin</span>
                            </a>
                            <a
                              href="https://www.youtube.com/@xtrapowertools"
                              target="_blank"
                            >
                              <img src="site-img/social/1692001677127090056364d9e58debf9d.jpg" />
                              <span>Youtube</span>
                            </a>
                            <a
                              href="https://api.whatsapp.com/send/?phone=918743800144&text&type=phone_number&app_absent=0"
                              target="_blank"
                            >
                              <img src="site-img/social/1693542307178805031864f167a349cf3.jpg" />
                              <span>Whatsapp</span>
                            </a>
                            <a
                              href="https://twitter.com/ToolsXtraPower"
                              target="_blank"
                            >
                              <img src="site-img/social/169503533618309058865082fc8bcef5.jpg" />
                              <span>Twitter</span>
                            </a>
                          </div>
                        </div>
                        <div className="left_toast"></div>
                        {this.props.ProductAuthenticationData &&
                        this.props.ProductAuthenticationData
                          .ProductSpecification &&
                        Array.isArray(
                          this.props.ProductAuthenticationData
                            .ProductSpecification
                        )
                          ? this.props.ProductAuthenticationData.ProductSpecification.map(
                              (x, i) => {
                                if (x.Key === "Website") {
                                  return (
                                    <tbody key={i}>
                                      <tr>
                                        <td className="authProdutname">
                                          <p>{x.Key}</p>
                                        </td>
                                        <td>
                                          <p>
                                            <a
                                              style={{
                                                color: "red",
                                                fontSize: "16px",
                                              }}
                                              href={x.Value}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {" "}
                                              {x.Value}
                                            </a>
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return (
                                    <tbody key={i}>
                                      <tr>
                                        <td className="authProdutname">
                                          <p>{x.Key}</p>
                                        </td>
                                        <td>
                                          <p>{x.Value}</p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              }
                            )
                          : null}
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <Dialog
                header="Warranty Registeration Form"
                modal
                dismissableMask={true}
                visible={this.props.ShowDialogWarranty}
                className="popup"
                onHide={this.onHideWarranty}
              >
                <form onSubmit={this.submitWarranty}>
                  <div className="row">
                    <div className="col-md-6 pt-2">
                      <TextField
                        id="standard-basic"
                        size="small"
                        onChange={this.handleChange1}
                        variant="outlined"
                        name="CustomerName"
                        value={this.state.CustomerName}
                        className="fullwidth"
                        label="CustomerName*"
                      />
                      {this.state.formError.CustomerName !== "" ? (
                        <div className="error">
                          {this.state.formError.CustomerName}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 pt-2">
                      <TextField
                        id="standard-basic"
                        size="small"
                        onChange={this.handleChange1}
                        variant="outlined"
                        name="email"
                        value={this.state.email}
                        className="fullwidth"
                        label="Email*"
                      />
                      {this.state.formError.email !== "" ? (
                        <div className="error">
                          {this.state.formError.email}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 pt-2">
                      <TextField
                        id="standard-basic"
                        size="small"
                        onChange={this.handleChange1}
                        variant="outlined"
                        name="MobNo"
                        value={this.state.MobNo}
                        className="fullwidth"
                        label="Mobile Number*"
                      />
                      {this.state.formError.MobNo !== "" ? (
                        <div className="error">
                          {this.state.formError.MobNo}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 pt-2">
                      <TextField
                        id="standard-basic"
                        size="small"
                        onChange={this.handleChange1}
                        variant="outlined"
                        name="purchasedfrom"
                        value={this.state.purchasedfrom}
                        className="fullwidth"
                        label="Purchased From*"
                      />
                      {this.state.formError.purchasedfrom !== "" ? (
                        <div className="error">
                          {this.state.formError.purchasedfrom}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 pt-2">
                      <TextField
                        id="standard-basic"
                        size="small"
                        onChange={this.handleChange1}
                        variant="outlined"
                        name="dealeraddress"
                        value={this.state.dealeraddress}
                        className="fullwidth"
                        label="Dealer Address*"
                      />
                      {this.state.formError.dealeraddress !== "" ? (
                        <div className="error">
                          {this.state.formError.dealeraddress}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 pt-2">
                      <TextField
                        id="standard-basic"
                        size="small"
                        onChange={this.handleChange1}
                        variant="outlined"
                        name="InvoiceDate"
                        value={this.state.InvoiceDate}
                        className="fullwidth"
                        label="Invoice Date*"
                      />
                      {this.state.formError.InvoiceDate !== "" ? (
                        <div className="error">
                          {this.state.formError.InvoiceDate}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-4 pt-3">
                      <div className="mb-3">Upload Invoice Image*</div>

                      <div className="mb-4">
                        <FileUpload
                          name="Invoice"
                          mode="basic"
                          url={
                            process.env.REACT_APP_API_URL +
                            "UploadFile/ImageUpload"
                          }
                          auto={true}
                          onUpload={this.setImagePath}
                          onError={this.onErrorImage}
                          accept="image/*"
                        />

                        {this.state.Invoice ? (
                          <div
                            className="p-fileupload-content mt-3 p-1"
                            style={{
                              display: "table",
                              border: "1px solid #ccc",
                            }}
                          >
                            <div>
                              <img
                                alt="pro.jfif"
                                role="presentation"
                                src={this.state.Invoice}
                                width="100"
                              />
                            </div>
                          </div>
                        ) : null}

                        {this.state.formError.Invoice !== null ? (
                          <div className="error">
                            {this.state.formError.Invoice}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-12 mt-3 text-center">
                      <Button label="Submit" icon="pi pi-check" />
                    </div>
                  </div>
                </form>
              </Dialog>
            </div>
          ) : (
            <div class="container fakepage">
              <div className="row justify-content-center newsletter-box">
                <div className="col-md-7">
                  <div className="card card-outline-secondary">
                    {(this.props.ProductAuthenticationData != null &&
                      this.props.ProductAuthenticationData.FakeAudio) ===
                    true ? (
                      <audio
                        controls
                        autoPlay
                        src={this.props.ProductAuthenticationData.FakeAudio}
                        id="pm3paly"
                        hidden
                      />
                    ) : (
                      ""
                    )}

                    <div className="success-message responsive">
                      {this.props.ProductAuthenticationData != null ? (
                        <div className="text-center pa-genuine ">
                          <div className="card-header">
                            {" "}
                            <img src={fakeIcon} className="" />
                          </div>
                          <div className="card-body text-center fake-msg">
                            <h2 className="blink-text">
                              {
                                this.props.ProductAuthenticationData
                                  .ResponseMessage
                              }
                            </h2>
                          </div>
                          <div className="card-body text-center  fakebtn">
                            <button
                              type="button"
                              className="btn btn-danger btn-lg"
                              onClick={() => this.openDialog()}
                            >
                              Feedback
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Dialog
            header="Feedback Form"
            modal
            dismissableMask={true}
            visible={this.props.ShowDialogFeedback}
            className="popup"
            onHide={this.onHide}
          >
            <form onSubmit={this.submitForm}>
              <div className="row">
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="Name"
                    value={this.state.Name}
                    className="fullwidth"
                    label="Name*"
                  />
                  {this.state.formError.Name !== "" ? (
                    <div className="error">{this.state.formError.Name}</div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="email"
                    value={this.state.email}
                    className="fullwidth"
                    label="Email*"
                  />
                  {this.state.formError.email !== "" ? (
                    <div className="error">{this.state.formError.email}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="MobileNo"
                    value={this.state.MobileNo}
                    className="fullwidth"
                    label="Mobile number*"
                  />
                  {this.state.formError.MobileNo !== "" ? (
                    <div className="error">{this.state.formError.MobileNo}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="UserFeedback"
                    value={this.state.UserFeedback}
                    className="fullwidth"
                    label="Feedback*"
                  />
                  {this.state.formError.UserFeedback !== "" ? (
                    <div className="error">
                      {this.state.formError.UserFeedback}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-12 mt-3 text-center">
                  <Button label="Submit" icon="pi pi-check" />
                </div>
              </div>
            </form>
          </Dialog>

          <div className="col-row">
            {/* <div className=" col-12 col-md-12 footerimg text-center thanku">
              <img src={thanku} width={200} />
            </div> */}

            <div className="Authfooter">
              <div className="row copy-righttext desingede">
                <div className="col-md-6 text-center">
                  Copyright © 2023 | Powered by LSL Tools Private Limited
                </div>
                <div className="col-md-6 text-center">
                  Design & Developed by{" "}
                  <a href="https://holostik.com/" target="_blank" className="">
                    Holostik India Limited
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  ////console.log('state.productAuthentication.feedback', state.productAuthentication.showDialogFeedback);
  ////console.log('state.productAuthentication.items', state.productAuthentication.items);
  console.log("scratch DATA", state.productAuthentication.scratch);
  return {
    Rating: state.productAuthentication.rating,
    ProductAuthenticationData:
      (state.productAuthentication.items &&
        state.productAuthentication.items.length) > 0
        ? state.productAuthentication.items[0]
        : null,
    ShowDialogFeedback: state.productAuthentication.showDialogFeedback,
    ShowDialogWarranty: state.productAuthentication.showDialogWarranty,
    ScratchData:
      (state.productAuthentication.scratch &&
        state.productAuthentication.scratch.length) > 0
        ? state.productAuthentication.scratch[0]
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyProduct: (productAuth) =>
      dispatch(productAuthenticationActions.verifyProduct(productAuth)),
    UpdateFeedback: (feedback) =>
      dispatch(productAuthenticationActions.UpdateFeedback(feedback)),
    UpdateWarranty: (warranty) =>
      dispatch(productAuthenticationActions.UpdateWarranty(warranty)),
    updateRating: (ratingData) =>
      dispatch(productAuthenticationActions.updateRating(ratingData)),
    onHideShowWarrnty: (isbool) =>
      dispatch(productAuthenticationActions.onHideShowWarranty(isbool)),
    onHideShow: (isbool) =>
      dispatch(productAuthenticationActions.onHideShow(isbool)),
    verifyScratch: (ScratchData) =>
      dispatch(productAuthenticationActions.verifyScratch(ScratchData)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductAuthentication);
