import { productMasterService } from "../services";
import { productConstants } from "../constants";
import { alertActions } from ".";

export const productMasterActions = {
  getAll,
  createProduct,
  updateProduct,
  inActiveRow,
  getProductName,
  getPrdctDDL,
  onHideShow,
  loadingData,
  onHideShowIsActive,
  UpdateIds,
  GetIds,
  GetMRPs,
  AddMRP,
  ProductWarranty,
  getPrdctDDLForSecondary
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());

    productMasterService.fetchProductData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: productConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: productConstants.GETALL_FAILURE, error };
  }
}

function getProductName(obj) {
  return (dispatch) => {
    dispatch(request());

    productMasterService.fetchProductName(obj).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: productConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: productConstants.GETALL_FAILURE, error };
  }
}

function createProduct(data) {
  return (dispatch) => {
    dispatch(request(data));

    productMasterService.createProduct(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: productConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: productConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: productConstants.CREATE_FAILURE, error };
  }
}

function updateProduct(data, obj) {
  return (dispatch) => {
    dispatch(request(data));

    productMasterService.updateProduct(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(getAll(obj));
            dispatch(onHideShow(false));
            dispatch(success(data));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: productConstants.UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: productConstants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: productConstants.UPDATE_FAILURE, error };
  }
}

function inActiveRow(productId, isActive) {
  var data = {
    ProductId: productId,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    productMasterService.inActiveRow(productId, isActive).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: productConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: productConstants.ISACTIVE_FAILURE, error };
  }
}

function getPrdctDDL() {
  return (dispatch) => {
    dispatch(request());
    productMasterService.fetchProductDLL().then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.GETPRDCTDDL_REQUEST };
  }
  function success(datas) {
    return { type: productConstants.GETPRDCTDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: productConstants.GETPRDCTDDL_FAILURE, error };
  }
}

function getPrdctDDLForSecondary(isSecondary, userId) {
  return (dispatch) => {
    dispatch(request());
    productMasterService.fetchProductDLLForSecondary(isSecondary, userId).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.GETPRDCTDDL_REQUEST };
  }
  function success(datas) {
    return { type: productConstants.GETPRDCTDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: productConstants.GETPRDCTDDL_FAILURE, error };
  }
}

function UpdateIds(type, value) {
  return (dispatch) => {
    dispatch(request());

    productMasterService.UpdateIds(type, value).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.UPDATE_ID_REQUEST };
  }
  function success(datas) {
    return { type: productConstants.UPDATE_ID_SUCCESS, datas };
  }
  function failure(error) {
    return { type: productConstants.UPDATE_ID_FAILURE, error };
  }
}

function GetIds(obj) {
  return (dispatch) => {
    dispatch(request());

    productMasterService.GetIds(obj).then(
      (response) => {
        dispatch(success(response.ResponseMessage));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.GET_ID_REQUEST };
  }
  function success(datas) {
    return { type: productConstants.GET_ID_SUCCESS, datas };
  }
  function failure(error) {
    return { type: productConstants.GET_ID_FAILURE, error };
  }
}

function AddMRP(value) {
  return (dispatch) => {
    dispatch(request());

    productMasterService.AddMRP(value).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.ADD_MRP_REQUEST };
  }
  function success(datas) {
    return { type: productConstants.ADD_MRP_SUCCESS, datas };
  }
  function failure(error) {
    return { type: productConstants.ADD_MRP_FAILURE, error };
  }
}

function GetMRPs(obj) {
  return (dispatch) => {
    dispatch(request());

    productMasterService.GetMRPs(obj).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.GET_MRP_REQUEST };
  }
  function success(datas) {
    return { type: productConstants.GET_MRP_SUCCESS, datas };
  }
  function failure(error) {
    return { type: productConstants.GET_MRP_FAILURE, error };
  }
}

function ProductWarranty(obj) {
  return (dispatch) => {
    dispatch(request());

    productMasterService.ProductWarranty(obj).then(
      (response) => {
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: productConstants.GET_ProductWarranty_REQUEST };
  }
  function success(datas) {
    return { type: productConstants.GET_ProductWarranty_SUCCESS, datas };
  }
  function failure(error) {
    return { type: productConstants.GET_ProductWarranty_FAILURE, error };
  }
}


function onHideShow(isbool) {
  return {
    type: productConstants.DIALOG_REQUEST,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: productConstants.LOADING_REQUEST,
    isbool,
  };
}

function onHideShowIsActive(isbool) {
  return {
    type: productConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}

