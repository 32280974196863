import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./../views/Dashboard";
import About from "./../views/About";
import CompanyMaster from "./../components/masters/companyMaster";
import OrderBooking from "./../components/masters/OrderBooking";
import userApproval from "./../components/masters/userApproval";
import ProductMaster from "./../components/masters/productMaster";
import roleMaster from "./../components/masters/roleMaster";
import CartonMaster from "../components/masters/cartonMaster";
import loyalityscheme from "./../components/masters/loyalityScheme";
import GiftMaster from "./../components/masters/GiftMaster";
import SqlEditor from "../components/masters/SqlEditor";
import Profile from "./../views/Profile";
import IssueNumber from "../components/mapping/IssueNumber";
import Mapping from "./../components/transactions/Mapping";
import DeMapping from "./../components/transactions/DeMapping";
import Damage from "./../components/transactions/Damage";
import UserMaster from "./../components/masters/userMaster";
import VerificationLog from "./../components/reports/VerificationLog";
import MappingRpt from "./../components/reports/MappingRpt";
import DemappingRpt from "./../components/reports/DemappingRpt";
import DamageRpt from "../components/reports/DamageRpt";
import LoyaltySchemeRpt from "./../components/reports/LoyaltySchemeRpt";
import FeedbackRpt from "./../components/reports/FeedbackRpt";
import ProductConfiguration from "../components/masters/ProductConfiguration";
import RedemptionApproval from "../components/transactions/RedemptionApproval";
import RedemptionLogRpt from "./../components/reports/RedemptionLogRpt";
import UserloyalityLogRpt from "../components/reports/UserloyalityLogRpt";
import SecondaryPacking from "../components/transactions/SecondaryPacking";
import OrderDispatch from "../components/transactions/OrderDispatch";
import DispatchReceive from "../components/transactions/DispatchReceive";
import CartonSlip from "../components/transactions/CartonSlip";
import dealerMaster from "./../components/masters/dealerMaster";
import SecondaryPackingReport from "../components/reports/SecondaryPackingReport";
import OrderDispatchReport from "../components/reports/OrderDispatchReport";
import OrderBookingRpt from "../components/reports/OrderBookingRpt";
import TertiaryPacking from "../components/transactions/TertiaryPacking";
import TertiaryPackingRpt from "../components/reports/TertiaryPackingRpt";
import ProductPurchaseRpt from "../components/reports/ProductPurchaseRpt";
import MRPMaster from "../components/masters/MRPMaster";
import categoryMaster from "../components/masters/categoryMaster";
import QRCodeTrackingRpt from "../components/reports/QRCodeTrackingRpt";
import SliderProMsg from "../components/masters/SliderProMsg";
import SODetails from "../components/masters/SODetails";
import WarrantyRegistrationReport from "../components/reports/WarrantyRegistrationReport";
import WarrantyRejectRegistrationReport from "../components/reports/WarrantyRejectRegistrationReport";
import WarrantyApproval from "../components/masters/WarrantyApproval";
//import WarehouseMaster from "../components/masters/WarehouseMaster";
import BinMaster from "../components/masters/BinMaster";
import WarrantyClaim from "../components/mapping/WarrantyClaim";
import WarrantyReapprove from "../components/mapping/WarrantyReapprove";
import New_Dispatch from "../components/transactions/New_Dispatch";
import Warranyrequesclaim from "../components/mapping/Warranyrequesclaim";
import GiftGenerate from "../components/transactions/GiftGenerate";

export default class Template extends Component {
  render() {
    return (
      <div className="container-fluid">
        <Header />
        <div className="row">
          <div className="col-md-2 sidebar-bg">
            <Sidebar />
          </div>
          <div className="col-md-10 body-part">
            <Router>
              <Switch>
                <Route exact path="/about" component={About} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/profile" component={Profile} />
                <Route
                  exact
                  path="/masters/company-master"
                  component={CompanyMaster}
                />
                {/* <Route
                  exact
                  path="/masters/Warehouse-master"
                  component={WarehouseMaster}
                /> */}
                <Route
                  exact
                  path="/masters/product-master"
                  component={ProductMaster}
                />
                <Route exact path="/masters/mrp-master" component={MRPMaster} />
                <Route exact path="/masters/role-master" component={roleMaster} />
                <Route exact path="/masters/carton-master" component={CartonMaster} />
                <Route exact path="/masters/loyalityscheme-master" component={loyalityscheme} />
                <Route exact path="/masters/gift-master" component={GiftMaster} />
                <Route exact path="/masters/user-master" component={UserMaster} />
                <Route exact path="/masters/product-configuration" component={ProductConfiguration} />
                <Route exact path="/masters/sql-editor" component={SqlEditor} />
                <Route
                  exact
                  path="/activation/issue-number"
                  component={IssueNumber}
                />
                <Route exact path="/transactions/damage" component={Damage} />
                <Route exact path="/transactions/mapping" component={Mapping} />
                <Route exact path="/transactions/de-mapping" component={DeMapping} />
                <Route exact path="/transactions/RedemptionApproval" component={RedemptionApproval} />
                <Route exact path="/transactions/gift-generator" component={GiftGenerate} />
                <Route exact path="/reports/verification-log" component={VerificationLog} />
                <Route exact path="/reports/mapping-report" component={MappingRpt} />
                <Route exact path="/reports/Demapping-report" component={DemappingRpt} />
                <Route exact path="/reports/Damage-report" component={DamageRpt} />
                <Route exact path="/reports/TertiaryPacking-report" component={TertiaryPackingRpt} />
                <Route exact path="/reports/Loyalty-Scheme-report" component={LoyaltySchemeRpt} />
                <Route exact path="/activation/Warranty-Claim" component={WarrantyClaim} />
                <Route exact path="/activation/warranty-request-Claim" component={Warranyrequesclaim} />
                <Route exact path="/activation/Warranty-Reapprove" component={WarrantyReapprove} />
                <Route exact path="/reports/Redemption-Log-report" component={RedemptionLogRpt} />
                <Route exact path="/reports/feedback-report" component={FeedbackRpt} />
                <Route exact path="/reports/Userloyality-report" component={UserloyalityLogRpt} />
                <Route exact path="/transactions/secondary-packing" component={SecondaryPacking} />
                <Route exact path="/transactions/Tertiary-Packing" component={TertiaryPacking} />
                <Route exact path="/transactions/dispatch" component={OrderDispatch} />
                <Route exact path="/transactions/New_Dispatch" component={New_Dispatch} />
                <Route exact path="/transactions/dispatch-receive" component={DispatchReceive} />
                <Route exact path="/transactions/carton-slip" component={CartonSlip} />
                <Route exact path="/masters/dealer-master" component={dealerMaster} />
                <Route exact path="/activation/warranty-approval" component={WarrantyApproval} />
                <Route exact path="/masters/upload-so-details" component={SODetails} />
                <Route exact path="/masters/order-booking" component={OrderBooking} />
                <Route exact path="/masters/user-approval" component={userApproval} />
                <Route exact path="/reports/secondarypacking-report" component={SecondaryPackingReport} />
                <Route exact path="/reports/dispatch-report" component={OrderDispatchReport} />
                <Route exact path="/reports/orderbooking-report" component={OrderBookingRpt} />
                <Route exact path="/reports/ProductPurchaseRpt-report" component={ProductPurchaseRpt} />
                <Route exact path="/reports/warranty-reg-rpt" component={WarrantyRegistrationReport} />
                <Route exact path="/reports/warranty-reject" component={WarrantyRejectRegistrationReport} />
                <Route exact path="/masters/category-master" component={categoryMaster} />
                <Route exact path="/reports/qr-code-tracking-report" component={QRCodeTrackingRpt} />
                <Route exact path="/masters/SliderProMsg" component={SliderProMsg} />
                <Route exact path="/masters/bin-master" component={BinMaster} />
                <Route path="*" component={Dashboard} />
              </Switch>
            </Router>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
