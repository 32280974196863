import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
// import UserService from '../../services/UserService';
import { BreadCrumb } from "primereact/breadcrumb";
// import CartonMasterService from '../../services/CartonMasterService';
// import SecondaryPackingService from '../../services/SecondaryPackingService';
// import PrintSecondaryPackagingService from '../../services/PrintSecondaryPackagingService';
// import MappingService from '../../services/MappingService';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import { ConfirmationModal } from '../../template/ConfirmationModal';
// import MessageComponent from '../../template/Message';
// import $ from 'jquery';
// import Loaders from '../../templates/Loaders';
import { connect } from "react-redux";
import {
  alertActions,
  CartonActions,
  secondaryPackingActions,
  productMasterActions,
} from "../../actions";
import alreadyPacked from "../../assets/audio/AlreadyScanned.mp3";
import invalidScan from "../../assets/audio/InvalidScan.mp3";
import { de, el } from "date-fns/locale";

class SecondaryPacking extends React.Component {
  constructor(props) {
    super(props);
    this.autoref = React.createRef();
    this.autorefCID = React.createRef();
    // this.UserService = new UserService();
    // this.CartonMasterService = new CartonMasterService();
    // this.MappingService = new MappingService();
    // this.SecondaryPackingService = new SecondaryPackingService();
    // this.PrintSecondaryPackagingService = new PrintSecondaryPackagingService();
    this.tblResponse = new tableRequest();
    this.alreadyPacked = new Audio(alreadyPacked);
    this.invalidScan = new Audio(invalidScan);
    this.state = {
      productSelected: false,
      reason: "",
      CartonName: "",
      Tvisible: false,
      ProductId: "",
      ProductName: "",
      PackingSize: "",
      rvisible: false,
      globalFilter: null,
      visible: false,
      sortIcon: false,
      productLoading: true,
      cartoonLoading: false,
      sortOrder: "1",
      formError: {},
      CartonDDL: [],
      CartonId: [],
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      IsDisabledCartonDDL: false,
      scanTxt: "",
      userId: 0
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitFormFinal = this.submitFormFinal.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onSort = this.onSort.bind(this);
    this.DATASEND = this.DATASEND.bind(this);
    this.ScanItem = this.ScanItem.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    this.setState({ userId: sessionStorage.getItem("UserData") });
    this.tblResponse.UserId = sessionStorage.getItem("UserData");
    this.tblResponse.SortColumn = "CartonId";
    setTimeout(() => {
      this.props.GetSecondaryPacking(this.tblResponse);
    }, 100);
    this.props.getPrdctDDL(sessionStorage.getItem("UserData"));
    this.props.fetchCartonList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.alreadyPackedAudio) {
      this.alreadyPacked.play();
      this.props.alreadyPacked(false);
    } else if (this.props.warningAudio) {
      this.invalidScan.play();
      this.props.warning(false);
    }

    if (prevProps.Tvisible != this.props.Tvisible) {
      debugger
      this.setState({ Tvisible: this.props.Tvisible });
      if (this.props.Tvisible == false) {
        this.setState({
          productSelected: false,
          CartonName: ""
        });
      } else {
        setTimeout(() => {
          this.autorefCID.current.focus();
        }, 500);
      }
    }
  }

  // PAGE EVENT CALL
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.GetSecondaryPacking(this.tblResponse);
    }, 250);
  }

  getDropValue() {

    let productId = "";
    this.props.ProductData.forEach((x) => {
      if (x.ProductId === this.state.ProductId) {
        productId = x.ProductId;
      }

    });
    return productId;
  }

  // set lazy loading values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    let errors = this.state.formError;

    if (this.props.scanItems === undefined) {
      this.setState({
        productSelected: false
      });
    } else {
      this.setState({
        productSelected: true
      });
    }

    if (name === 'ProductId') {
      const filteredProduct = this.props.ProductData.find(product => product.value === value);
      if (filteredProduct) {
        this.setState({
          PackingSize: filteredProduct.PackingSize,
          [name]: value
        });
      }
    }

    // Update state with the new value and errors
    this.setState({
      errors,
      [name]: trimmedValue
    });
  }



  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "UserName":
        if (value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter your username.";
        } else errors.UserName = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      IsDisabledCartonDDL: false,
      Tvisible: false,
      ReferenceID: "",
      CartonName: "",
      reason: "",
      rvisible: false,
      reason: "",
      UserName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: true,
      CartonId: [],
      ProductId: [],
      reason: "",
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  submitForm(event) {
    if (this.props.scanItems == undefined) {
      this.props.showWarn("Scan First");
      return;
    }
    else if (this.state.PackingSize != this.props.scanItems.length && this.state.reason.trim().length < 4) {
      // let reason = prompt("Carton is not fully Packed. \n Please Specify the Reason in min 5 chars Max 500 Chars", "");
      // reason = reason != null ? reason.trim() : "";
      this.setState({ rvisible: true })
    }
    else {
      this.setState({ Tvisible: true });
    }
  }

  // submit packing
  submitFormFinal(event) {
    event.preventDefault();
    if (this.props.scanItems == undefined) {
      this.props.showWarn("Scan First");
      return;
    }
    if (this.state.PackingSize >= this.props.scanItems.length) {
      if (this.state.PackingSize != this.props.scanItems.length && this.state.reason.trim().length < 4) {
        let reason = prompt("Carton is not fully Packed. \n Please Specify the Reason in min 5 chars Max 500 Chars", "");
        reason = reason != null ? reason.trim() : "";
        if (reason.length >= 5) {
          this.setState({ reason });
        } else {
          alert("Please provide a reason with at least 5 characters.");
        }
      }
      else {
        this.setState({ productLoading: false });
        const formData = {
          secondaryPackingDetails: this.props.scanItems.map(item => ({
            ...item,
            ProductId: this.state.ProductId
          })),
          reason: this.state.reason,
          ProductId: this.state.ProductId,
          CartonId: this.state.CartonName,
          CreatedBy: this.state.userId
        };
        this.setState({ productLoading: true });
        this.props.saveSecondaryPacking(formData)
        this.autoref.current.focus();
        this.state.scanItems = [];
      }
    } else {
      this.props.showWarn("Carton Contain Many item, Pls removed");
    }
  }

  // get active carton drop down list
  GetActiveCartonDDL() {
    this.CartonMasterService.GetActiveCartonDDL().then((data) => {
      const resdata = data.data;
      //if ResponseCode is 200
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          CartonDDL: resdata.ResponseData,
        });
      }
    });
  }

  // scan qr code
  ScanItem(e) {
    if (e.key === "Enter") {
      debugger
      let qr = e.target.value;

      if (this.state.ProductId.length === 0) {
        this.props.showWarn("Select Product");
        this.setState({ scanTxt: '' });
        return;
      }

      if (this.state.PackingSize === 0) {
        this.props.showWarn("Please select a product with a defined package size.");
        this.setState({ scanTxt: '' });
        return;
      }
      else
        if (this.state.PackingSize === this.props.scanItems?.length) {
          this.setState({
            scanTxt: ""
          });
          this.props.showWarn("Carton Full");
          this.setState({ Tvisible: true });
          setTimeout(() => {
            this.autorefCID.current.focus();
          }, 1000);
          return;
        }

      const fObj = { QrData: qr };
      this.props.GetProductByQrScan(fObj, this.state.PackingSize);
      this.setState({
        scanTxt: "",
        productSelected: true,
      });
      e.target.value = "";
    }
  }


  // delete user data
  deleteScanned(SeqNo) {
    let arrayfilter = this.props.scanItems.filter((x) => x.SeqNo != SeqNo);
  }

  DATASEND(data) {
    if (data === '310') {
      this.setState({ Tvisible: true });
    } else {
      this.setState({ Tvisible: false });
    }
  }

  // seach table
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.props.GetSecondaryPacking(this.tblResponse);
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  // disabled checkbox
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // Sort table
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.props.GetSecondaryPacking(this.tblResponse);
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    ////console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const deleteFunc = () => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure you want to delete this item?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.removeItem(rowData.SeqNo);
              this.setState({ productSelected: false });
            }
          },
          {
            label: "No",
            onClick: () => null
          }
        ]
      });
    };


    return (
      <span>
        <Button
          type="button"
          icon="pi pi-trash"
          onClick={deleteFunc}
          className="p-button-danger"
        ></Button>
      </span>
    );
  }

  // create html part
  render() {
    const items = [{ label: "Transaction" }, { label: "Secondary Packing" }];
    const TabView = (
      <Dialog
        className="popup-modal py-3 text-center"
        style={{ width: "30%" }}
        header="Scan the Carton Slip For Secodary Packing"
        visible={this.state.Tvisible}
        modal={true}
        onHide={() => {
          this.setState({ Tvisible: false });
        }}
      >
        <div className="py-3 text-center">
          {" "}
          <input
            type="text"
            value={this.state.CartonName}
            name="CartonName"
            onChange={(e) => {
              this.setState({ CartonName: e.target.value.toString().trim() });
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                if (e.target.value.includes("SID") && (e.target.value.match(/SID/g) || []).length == 1
                  && (e.target.value.match(/XP/g) || []).length == 1
                  && (e.target.value.match(/SID-XP-(19|20)\d{2}-[0-9]\d{6,10}/g) || []).length == 1) {
                  this.submitFormFinal(e);
                } else {
                  this.props.showWarn("Invalid Cartons");
                  this.setState({ CartonName: "" });
                }
              }
            }}
            ref={this.autorefCID}
          />
        </div>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div className="row">
        <div style={{ marginTop: "7px" }} className="col-md-2">
          <label>
            <h5 className="main-head">Secondary Packing</h5>
          </label>

        </div>
        <div className="col-md-2 mapping text-right">
          <label>Select Product</label>
        </div>
        <div className="col-md-3 text-left mt-2">
          <div className="form-group pl-3 pr-2" style={{ margin: "0rem" }}>
            <Dropdown
              appendTo={document.getElementById("root")}
              value={this.state.ProductId}
              disabled={this.state.productSelected}
              onChange={(e) => {
                this.handleChange(e);
              }}
              options={this.props.ProductData}
              optionValue="value"
              optionLabel="label"
              className="form-control custom-select"
              placeholder={"Select Product"}
              name="ProductId"
              filter
            />
          </div>
        </div>
        <div className="col-md-2 mapping text-right">
          <label>Scan items:</label>
        </div>
        <div className="col-md-3 text-left mt-2">
          <div className="form-group pl-3 pr-2" style={{ margin: "0rem" }}>
            <input
              name="scanTxt"
              ref={this.autoref}
              value={this.state.scanTxt}
              type="search"
              className="form-control"
              onChange={(e) => {
                this.handleChange(e);
              }}
              id="scanTxt"
              onKeyDown={(e) => {
                this.ScanItem(e);
              }}
            />
          </div>
        </div>

        <div className="col-md-12  ml-1 text-right">
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="30"
          />
          <i className="pi pi-search searche" style={{ margin: "4px 4px 0 0" }}></i>
        </div>
      </div>
    );

    let header2 = (
      <div className="row right">
        <div className="col-md-4 text-left right">
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="30"
          />
          <i className="pi pi-search searche" style={{ margin: "4px 4px 0 0" }}></i>
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <div className="card">
          <div>
            <audio hidden src={this.alreadyPacked} type="audio/mpeg" controls />
            <audio hidden src={this.invalidScan} type="audio/mpeg" controls />
            <DataTable
              responsive={true}
              header={header}
              emptyMessage={this.state.emptyMessage}
              value={this.props.scanItems}
              paginator={true}
              currentPageReportTemplate="Total Items Added: {totalRecords}, Showing {first} to {last}"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              sortMode="multiple"
              totalRecords={this.state.scanItems.length}
              rows={this.state.rows}
              className="tblAuto"
              globalFilter={this.state.globalFilter}
            >
              <Column style={{ width: "25%" }} field="SeqNo" header="Visible No" sortable={true} />

              <Column body={this.actionTemplate} style={{ width: "25%" }} header="Action" />
            </DataTable>
            <div className="col-md-12 text-center mt-4 mb-4">
              <button className="btn btn-primary" onClick={this.submitForm}>
                Submit
              </button>
            </div>
            <Dialog
              visible={this.state.rvisible}
              className="popup-modal"
              style={{ width: "30%" }}
              modal={true}
              header="Fractional Carton Remark"
              onHide={() => {
                this.setState({ rvisible: false });
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <h5>Define in min 5 char</h5>
                  <label>
                    Submit Remark<span>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ width: "95%", height: "30px" }}
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-12 text-center mt-4">
                <button
                  type="button"
                  onClick={(e) => {
                    this.setState({ rvisible: false });
                    this.submitForm(e);
                  }}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </Dialog>
            <div className="col-md-12 p-0">
              <DataTable
                responsive={true}
                header={header2}
                globalFilter={this.state.globalFilter}
                emptyMessage={this.state.emptyMessage}
                value={this.props.secondaryItems}
                paginator={true}
                rows={this.state.rows}
                rowsPerPageOptions={[10, 50, 200, 500]}
                totalRecords={parseInt(this.state.totalRecords)}
                lazy={true}
                first={this.state.first}
                onPage={this.onPage}
                onSort={this.onSort}
                className="tblAuto"
              >
                <Column
                  field="CartonId"
                  style={{ width: "35%" }}
                  header="CartonId"
                  sortable={true}
                />
                <Column
                  field="TotalQty"
                  style={{ width: "35%" }}

                  header="Total Qty"
                />
                <Column
                  field="CreatedDt"
                  style={{ width: "30%" }}

                  header="Created Date"
                  sortable={true}
                />
              </DataTable>
              <br />
              <br />
            </div>
            {TabView}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("Scaned DATA:", state.secondaryPacking?.Product)
  return {
    CartonDDL: state.Carton?.cartonddl,
    Tvisible: state.secondaryPacking?.Tvisible,
    code: state.secondaryPacking.Product,
    scanItems: state.secondaryPacking?.scanneditems,
    secondaryItems: state.secondaryPacking?.secondaryItems,
    second: state.secondaryPacking.items,
    warningAudio: state.secondaryPacking?.warning,
    alreadyPackedAudio: state.secondaryPacking?.alreadyPacked,
    ProductData:
      (typeof state.product.prdctddl !== "undefined" &&
        state.product.prdctddl !== null) === true
        ? state.product?.prdctddl
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    fetchCartonList: () => {
      dispatch(CartonActions.GetActiveCartonDDL());
    },
    GetProductByQrScan: (QrData, PackingSize) =>
      dispatch(
        secondaryPackingActions.getProductByQrScan(QrData, PackingSize)
      ),
    removeItem: (data) => dispatch(secondaryPackingActions.removeItem(data)),
    saveSecondaryPacking: (formData) =>
      dispatch(secondaryPackingActions.saveSecondaryPacking(formData)),
    showWarn: (message) => dispatch(alertActions.warning(message)),
    GetSecondaryPacking: (tblObj) =>
      dispatch(secondaryPackingActions.getSecondaryPacking(tblObj)),
    warning: (bool) => dispatch(secondaryPackingActions.warning(bool)),
    alreadyPacked: (bool) =>
      dispatch(secondaryPackingActions.alreadyPacked(bool)),
    getPrdctDDL: (userId) => dispatch(productMasterActions.getPrdctDDLForSecondary(1, userId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SecondaryPacking);
