import { GiftMasterService } from '../services/GiftMasterService';
import { GiftMasterConstants } from '../constants/GiftMasterConstants';
import { userService } from '../services';
import { userConstants } from '../constants';
import { alertActions } from '.';
import { history } from '../helpers';

export const GiftMasterActions = {
    getAll,    
    createScheme,
    inActiveRow,  onHideShow,  
    loadingData,
    onHideShowIsActive,
    QuarterDDL,
    ProductDDL
};



function getAll(data) {
    
    return dispatch => {
        dispatch(request());
        GiftMasterService.fetchSchemeData(data).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: GiftMasterConstants.GETALL_REQUEST } }
    function success(datas) { return { type: GiftMasterConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: GiftMasterConstants.GETALL_FAILURE, error } }
}



function QuarterDDL() {
    debugger
    return (dispatch) => {
      dispatch(request());
      GiftMasterService.fetchDDL().then(
        (response) => {
          dispatch(success(response.ResponseData));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
    function request() {
        return { type: GiftMasterConstants.GETQDDL_REQUEST };
      }
      function success(datas) {
        return { type: GiftMasterConstants.GETQDDL_SUCCESS, datas };
      }
      function failure(error) {
        return { type: GiftMasterConstants.GETQDDL_FAILURE, error };
      }
}

function ProductDDL() {
    
    return (dispatch) => {
      dispatch(request());
      GiftMasterService.ProductDDL().then(
        (response) => {
          dispatch(success(response.ResponseData));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
    function request() {
        return { type: GiftMasterConstants.GETPDDL_REQUEST };
      }
      function success(datas) {
        return { type: GiftMasterConstants.GETPDDL_SUCCESS, datas };
      }
      function failure(error) {
        return { type: GiftMasterConstants.GETPDDL_FAILURE, error };
      }
}
function createScheme(data) {
    debugger
    return dispatch => {
        dispatch(request(data));

        GiftMasterService.createScheme(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(onHideShow(false));  // Close the dialog
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage));
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage));
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };



    function request(data) { return { type: GiftMasterConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: GiftMasterConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: GiftMasterConstants.CREATE_FAILURE, error } }
};

function inActiveRow(SchemeId, isActive) {
    var data = {
        SchemeId: SchemeId,
        IsActive: isActive
    }
    return dispatch => {
        dispatch(request());

        GiftMasterService.inActiveRow(SchemeId, isActive).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(data));
                        dispatch(onHideShowIsActive(false));
                        dispatch(alertActions.success(response.ResponseMessage))
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: GiftMasterConstants.ISACTIVE_REQUEST } }
    function success(data) { return { type: GiftMasterConstants.ISACTIVE_SUCCESS, data } }
    function failure(error) { return { type: GiftMasterConstants.ISACTIVE_FAILURE, error } }
};

function onHideShow(isbool) {
    return {
        type: GiftMasterConstants.DIALOG_REQUEST, isbool
    }
}

function loadingData(isbool) {
    return {
        type: GiftMasterConstants.LOADING_REQUEST, isbool
    }
}

function onHideShowIsActive(isbool) {
    return {
        type: GiftMasterConstants.DIALOG_ISACTIVE_REQUEST, isbool
    }
}



