import React, { Component } from "react";
import { Chart as CC } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import Slider from "react-slick";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  dashboardActions,
  mappingActions,
  SliderProMsgActions,
} from "../actions";
import tableRequest from "../models/tableRequest.js";
// import bg from './../assets/images/HP-BG.jpg'
import "./Sidebar.css";

import {
  Chart,
  BarSeries,
  Legend,
  Tooltip,
  Title,
  ArgumentAxis,
  ValueAxis,
  PieSeries,
} from "@devexpress/dx-react-chart-material-ui";
import * as d3Format from "d3-format";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import { Animation } from "@devexpress/dx-react-chart";
import { HoverState, Stack, EventTracker } from "@devexpress/dx-react-chart";

const tooltipContentTitleStyle = {
  fontWeight: "bold",
};
const TitleText = (props) => (
  <Title.Text {...props} className="mb-5 mt-3 css-Header" />
);
const formatTooltip = d3Format.format(",.2r");

const Root = (props) => (
  <Legend.Root {...props} className="m-auto flex-row svg-bar" />
);

const RootPie = (props) => (
  <Legend.Root {...props} className="m-auto flex-row css-legend legend-item" />
);
const compareTargets = (
  { series, point },
  { series: targetSeries, point: targetPoint }
) => series === targetSeries && point === targetPoint;

const columns = [
  { title: "Order No.", field: "OrderBookingNo" },
  { title: "Order Date", field: "OrderDate" },
  { title: "User Name", field: "UserName" },
  { title: "Dispatch Status", field: "Status" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f4f6",
    color: theme.palette.common.black,
    fontSize: 13.5,
    textTransform: "uppercase",
    borderBottom: "none",
    border: "none",
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13.5,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    borderBottom: "none",
    border: 0,
  },
  "&:nth-of-type(even)": {
    borderBottom: "none",
    border: 0,
  },
}));

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.state = {
      hover: null,
      globalFilter: null,
      first: 0,
      selection: [
        { ShortMonthName: "Jan", MonthName: "January", OrderCount: 0 },
      ],
      rows: 5,
      totalRecords: 0,
      sortOrder: "",
      loading: false,
      UserType: 0,
      DealerId: 0,
      tooltipTarget: null,
      tooltipEnabled: true,
    };
    this.click = ({ targets }) => {
      const target = targets[0];
      if (target) {
        this.setState(({ selection }) => ({
          selection:
            selection[0] && compareTargets(selection[0], target)
              ? []
              : [target],
        }));
      }
    };
    this.changeHover = (hover) => this.setState({ hover });
    this.changeTooltip = (targetItem) =>
      this.setState({ tooltipTarget: targetItem });
    this.basicData = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Pending",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: "#42A5F5",
        },
        {
          label: "Active",
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          borderColor: "#FFA726",
        },
      ],
    };
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        }
      }
    }
    this.props.fetchApi(this.tblResponse);
    this.props.fetchMappingApi(this.tblResponse);

    const userDetails = sessionStorage.getItem("user");
    const DealerId = sessionStorage.getItem("UserData");

    const userdetailsObject = JSON.parse(userDetails);
    this.setState({
      UserType: userdetailsObject.UserType,
      DealerId: DealerId,
    });
    //this.props.fetchSliderApi();
  }

  componentDidUpdate(prevProps) {
    if (this.props.MappingData) {
      if (prevProps.MappingData !== this.props.MappingData) {
        // this.props.myProp has a different value
        if (this.props.MappingData) {
          var e = this.props.MappingData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }

    if (prevProps.MaxFromNo !== this.props.MaxFromNo) {
      this.setState({
        FromNo: this.props.MaxFromNo,
      });
    }
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchMappingApi(this.tblResponse);
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchMappingApi(this.tblResponse);
    this.props.fetchApi(this.tblResponse);
  }

  getStatus(value) {
    if (value == 0) {
      return <span className="span-status pending">Pending</span>;
    } else if (value == 1) {
      return (
        <span className="span-status p-dispatch">Partially Dispatched</span>
      );
    } else if (value == 2) {
      return <span className="span-status dispatch">Dispatched</span>;
    }
  }

  onPage(event) {
    //imitate delay of a backend call
    this.getLazyLoadingValues(event);
    this.props.fetchMappingApi(this.tblResponse);
    this.props.fetchApi(this.tblResponse);
  }
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      height: "100%",
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    const TooltipContent = (props) => {
      const { targetItem, text, ...restProps } = props;
      return (
        <div>
          <div>
            <Tooltip.Content
              {...restProps}
              style={tooltipContentTitleStyle}
              text={this.props.OrderChart[targetItem.point]["MonthName"]}
            />
          </div>
          <div>
            <Tooltip.Content
              {...restProps}
              text={
                "Total Orders: " +
                this.props.OrderChart[targetItem.point]["OrderCount"]
              }
            />
          </div>
        </div>
      );
    };

    const TooltipContentPieChart = (props) => {
      const { targetItem, text, ...restProps } = props;
      return (
        <div>
          <div>
            <Tooltip.Content
              {...restProps}
              style={tooltipContentTitleStyle}
              text={this.props.ProductData[targetItem.point]["ProductName"]}
            />
          </div>
          <div>
            <Tooltip.Content
              {...restProps}
              text={
                "Total Qty Ordered: " +
                this.props.ProductData[targetItem.point]["OrderedQty"]
              }
            />
          </div>
        </div>
      );
    };

    const chartdata = {
      labels: this.props.Months,
      datasets: [
        {
          label: "Secondary Product Mapping ",
          data: this.props.MappingChart,
          fill: false,
          borderColor: "#4bc0c0",
        },
        {
          label: "Product Damage",
          data: this.props.DeMappingChart,
          fill: false,
          borderColor: "#9bc0c9",
        },
      ],
    };

    const { hover, selection, tooltipTarget, tooltipEnabled } = this.state;

    return (
      <div className="container-fluid m-top">
        <div className="row no-gutters bux mx margin-y">
          <div className="col-6 col-sm-2  service-card">
            <div className="panel panel-info">
              <div className="panel-heading">Numbers Issued</div>
              <div className="panel-body">
                <div className="rotate">
                  <i className="fa fa-list-ol fa-4x"></i>
                </div>
                <h1>{this.props.TotalIssue ? this.props.TotalIssue : 0}</h1>
              </div>
            </div>
          </div>
          <div className="col-6 col-sm-2 service-card">
            <div className="panel panel-info">
              <div className="panel-heading">Secondary Mapping</div>
              <div className="panel-body">
                <div className="rotate">
                  <i className="fa fa-map fa-4x"></i>
                </div>
                <h1>{this.props.TotalMapping ? this.props.TotalMapping : 0}</h1>
              </div>
            </div>
          </div>
          <div className="col-6 col-sm-2  service-card">
            <div className="panel panel-info">
              <div className="panel-heading">Claim Warranty</div>
              <div className="panel-body">
                <div className="panel-body">
                  <div className="rotate">
                    <i className="fa fa-book fa-4x"></i>
                  </div>
                  <h1>
                    {this.props.TotalDeMapping ? this.props.TotalDeMapping : 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* new order */}

          <div className="col-6 col-sm-2  service-card">
            <div className="panel panel-info">
              <div className="panel-heading">Damages</div>
              <div className="panel-body">
                <div className="rotate">
                  <i className="fa fa-exclamation-triangle fa-4x"></i>
                </div>
                <h1>{this.props.TotalDamage ? this.props.TotalDamage : 0}</h1>
              </div>
            </div>
          </div>
          <div className="col-6 col-sm-2 service-card">
            <div className="panel panel-info">
              <div className="panel-heading">Total Scans</div>
              <div className="panel-body">
                <div className="rotate">
                  <i className="fa fa-qrcode fa-4x"></i>
                </div>
                <h1>{this.props.TotalScans ? this.props.TotalScans : 0}</h1>
              </div>
            </div>
          </div>

          {/* User Approvel */}
          <div className="col-6 col-sm-2 service-card">
            <div className="panel panel-info">
              <div className="panel-heading">Pending User</div>
              <div className="panel-body">
                <div className="panel-body">
                  <div className="rotate">
                    <i className="fa fa-user fa-4x"></i>
                  </div>
                  <h1>
                    {this.props.TotalUserApproval
                      ? this.props.TotalUserApproval
                      : 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          {/* <div className="col-md-6">
              <div class="card">
                <div class="card-header dashchart">Order Booking</div>
                <div class="card-body">
                  <div hidden={!this.props.OrderChart}>
                    <div className="card bar-card pl-3 pr-2">
                      <Chart
                        data={
                          this.props.OrderChart == (null || undefined)
                            ? [{ MonthName: " ", OrderCount: " " }]
                            : this.props.OrderChart
                        }
                      >
                        <ArgumentAxis />
                        <ValueAxis max={12} />
                        <BarSeries
                          name="Monthly Order Counts"
                          valueField="OrderCount"
                          argumentField="ShortMonthName"
                        />

                        <Animation />
                        <Stack />
                        <Legend position="bottom" rootComponent={Root} />
                        <EventTracker onClick={this.click} />
                        <HoverState
                          hover={hover}
                          onHoverChange={this.changeHover}
                        />
                        <Tooltip
                          onTargetItemChange={this.changeTooltip}
                          contentComponent={TooltipContent}
                        />
                      </Chart>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          {/* <div className="col-md-6">
              <div class="card">
                <div class="card-header dashchart">Top Ordered Products</div>
                <div class="card-body">
                  <div hidden={!this.props.ProductData} className=" pie-chart">
                    <div className="card bar-card">
                      <Chart
                        className="pl-4"
                        data={
                          this.props.ProductData == (null || undefined)
                            ? [{ MonthName: " ", OrderCount: " " }]
                            : this.props.ProductData
                        }
                      >
                        <PieSeries
                          valueField="OrderedQty"
                          argumentField="ProductName"
                        />

                        <Animation />
                        <Stack />

                        <EventTracker onClick={this.click} />
                        <HoverState
                          hover={hover}
                          onHoverChange={this.changeHover}
                        />
                        <Tooltip
                          onTargetItemChange={this.changeTooltip}
                          contentComponent={TooltipContentPieChart}
                        />
                        <Legend position="right" rootComponent={RootPie} />
                      </Chart>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>

        <div className="row mt-3">
          <div className="col-md-6 boxx">
            <div className="cards">
              <div class="card-header dashchart">
                Secondary Product Mapping Vs Damage Charts
              </div>
              <CC type="line" data={chartdata} />
            </div>
          </div>
          {/* <div className="col-md-6 boxx">
              <div class="card">
                <div class="card-header dashchart">Latest Orders</div>
                <div class="card-body">
                  <div hidden={!this.props.OrderDataList}>
                    <div className="cards bar-card">
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {columns.map((row) => (
                                <StyledTableCell>{row.title}</StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.props?.OrderDataList?.map((row) => (
                              <StyledTableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell align="left">
                                  {row.OrderBookingNo}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.OrderDate}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.UserName}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {this.getStatus(row.Status)}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DashboardList: state.dashboard?.items?.DashboardListData,
    TotalMapping: state.dashboard?.items?.DashboardCount?.TotalMapping,
    TotalDeMapping: state.dashboard?.items?.DashboardCount?.TotalDeMapping,
    TotalDamage: state.dashboard?.items?.DashboardCount?.TotalDamage,
    TotalScans: state.dashboard?.items?.DashboardCount?.TotalScans,
    TotalIssue: state.dashboard?.items?.DashboardCount?.TotalIssue,
    DeMappingChart: state.dashboard?.items?.DeMappingList,
    MappingChart: state.dashboard?.items?.MappingList,
    Months: state.dashboard?.items?.MonthName,
    MappingData: state.mapping.items,
    OrderChart: state.dashboard?.items?.OrderCharts,
    ProductData: state.dashboard?.items?.ProductData,
    OrderDataList: state.dashboard?.items?.OrderDataList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(dashboardActions.fetchData(obj));
    },
    loadingData: (isLoding) => dispatch(dashboardActions.loadingData(isLoding)),
    fetchMappingApi: (obj) => {
      dispatch(mappingActions.getAll(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
