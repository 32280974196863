export const GiftGenerateConstants = {
    APPROVE_REQUEST: 'GiftGenerate_APPROVE_REQUEST',
    APPROVE_SUCCESS: 'GiftGenerate_APPROVE_SUCCESS',
    APPROVE_FAILURE: 'GiftGenerate_APPROVE_FAILURE',

    CANCEL_REQUEST: 'GiftGenerate_CANCEL_REQUEST',
    CANCEL_SUCCESS: 'GiftGenerate_CANCEL_SUCCESS',
    CANCEL_FAILURE: 'GiftGenerate_CANCEL_FAILURE',

    CREATE_REQUEST: 'GiftGenerate_CREATE_REQUEST',
    CREATE_SUCCESS: 'GiftGenerate_CREATE_SUCCESS',
    CREATE_FAILURE: 'GiftGenerate_CREATE_FAILURE',

    GETALL_REQUEST: 'GiftGenerate_GETALL_REQUEST',
    GETALL_SUCCESS: 'GiftGenerate_GETALL_SUCCESS',
    GETALL_FAILURE: 'GiftGenerate_GETALL_FAILURE',

    GETALL_USERPT_REQUEST: 'GiftGenerate_GETALL_USERPT_REQUEST',
    GETALL_USERPT_SUCCESS: 'GiftGenerate_GETALL_USERPT_SUCCESS',
    GETALL_USERPT_FAILURE: 'GiftGenerate_GETALL_USERPT_FAILURE',

    GETALL_USER_REQUEST: 'GiftGenerate_GETALL_USER_REQUEST',
    GETALL_USER_SUCCESS: 'GiftGenerate_GETALL_USER_SUCCESS',
    GETALL_USER_FAILURE: 'GiftGenerate_GETALL_USER_FAILURE',


    DELETE_REQUEST: 'GiftGenerate_DELETE_REQUEST',
    DELETE_SUCCESS: 'GiftGenerate_DELETE_SUCCESS',
    DELETE_FAILURE: 'GiftGenerate_DELETE_FAILURE',
    
    ISACTIVE_REQUEST: 'GiftGenerate_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'GiftGenerate_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'GiftGenerate_ISACTIVE_FAILURE',
    
    DIALOG_REQUEST:'DIALOG_REQUEST',

    DIALOGUE_REQUEST:'DIALOGUE_REQUEST',
    
    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST',

    REJECT_DIALOGUE_REQUEST : 'REJECT_DIALOGUE_REQUEST'
};