import { BinMasterService } from "../services";
import { BinMasterConstants } from "../constants";
import { alertActions } from ".";
import { history } from "../helpers";

export const BinMasterActions = {
  getAll,    
  createBin,
  updateBin,
  inActiveRow,
  onHideShow,
  loadingData,
  onHideShowIsActive, 
  exportData,
  getWarehouseDDL,
  fetchAllBinData
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());

    BinMasterService.fetchBinData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: BinMasterConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: BinMasterConstants.GETALL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: BinMasterConstants.GETALL_FAILURE, error };
  }
}

function fetchAllBinData() {;
  return dispatch => {
      dispatch(request());

      BinMasterService.fetchAllBinData().then(response => {
          dispatch(loadingData(false));
          dispatch(success(response.ResponseData))
      },
          error => {
              dispatch(loadingData(false));
              dispatch(failure(error.toString()));
              dispatch(alertActions.error(error.toString()));
          }
      );
  };

  function request() { return { type: BinMasterConstants.GETALLBIN_REQUEST } }
  function success(datas) { return { type: BinMasterConstants.GETALLBIN_SUCCESS, datas } }
  function failure(error) { return { type: BinMasterConstants.GETALLBIN_FAILURE, error } }
}


function getWarehouseDDL() {
  return dispatch => {
      dispatch(request());

      BinMasterService.fetchWarehouseDDL().then(response => {
          dispatch(success(response.ResponseData))
      },
          error => {
              dispatch(failure(error.toString()));
              dispatch(alertActions.error(error.toString()));
          }
      );
  };


  function request() {
    return { type: BinMasterConstants.GETWHDDL_REQUEST };
  }
  function success(datas) {
    return { type: BinMasterConstants.GETWHDDL_SUCCESS, datas };
  }
  function failure(error) {
    return { type: BinMasterConstants.GETWHDDL_FAILURE, error };
  }
}

function createBin(data) {
  return (dispatch) => {
    dispatch(request(data));

    BinMasterService.createBin(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(response.ResponseData));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: BinMasterConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: BinMasterConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: BinMasterConstants.CREATE_FAILURE, error };
  }
}

function updateBin(data) {
  return (dispatch) => {
    dispatch(request(data));

    BinMasterService.updateBin(data).then(
      (response) => {
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShow(false));
            dispatch(alertActions.success(response.ResponseMessage));
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: BinMasterConstants.UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: BinMasterConstants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: BinMasterConstants.UPDATE_FAILURE, error };
  }
}

function inActiveRow(binId, isActive) {
  var data = {
    BinId: binId,
    IsActive: isActive,
  };
  return (dispatch) => {
    dispatch(request());

    BinMasterService.inActiveRow(binId, isActive).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(success(data));
            dispatch(onHideShowIsActive(false));
            dispatch(alertActions.success(response.ResponseMessage));
            window.location.reload();
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: BinMasterConstants.ISACTIVE_REQUEST };
  }
  function success(data) {
    return { type: BinMasterConstants.ISACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: BinMasterConstants.ISACTIVE_FAILURE, error };
  }
}

function exportData(obj) {
  return (dispatch) => {
    dispatch(request());
    BinMasterService.fetchBinData(obj).then(
      (response) => {
        ;
        dispatch(loadingData(false));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            
          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: BinMasterConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: BinMasterConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: BinMasterConstants.GETEXPORT_FAILURE, error };
  }
}

function onHideShow(isbool) {
  return {
    type: BinMasterConstants.DIALOG_REQUEST,
    isbool,
  };
}

function loadingData(isbool) {
  return {
    type: BinMasterConstants.LOADING_REQUEST,
    isbool,
  };
}

function onHideShowIsActive(isbool) {
  return {
    type: BinMasterConstants.DIALOG_ISACTIVE_REQUEST,
    isbool,
  };
}
