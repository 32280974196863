import { combineReducers } from 'redux';
import { Carton } from './CartonReducer';
import { product } from './productReducer';
import { user } from './userReducer';
import { alert } from './alertReducer';
import { role } from "./roleMasterReducer";
import { dashboard } from "./dashboardReducer";
import { login } from "./loginReducer";
import { menu } from "./menuReducer";
import { changePassword } from "./changePasswordReducer";
import {mapping} from "./mappingReducer";
import {issueNo} from './issueNoReducer';
import {damage} from './damageReducer';
import { verification } from "./verificationLogReducer";
import { mappingRpt } from "./mappingRptReducer";
import {FeedbackRpt} from "./FeedbackRptReducer";
import { DemappingRpt } from './DemappingRptReducer';
import { DamageRpt } from './DamageRptReducer';
import { LoyaltySchemeRpt } from './LoyaltySchemeRptReducer';
import { RedemptionLogRpt } from './RedemptionLogRptReducer';
import { UserloyalityLogRpt } from './UserloyalityLogRptReducer';
import {SqlEditor} from "./SqlEditorReducer";
import {demapping} from './demappingReducer'; 
import { productAuthentication } from './productAuthenticationReducer';
import { productConfiguration } from './productConfigurationReducer';
import { company } from "./companyReducer";
import { OrderBooking } from "./OrderBookingReducer";
import { OrderBookingRpt } from "./OrderBookingRptReducer";
import { loyalityscheme } from './loyalityschemeReducer';
import { Dispatch } from './DispatchReducer';
import {DispatchReport} from './DispatchRptReducer'
import { RedemptionApproval } from './RedemptionApprovalReducer';
import { TertiaryPacking } from './TertiaryPackingReducer';
import {TertiaryPackingRpt} from './TertiaryPackingRptReducer';
import { ProductPurchaseRpt } from './ProductPurchaseRptReducer';
import {secondaryPacking} from './secondaryPackingReducer';
import {SecondaryPackingReport} from './SecondaryPackingRptReducer';
import {dealer} from './dealerReducer';
import { category } from './categoryReducer';
import { QRCodeTrackingRpt} from './QRCodeTrackingRptReducer';
import {SliderProMsg} from './SliderProMsgReducer';
import {warranty  } from './WarrantyRegistrationReportReducer';
import {warrantyReject} from './WarrantyRejectRegistrationReportReducer';
import { BinMaster } from './BinMasterReducer';
import { GiftMaster } from './GiftMasterReducer';
import {GiftGenerate} from './GiftGenerateReducer'
const rootReducer = combineReducers({    
    user,
    alert,
    Carton,
    product,
    role,
    dashboard,
    login,
    menu,
    changePassword,
    mappingRpt,
    DemappingRpt,
    DamageRpt,
    FeedbackRpt,
    SqlEditor,
    issueNo,
    damage,
    verification,
    mapping,
    verification,
    demapping,
    productAuthentication,
    productConfiguration,
    company,
    OrderBooking,
    OrderBookingRpt,
    LoyaltySchemeRpt,
    RedemptionLogRpt,
    UserloyalityLogRpt,
    RedemptionApproval,
    loyalityscheme,
    TertiaryPacking,
    TertiaryPackingRpt,
    Dispatch,
    DispatchReport,
    ProductPurchaseRpt,
    secondaryPacking,
    SecondaryPackingReport,
    dealer,
    category,
    QRCodeTrackingRpt,
    SliderProMsg,
    warranty,
    SliderProMsg,
    BinMaster,
    warrantyReject,
    GiftMaster,
    GiftGenerate
    
});
 
export default rootReducer;