export const WarrantyRegistrationReportConstants ={
    GETALL_REQUEST: 'WARRANTY_GETALL_REQUEST',
    GETALL_SUCCESS: 'WARRANTY_GETALL_SUCCESS',
    GETALL_FAILURE: 'WARRANTY_GETALL_FAILURE',

    GETEXPORT_REQUEST: 'WARRANTY_GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'WARRANTY_GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'WARRANTY_GETEXPORT_FAILURE',

    LOADING_REQUEST: 'LOADING_REQUEST',

    DIALOG_REQUEST : 'DIALOG_REQUEST',

    ISACTIVE_REQUEST :'WARRANTY_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'WARRANTY_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE : 'WARRANTY_ISACTIVE_SUCCESS',

    DIALOG_ISACTIVE_REQUEST : 'DIALOG_ISACTIVE_REQUEST'
}