import { mappingRptService } from "../services";
import { mappingRptConstants } from "../constants";
import { alertActions } from ".";

export const mappingRptActions = {
  getAll,
  loadingData,
  exportData,
};

function getAll(obj) {
  return (dispatch) => {
    dispatch(request());

    mappingRptService.fetchMappingData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.CreatedBy;
            delete v.DocURL;
            delete v.MappingId;
            delete v.ProductID;
          });
        }
        dispatch(successExport(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: mappingRptConstants.GETALL_REQUEST };
  }
  function success(datas) {
    return { type: mappingRptConstants.GETALL_SUCCESS, datas };
  }
  function successExport(datas) {
    return { type: mappingRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: mappingRptConstants.GETALL_FAILURE, error };
  }
}

function exportData(obj) {
  return (dispatch) => {
    dispatch(request());
    mappingRptService.fetchMappingData(obj).then(
      (response) => {
        dispatch(loadingData(false));
        var data = response.ResponseData;
        if (data != null) {
          data.forEach(function (v) {
            delete v.TotalRows;
            delete v.CreatedBy;
            delete v.DocURL;
            delete v.MappingId;
            delete v.ProductID;
            delete v.MFGDate;
            delete v.EXPDate;
            delete v.SeqNo;
          });
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: mappingRptConstants.GETEXPORT_REQUEST };
  }
  function success(datas) {
    return { type: mappingRptConstants.GETEXPORT_SUCCESS, datas };
  }
  function failure(error) {
    return { type: mappingRptConstants.GETEXPORT_FAILURE, error };
  }
}

function loadingData(isbool) {
  return {
    type: mappingRptConstants.LOADING_REQUEST,
    isbool,
  };
}
