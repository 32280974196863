import { authHeader, handleResponse } from "../helpers";
export const CartonService = {
  GetCarton,
  CreateCarton,
  UpdateCarton,
  GetActiveCartonDDL,
};

function GetCarton(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CartonMaster/GetCarton`,
    requestOptions
  ).then(handleResponse);
}

function CreateCarton(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CartonMaster/CreateCarton`,
    requestOptions
  ).then(handleResponse);
}

function UpdateCarton(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}CartonMaster/UpdateCarton`,
    requestOptions
  ).then(handleResponse);
}

function GetActiveCartonDDL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}CartonMaster/GetActiveCartonDDL`,
    requestOptions
  ).then(handleResponse);
}

////////////////////////////////////////////////////////////////////////////////////////////////
//import instance from './DefaultApi';
//
//export default class CartonMasterService {
//    ControllerName = 'CartonMaster';
//
//    //Get Carton List
//    GetCarton(tableRequest) {
//        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCarton`, tableRequest).then(res => res)
//    }
//
//    // Save Carton Master Data
//    CreateCarton(CartonData) {
//        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateCarton`, CartonData).then(res => res)
//    }
//
//    // Get Active Carton of Drop Down List
//    GetActiveCartonDDL() {
//        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetActiveCartonDDL`).then(res => res)
//    }
//}
