export const BinMasterConstants = {
    CREATE_REQUEST: 'BIN_CREATE_REQUEST',
    CREATE_SUCCESS: 'BIN_CREATE_SUCCESS',
    CREATE_FAILURE: 'BIN_CREATE_FAILURE',

    UPDATE_REQUEST: 'BIN_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'BIN_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'BIN_UPDATE_FAILURE',

    GETALL_REQUEST: 'BIN_GETALL_REQUEST',
    GETALL_SUCCESS: 'BIN_GETALL_SUCCESS',
    GETALL_FAILURE: 'BIN_GETALL_FAILURE',

    DELETE_REQUEST: 'BIN_DELETE_REQUEST',
    DELETE_SUCCESS: 'BIN_DELETE_SUCCESS',
    DELETE_FAILURE: 'BIN_DELETE_FAILURE',

    GETWHDDL_REQUEST: 'WH_DDL_REQUEST',
    GETWHDDL_SUCCESS: 'WH_DDL_SUCCESS',
    GETWHDDL_FAILURE: 'GETWHDDL_FAILURE',
    
    ISACTIVE_REQUEST: 'BIN_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'BIN_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'BIN_ISACTIVE_FAILURE',

    GETEXPORT_REQUEST: 'GETEXPORT_REQUEST',
    GETEXPORT_SUCCESS: 'GETEXPORT_SUCCESS',
    GETEXPORT_FAILURE: 'GETEXPORT_FAILURE',

    GETALLBIN_REQUEST: 'BIN_GETALLBIN_REQUEST',
    GETALLBIN_SUCCESS: 'BIN_GETALLBIN_SUCCESS',
    GETALLBIN_FAILURE: 'BIN_GETALLBIN_FAILURE',
    
    DIALOG_REQUEST:'DIALOG_REQUEST',

    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST'
};