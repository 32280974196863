import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { WarrantyRegistrationReportActions } from "../../actions/index.js";
import { alignProperty } from "@mui/material/styles/cssUtils.js";

class WarrantyRequestClaim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scanTxt: "",
            qr: "",
        };
        this.ScanItem = this.ScanItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    
    ScanItem(e) {
        if (e.key === "Enter") {
            const qr = e.target.value.includes("=") ? e.target.value.split("=")[1] : e.target.value;
            this.props.GetScanInfo(qr);
            this.setState({ qr: qr });
            this.setState({ scanTxt: "" });
        }
    }
    
    actionBodyTemplate = (rowData) => { 
        const claimWarranty = () => {
            // Call the claimWarranty action and pass the WarrantyId
            this.props.claimWarranty(rowData.WarrantyId, true);
          setTimeout(() => {
            this.props.GetScanInfo(this.state.qr);
          }, 100);   // Use state to get qr value
            window.location.reload();
        };
    
        const isClaimed = rowData.isClaim; // Assuming there's a field like isClaimed in your rowData
    
        if (isClaimed == "false") {
            return (
                <button type="button" className="btn btn-success" onClick={claimWarranty}>
                    Claim
                </button>
            );
        } else {
            return (
                <strong style={{color:"Blue"}}>Claimed</strong> // Or any other UI to show it's already claimed
            );
        }
    };
    

    actionBodyTemplate2 = (rowData) => {
        
        const claimWarranty = () => {
            // Call the claimWarranty action and pass the WarrantyId
            this.props.claimWarranty(rowData.WarrantyId, true);
          setTimeout(() => {
            this.props.GetScanInfo(this.state.qr);
          }, 100);
          window.location.reload();
        };

       
            return (
                <button type="button" className="btn btn-success" onClick={claimWarranty}>
                    Claim
                </button>
            );
        
    };

    render() {
        const { TIDproducts } = this.props; // Assuming TIDproducts is passed as props
        const item = [TIDproducts];


        return (
            <>
                {/* Other components */}
                <div className="datatable-filter-demo">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card card-outline-secondary">
                                <div className="card-header">
                                    <h3 className="mb-0">Warranty Claim</h3>
                                </div>
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <input
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            name="scanTxt"
                                            value={this.state.scanTxt}
                                            type="search"
                                            autoComplete="off"
                                            className="form-control"
                                            id="scan"
                                            onKeyDown={(e) => {
                                                this.ScanItem(e);
                                            }}
                                            autoFocus
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Conditionally render DataTable */}
                {Object.keys(TIDproducts).length > 0 && (
                    <div>
                        <div className="card">
                            <DataTable value={item} emptyMessage="No data found."
                                scrollable
                                responsive={true}>
                                <Column field="SeqNo" header="Seq No" sortable style={{width:"5%"}}></Column>
                                <Column body={(rowData) => rowData.P.map((product, index) => <div key={index}>{product.ProductName}</div>)} 
                                 header="Product Name" style={{width:"10%"}} sortable></Column>
                              <Column field="CustomerName" header="Customer Name" sortable style={{width:"9%"}} ></Column>
                                <Column field="Email" header="Email" sortable style={{width:"18%"}}></Column>
                                <Column field="MobNo" header="Mobile" sortable style={{width:"9%"}}></Column>
                                <Column field="InvoiceDate" header="Purchased Date" sortable style={{width:"9%"}}></Column>
                                <Column field="RegisterDate" header="Warranty Registered Date" sortable style={{width:"18%"}}></Column>
                                <Column field="purchasedFrom" header="Dealer Name" sortable style={{width:"11%"}}></Column>

                                {item[0].isApprove == "1" ? (
                                    <Column
                                    field="Action"
                                    onClick={(event) => { event.stopPropagation() }}
                                    header="Action"
                                    body={(rowData) => rowData.isClaim ? this.actionBodyTemplate(rowData) : null}
                                    style={{ width: '14%' }}
                                ></Column>
                                ) : (
                                    <Column
                                        field="Status"
                                        header="Status"
                                        body={(rowData) => <strong style={{color:"Red"}}>Not Approve</strong>}
                                    ></Column>
                                )}

                            </DataTable>
                        </div>
                        <br>
                        </br>
                        {item[0].isClaim =='true' ? (
                            <div className="card2">
                                <DataTable value={item} emptyMessage="No data found." scrollable responsive={true} >
                                    <Column field="claimdate" header="Last Claim Date" sortable ></Column>
                                    {/* <Column
                                        field="Status"
                                        header="Status"
                                        body={(rowData) => <span>Claimed</span>}
                                    ></Column>  */}
                                    <Column
                                        field="Action"
                                        onClick={(event) => { event.stopPropagation() }}
                                        header="Action"
                                        body={this.actionBodyTemplate2}
                                        style={{ width: '14%' }}
                                    ></Column>
                                </DataTable>
                            </div>
                        ) : null}
                    </div>
                )}
            </>
        );
    }
}


const mapStateToProps = (state) => {
    
    return { TIDproducts: state.QRCodeTrackingRpt.TIDproducts || [], };
};

const mapDispatchToProps = (dispatch) => ({
    GetScanInfo: (data) => dispatch(WarrantyRegistrationReportActions.GetScanInfo(data)),
    claimWarranty: (WarrantyId, isActive) =>
        dispatch(WarrantyRegistrationReportActions.claimWarranty(WarrantyId, isActive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyRequestClaim);
