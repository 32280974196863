export const categoryConstants = {
  CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
  CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
  CREATE_FAILURE: 'CATEGORY_CREATE_FAILURE',

  UPDATE_REQUEST: 'CATEGORY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CATEGORY_UPDATE_FAILURE',

  GETALL_REQUEST: 'CATEGORY_GETALL_REQUEST',
  GETALL_SUCCESS: 'CATEGORY_GETALL_SUCCESS',
  GETALL_FAILURE: 'CATEGORY_GETALL_FAILURE',

  GETSUBALL_REQUEST: 'CATEGORY_GETSUBALL_REQUEST',
  GETSUBALL_SUCCESS: 'CATEGORY_GETSUBALL_SUCCESS',
  GETSUBALL_FAILURE: 'CATEGORY_GETSUBALL_FAILURE',

  ISACTIVE_REQUEST: 'CATEGORY_ISACTIVE_REQUEST',
  ISACTIVE_SUCCESS: 'CATEGORY_ISACTIVE_SUCCESS',
  ISACTIVE_FAILURE: 'CATEGORY_ISACTIVE_FAILURE',

  ISACTIVE2_REQUEST: 'SUBCATEGORY_ISACTIVE2_REQUEST',
  ISACTIVE2_SUCCESS: 'SUBCATEGORY_ISACTIVE2_SUCCESS',
  ISACTIVE2_FAILURE: 'SUBCATEGORY_ISACTIVE2_FAILURE',

  DIALOG_REQUEST: 'DIALOG_REQUEST',
  LOADING_REQUEST: 'LOADING_REQUEST',
  DIALOG_ISACTIVE_REQUEST: 'DIALOG_ISACTIVE_REQUEST',

  DIALOG_REQUEST2: 'DIALOG_REQUEST2',
  LOADING_REQUEST2: 'LOADING_REQUEST2',
  DIALOG_ISACTIVE_REQUEST2: 'DIALOG_ISACTIVE_REQUEST2',


  GETCATEGORYDDL_REQUEST: 'GETCATEGORYDDL_REQUEST',
  GETCATEGORYDDL_SUCCESS: 'GETCATEGORYDDL_SUCCESS',
  GETCATEGOYRDDL_FAILURE: 'GETCATEGOYRDDL_FAILURE',

  GETSUBCATEGORYDDL_REQUEST: 'PRODUCTS_GETSUBCATEGORYDDL_REQUEST',
  GETSUBCATEGORYDDL_SUCCESS: 'PRODUCTS_GETSUBCATEGORYDDL_SUCCESS',
  GETSUBCATEGORYDDL_FAILURE: 'PRODUCTS_GETSUBCATEGORYDDL_FAILURE',

};