export const productConstants = {
    CREATE_REQUEST: 'PRODUCTS_CREATE_REQUEST',
    CREATE_SUCCESS: 'PRODUCTS_CREATE_SUCCESS',
    CREATE_FAILURE: 'PRODUCTS_CREATE_FAILURE',

    UPDATE_REQUEST: 'PRODUCTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PRODUCTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PRODUCTS_UPDATE_FAILURE',

    GETALL_REQUEST: 'PRODUCTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PRODUCTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PRODUCTS_GETALL_FAILURE',

    UPDATE_ID_REQUEST: 'ID_UPDATE_REQUEST',
    UPDATE_ID_SUCCESS: 'ID_UPDATE_SUCCESS',
    UPDATE_ID_FAILURE: 'ID_UPDATE_FAILURE',

    GET_ID_REQUEST: 'GET_ID_REQUEST',
    GET_ID_SUCCESS: 'GET_ID_SUCCESS',
    GET_ID_FAILURE: 'GET_ID_FAILURE',

    DELETE_REQUEST: 'PRODUCTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'PRODUCTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'PRODUCTS_DELETE_FAILURE' ,

    ISACTIVE_REQUEST: 'PRODUCTS_ISACTIVE_REQUEST',
    ISACTIVE_SUCCESS: 'PRODUCTS_ISACTIVE_SUCCESS',
    ISACTIVE_FAILURE: 'PRODUCTS_ISACTIVE_FAILURE',
    
    GETPRDCTDDL_REQUEST: 'PRODUCTS_GETPRDCTDDL_REQUEST',
    GETPRDCTDDL_SUCCESS: 'PRODUCTS_GETPRDCTDDL_SUCCESS',
    GETPRDCTDDL_FAILURE: 'PRODUCTS_GETPRDCTDDL_FAILURE',


    ADD_MRP_REQUEST: 'ADD_MRP_REQUEST',
    ADD_MRP_SUCCESS: 'ADD_MRP_SUCCESS',
    ADD_MRP_FAILURE: 'ADD_MRP_FAILURE',

    GET_MRP_REQUEST: 'GET_MRP_REQUEST',
    GET_MRP_SUCCESS: 'GET_MRP_SUCCESS',
    GET_MRP_FAILURE: 'GET_MRP_FAILURE',

    GET_ProductWarranty_REQUEST: 'GET_ProductWarranty_REQUEST',
    GET_ProductWarranty_SUCCESS: 'GET_ProductWarranty_SUCCESS',
    GET_ProductWarranty_FAILURE: 'GET_ProductWarranty_FAILURE',



    DIALOG_REQUEST:'DIALOG_REQUEST',

    LOADING_REQUEST:'LOADING_REQUEST',

    DIALOG_ISACTIVE_REQUEST:'DIALOG_ISACTIVE_REQUEST',
};