import { authHeader, handleResponse } from "../helpers";
export const dealerService = {
  createDealer,
  fetchStateDLL,
  fetchCityDLL,
  fetchFirmDLL,
  updateDealer,
  fetchDealerData,
  inActiveRow,
  fetchTransportationModeDDL,
  fetchDealerDataforExport,
};

function createDealer(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/CreateDealer`,
    requestOptions
  ).then(handleResponse);
}

function updateDealer(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/UpdateDealer`,
    requestOptions
  ).then(handleResponse);
}

function fetchStateDLL(countryId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetStateDLL/${countryId}`,
    requestOptions
  ).then(handleResponse);
}

function fetchCityDLL(userId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetCityDLL/${userId}`,
    requestOptions
  ).then(handleResponse);
}

function fetchFirmDLL(userId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetFirmDLL/${userId}`,
    requestOptions
  ).then(handleResponse);
}

function fetchTransportationModeDDL() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetTransportationModeDLL`,
    requestOptions
  ).then(handleResponse);
}

function fetchDealerData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetDealers`,
    requestOptions
  ).then(handleResponse);
}

function fetchDealerDataforExport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/GetDealersForExport`,
    requestOptions
  ).then(handleResponse);
}

function inActiveRow(UserId, isActive) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}UserDMaster/InActiveRow/${UserId}/${isActive}`,
    requestOptions
  ).then(handleResponse);
}
