import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { ToastContainer } from 'react-toastify';
import { GiftGenerateActions } from '../../actions/GiftGenerateActions';
import { GiftMasterActions } from '../../actions/GiftMasterAction';
import tableRequest from '../../models/tableRequest';

class GiftGenerate extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();

        this.state = {
            selectedQuarter: '',
            FindselectedQuarter: '',
            searchTerm: '',
            loading: false,
            formError: {
                QuarterName: '',
            },
            totalRecords: 0,
            rows: 10,
            first: 0,
            globalFilter: '',
        };
    }

    componentDidMount() {
        const userPages = sessionStorage.getItem('assignedPermissionsForUser');
        if (userPages) {
            const assignedPermissions = JSON.parse(userPages);
            const pageName = window.location.pathname.split('/').pop();

            const obj = assignedPermissions.assigned.filter(x => x.ActionName === pageName);
            if (obj && obj.length > 0) {
                this.setState({
                    IsEdit: obj[0].IsEdit,
                    IsAdd: obj[0].IsAdd,
                });
            }
        }

        this.props.fetchApi(this.tblResponse);
        this.props.GetQuarterDDl();
    }

    componentDidUpdate(prevProps) {
        if (this.props.SchememappingData !== prevProps.SchememappingData) {
            const e = this.props.SchememappingData;
            this.setState({
                totalRecords: e && e[0] ? e[0].TotalRows : 0,
            });
        }
    }

    submitForm = (e) => {
        e.preventDefault();

        const user = JSON.parse(sessionStorage.getItem("user"));
        const userId = user ? user.UserId : null;

        // Validate quarter selection
        if (!this.state.selectedQuarter) {
            this.setState({
                formError: { ...this.state.formError, QuarterName: 'Quarter is required.' }
            });
            return;
        }

        // Show loader during the submission
        this.setState({ loading: true });

        const formData = {
            QuarterName: this.state.selectedQuarter,
            CreatedBy: userId
        };

        // Simulating form submission with a timeout
        setTimeout(() => {
            // Replace this with actual submission logic
            this.props.createRedemption(formData);
            this.setState({ loading: false });

        }, 4000); // Simulate a 3-second delay for API call
        this.resetForm();
        setTimeout(() => {
            this.props.fetchApi(this.tblResponse);
        }, 6000);


    };

    resetForm = () => {
        this.setState({
            selectedQuarter: '',
            FindselectedQuarter: '',
            formError: {
                QuarterName: '',
                FindselectedQuarter: '',
            }
        });
    }

    handleQuarterChange = (e) => {
        const selectedQuarter = e.target.value;
        this.setState({ FindselectedQuarter: selectedQuarter }, () => {
            // Make the API call with the selected quarter value
            this.getFilteredData(selectedQuarter);
        });
    };


    getFilteredData = (selectedQuarter) => {
        debugger
        // Call your API using the selected quarter value
        console.log('Fetching data for quarter:', selectedQuarter);
        this.props.loadingData(true);
        //this.tblResponse.SearchValue = e.target.value;
        this.tblResponse.SearchValue = selectedQuarter;
        this.props.fetchApi(this.tblResponse);

    };


    getLazyLoadingValues = (event) => {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter || "";
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    onPage = (event) => {
        this.props.loadingData(true);
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    searchFilter = (e) => {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        // this.tblResponse.Quarter = this.state.FindselectedQuarter;
        this.props.fetchApi(this.tblResponse);
    }

    onSort = (event) => {
        this.props.loadingData(true);
        const sortField = event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField;
        this.tblResponse.SortOrder = event.multiSortMeta[0].order === 1 ? 'ASC' : 'DESC';
        this.props.fetchApi(this.tblResponse);
    }

    onHide = () => {
        this.props.onHideShow(false);
        this.resetForm();
    }

    openDialog = () => {
        this.props.onHideShow(true);
    }

    render() {
        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4 className="user">Generated Gifts</h4>
                </div>
                <div className="col-md-6">
                    <table className="tableheadering" >
                        <tr>
                            <td className="tdtable">
                                <span>
                                    <FormControl >
                                        <InputLabel>Quarter</InputLabel>
                                        <Select value={this.state.FindselectedQuarter}
                                            onChange={this.handleQuarterChange} >
                                            <MenuItem value=''> All Quarter</MenuItem>
                                            {this.props.quarterDDL && this.props.quarterDDL.map((quarter) => (
                                                <MenuItem key={quarter.value} value={quarter.value}>
                                                    {quarter.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {this.state.formError.FindselectedQuarter && <p style={{ color: "red", fontSize: "12px" }}>{this.state.formError.FindselectedQuarter}</p>}
                                </span>
                            </td>
                            {/* <td>
                                <span className="p-input-icon-left mr-2">
                                    <i className="pi pi-search" />
                                    <InputText type="search" onInput={this.searchFilter} placeholder="Global Search" />
                                </span>
                            </td> */}
                        </tr>
                    </table>
                </div>
            </div>
        );

        return (
            <div className="GiftGenerate">
                <div className="datatable-filter-demo">
                    <ToastContainer />
                    <div className="card">
                        <div className="row p-3">
                            <div className="col-md-3" style={{ alignContent: "center" }}>
                                <h5>Gift Generator</h5>
                            </div>
                            <div className="col-md-6">
                                <FormControl fullWidth>
                                    <InputLabel>Quarter</InputLabel>
                                    <Select value={this.state.selectedQuarter}
                                        onChange={(e) => this.setState({ selectedQuarter: e.target.value })}>
                                        {this.props.quarterDDL && this.props.quarterDDL.map((quarter) => (
                                            <MenuItem key={quarter.value} value={quarter.value}>
                                                {quarter.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {this.state.formError.QuarterName && <p style={{ color: "red", fontSize: "12px" }}>{this.state.formError.QuarterName}</p>}
                            </div>
                            <div className="col-md-3">
                                <Button
                                    type="submit"
                                    label=" Gift Generate  "
                                    icon={this.state.loading ? '' : 'pi pi-check'}
                                    disabled={this.state.loading} // Disable button while loading
                                    onClick={this.submitForm}
                                >
                                    {this.state.loading ? (
                                        <ProgressSpinner style={{ width: '25px', height: '25px' }} strokeWidth="8" />
                                    ) : (
                                        ''
                                    )}
                                </Button>
                            </div>
                        </div>
                        <DataTable value={this.props.TableData}
                            scrollable
                            globalFilter={this.state.globalFilter} header={header}
                            emptyMessage="No data found."
                            paginator rows={this.state.rows} rowsPerPageOptions={[10, 20, 30]}
                            totalRecords={this.state.totalRecords}
                            lazy first={this.state.first} onPage={this.onPage} onSort={this.onSort}
                            loading={this.props.loading}>
                            <Column field="UserName" header="User Name" style={{ width: "13%" }} />
                            <Column field="ProductName" header="Product Name" style={{ width: "13%" }} />
                            <Column field="SeqNo" header="Sequence Number" style={{ width: "15%" }} />
                            <Column field="QuarterName" header="Quarter Name" style={{ width: "15%" }} />
                            <Column field="GiftName" header="Gift Name" style={{ width: "15%" }} />
                            <Column field="CreateDt" header="Create Date" style={{ width: "15%" }} />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    TableData: state.GiftGenerate.TableData,
    quarterDDL: state.GiftMaster.quarterDDL,
    showDialogue: state.GiftGenerate.showDialogue,
    loading: state.GiftGenerate.loading,
});

const mapDispatchToProps = (dispatch) => ({
    fetchApi: (obj) => dispatch(GiftGenerateActions.getAll(obj)),
    GetQuarterDDl: () => dispatch(GiftMasterActions.QuarterDDL()),
    createRedemption: (data) => dispatch(GiftGenerateActions.createRedemption(data)),
    onHideShow: (isbool) => dispatch(GiftGenerateActions.onHideShow(isbool)),
    loadingData: (isLoading) => dispatch(GiftGenerateActions.loadingData(isLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftGenerate);
