import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import TextField from '@material-ui/core/TextField';
import { FileUpload } from 'primereact/fileupload';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Calendar } from 'primereact/calendar';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import { Toast } from 'primereact/toast';
import { RedemptionApprovalActions } from '../../actions/RedemptionApprovalActions';
import { productMasterActions } from '../../actions';
import { roleMasterActions } from '../../actions';
import tableRequest from '../../models/tableRequest';
import { Dropdown } from 'primereact/dropdown';
// import { RoleMasterActions } from '../../actions';
import Switch from '@material-ui/core/Switch';

class RedemptionApproval extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        //this.Databody = this.Databody.bind(this);
        //this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onHidee = this.onHidee.bind(this);
        this.onHideclose = this.onHideclose.bind(this);
        this.openDialogue = this.openDialogue.bind(this);
        this.openRejectDialogue = this.openRejectDialogue.bind(this);
        this.RedemptionRequest = this.RedemptionRequest.bind(this);
        this.RedemptionReject = this.RedemptionReject.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.getProductList = this.getProductList.bind(this);
        this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
        this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this)
        this.resetForm = this.resetForm.bind(this);
        this.onHide = this.onHide.bind(this);
        //this.getRoleDDL = this.getRoleDDL.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);


        const RoleData = [
            { value: 1, label: 'Web' },
            { value: 2, label: 'Mobile' }
        ]
        const RedeemTypeData = [
            { value: 1, label: 'Paytm' },
            { value: 2, label: 'Loyality Points' }
        ]

        this.state = {
            selectedProduct: '',
            productId: '',
            IsAdd: true,
            IsEdit: true,
            first: 0,
            rows: 5,
            totalRecords: 0,
            first1: 0,
            rows1: 5,
            totalRecords1: 0,
            globalFilter: null,
            sortOrder: '',
            buttonLabel: 'Submit',
            Approve: false,
            formHeading: 'Create Redemption Request',
            UserId: '',
            RedeemTypeId: 0,
            RoleId: 0,
            RoleId: '',
            CancellationReason: '',
            Mappseriesdata: [],
            Userdropdown: [],
            selectedProduct: null,
            RoleData: [
                { value: 1, label: 'Web' },
                { value: 2, label: 'Mobile' }
            ],
            RedeemTypeData: [
                { value: 1, label: 'Paytm' },
                { value: 2, label: 'Loyality Points' }
            ],

            Name: '',
            Email: '',
            RedeemType: '',
            RoleId: '',
            product: '',
            RedeemPoint: '',
            UserPoints: '',
            IsActive: true,
            CreatedDate: null,
            UpdatedDate: null,
            formError: {
                UserId: '',
                RedeemType: '',
                RedeemPoint: '',
            }
        };
    }
    componentDidMount() {
        const userPages = sessionStorage.getItem('assignedPermissionsForUser')
        if (userPages) {
            const assignedPermissions = JSON.parse(userPages);
            const Curenturl = window.location.pathname;
            const nNumber = Curenturl.lastIndexOf('/');
            const pageName = Curenturl.substring(nNumber + 1);

            const obj = assignedPermissions.assigned.filter((x) => {
                return x.ActionName === pageName
            });

            if (obj !== null && obj.length > 0) {
                if (!obj[0].IsEdit) {
                    this.setState({
                        IsEdit: false
                    });
                }
                if (!obj[0].IsAdd) {
                    this.setState({
                        IsAdd: false
                    });
                } else {
                    this.setState({
                        IsAdd: true
                    });
                }
            }
        }

        this.setState({
            Mappseriesdata: this.props.Mappseries
        })


        this.props.fetchApi(this.tblResponse);
        this.props.UserData(this.tblResponse);
        //this.props.GetUserPoints(this.tblResponse);


        this.props.getProductDDL();
    }

    componentDidUpdate(prevProps) {
        if (this.props.SchememappingData) {
            if (prevProps.SchememappingData !== this.props.SchememappingData) {
                // this.props.myProp has a different value
                if (this.props.SchememappingData) {
                    var e = this.props.SchememappingData;
                    this.setState({
                        totalRecords: e[0].TotalRows
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0
                });
        }

        if (this.props.Mappseriesdata) {
            if (prevProps.Mappseriesdata !== this.props.Mappseriesdata) {
                // this.props.myProp has a different value
                if (this.props.Mappseriesdata) {
                    var e = this.props.Mappseriesdata;
                    this.setState({
                        totalRecords1: e[0].this.props.TotalRows1
                    });
                }
            }
        } else {
            if (this.state.totalRecords1 !== 0)
                this.setState({
                    totalRecords1: 0,
                    first1: 0
                });
        }
    }

    // switchToggleConfirm = (e) => {
    //     this.props.inActiveRow(this.state.UserId, this.state.switchAction);
    // }

    getUserList(event) {
        this.setState({ UserId: event.target.value });
        this.props.UserData(event.target.value);
        const userpnt = {
            UserId: event.target.value
        }
        this.props.GetUserPoints(userpnt)
        setTimeout(() => {
            const e = this.props.Userpoints;
            if (e != null) {
                this.setState({
                    UserPoints: e[0].UserPoints
                });
            } else {
                this.setState({
                    UserPoints: 0.0
                });
            }
        }, 2000);
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        switch (name) {
            case 'IsActive':
                value = event.target.checked;
                break;
            default:
                break;
        }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }

    UpdatedDateTemplate(rowData) {
        if (rowData.UpdatedDate !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>
            )
        }
        else {

        }
    }

    CreatedDateTemplate(rowData) {
        if (rowData.UpdatedDate !== null) {

            return (
                <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>
            )
        }
    }
    getProductList(event) {
        this.props.getProductDDL();
    }

    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        switch (name) {
            case 'UserId ':
                if (value.length < 1) {
                    IsValid = false;
                    errors.UserId = "Please Select User.";
                } else errors.UserId = ''
                break;
            case 'RedeemType':
                if (value.length < 1) {
                    IsValid = false;
                    errors.RedeemType = "Please enter Redeem Type.";
                } else errors.RedeemType = ''
                break;

            case 'RedeemPoint':
                value = value === '' ? 0 : value;
                if (value !== 0) {
                    if (parseInt(value) < 1) {
                        IsValid = false;
                        errors.RedeemPoint = "Reedem points must be greater than 0";
                    }
                    else if (!value.toString().match(/^[0-9]+$/.exec(value))) {
                        IsValid = false;
                        errors.RedeemPoint = "Please enter only number.";
                    }
                    else {
                        errors.RedeemPoint = null;
                    }
                }
                else {
                    IsValid = false;
                    errors.RedeemPoint = "Please Enter Redeem Points";
                }
                break;

            default:
                break;
        }

        return IsValid;
    }

    getDropValueOfUser() {
        let User = '';
        this.props.Userdataa.filter(x => {
            if (x.value === this.props.Userdataa) {
                User = x.label;
            }
        });

        return User;
    }

    getDropValueOfRedeemType() {

        let RedeemTypeId = '';
        this.state.RedeemTypeData.filter(x => {
            if (x.value === this.state.RedeemType) {
                RedeemTypeId = x.label;
            }
        });

        return RedeemTypeId;
    }


    submitForm(event) {
        event.preventDefault();

        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });

        if (isfrmvalid) {
            const ddlValuUser = this.getDropValueOfUser();
            // const ddlValuRole = this.getDropValueOfRole();
            // const ddlValuRedeemType = this.getDropValueOfRedeemType();
            const formData = {

                UserId: this.state.UserId,
                Userdropdown: ddlValuUser != null ? ddlValuUser : "",
                RedeemReqType: this.state.RedeemType, //= 1 ? this.state.RedeemType = 'Paytm' : this.state.RedeemType = 'Loyality Points',
                UserPoints: this.state.UserPoints,
                RedeemPoint: this.state.RedeemPoint,
                UpdatedBy: JSON.parse(sessionStorage.getItem("UserData")),
                TotalRows: this.state.totalRecords
            }
            this.props.createRedemption(formData);
            event.preventDefault();
            setTimeout(() => {
                this.props.fetchApi(this.tblResponse);
            }, 800);

            if (!this.props.showDialog) {
                this.resetForm();
            }
        }

    }

    resetForm() {
        this.setState({
            UserId: '',
            UserData: '',
            RedeemType: '',
            UserPoints: '',
            RedeemPoint: '',

            formError: {
                UserId: '',
                RedeemType: '',
                RedeemPoint: '',
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }


    onPage(event) {
        this.props.loadingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    onHide() {
        this.props.onHideShow(false);
        this.resetForm();
    }
    openDialog() {
        this.props.onHideShow(true);
        this.setState({ buttonLabel: 'Submit', formHeading: 'Add Scheme' })
    }
    onHidee() {
        this.props.onHideShoww(false);
    }

    openDialogue() {
        this.props.onHideShoww(true);
    }
    onHideclose() {
        this.props.onHideclose(false);
    }

    openRejectDialogue() {
        this.props.onHideclose(true);
        //this.setState({ buttonLabel: 'Submit', formHeadingg: 'Confirmation' })

    }

    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }

    RedemptionReject() {
        //var e = this.props.SchememappingData

        const formData = {
            RedeemReqId: this.state.RedeemReqId,
            UpdatedBy: JSON.parse(sessionStorage.getItem("UserData")),
            CancellationReason: this.state.CancellationReason
        }
        this.props.CancelRedeem(formData);
        setTimeout(() => {
            this.props.fetchApi(this.tblResponse);
        }, 800);
    }




    RedemptionRequest() {
        //var e = this.props.SchememappingData

        const formData = {
            RedeemReqId: this.state.RedeemReqId,
            UpdatedBy: JSON.parse(sessionStorage.getItem("UserData")),
        }
        this.props.ApproveRedeem(formData);
        setTimeout(() => {
            this.props.fetchApi(this.tblResponse);
        }, 800);
    }


    actionBodyTemplate(rowData) {
        const editMode = (e) => {
            if (e == 'Approve') {
                this.openDialogue();
            }
            else {
                this.openRejectDialogue();
            }

            this.setState({
                RedeemReqId: rowData.RedeemReqId,
                UpdatedBy: rowData.UpdatedBy
            });
        }

        if (rowData) {
            return (
                <>
                    {this.state.IsEdit === true ?
                        <div >
                            <Button className="btn m-1 p-1 success" label="Approve" icon="pi pi-check" onClick={() => editMode('Approve')} />
                            <Button className="btn m-1 pr-3 danger" label="Reject" icon="pi pi-times" onClick={() => editMode('Reject')} />
                            {/* <Button style={{ display: 'none'}} onClick={editMode} /> */}
                        </div>
                        : ""
                    }
                </>
            )
        }
    }

    render() {

        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                <h4 className="user">Redemption Approval</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.searchFilter(e)} placeholder="Global Search" />
                    </span>

                    {this.state.IsAdd === true ?
                        <Button className="mr-2" label="Add Redemption" icon="pi pi-plus-circle" onClick={this.openDialog} />
                        : <Button className="mr-2" label="Add Redemption" icon="pi pi-plus-circle" style={{ display: 'none' }} onClick={this.openDialog} />
                    }

                </div>
            </div>
        );

        return (
            <div className="datatable-filter-demo">
                <ToastContainer />

                <div className="card">
                    <DataTable value={this.props.SchememappingData}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter} header={header}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 20]}
                        totalRecords={parseInt(this.state.totalRecords)}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} loading={this.props.loading} ref={(el) => { this.dt = el; }}>
                        <Column field="UserName" style={{ width: "13%" }} header="Name" sortable></Column>
                        <Column field="MobileNo" style={{ width: "15%" }} header="Mobile No." sortable></Column>
                        <Column field="Email" style={{ width: "15%" }} header="Email" sortable></Column>
                        <Column field="RoleName" style={{ width: "15%" }} header="Role" sortable></Column>
                        <Column field="RedeemPoint" style={{ width: "15%" }} header="Redeem Points" sortable></Column>
                        <Column field="RedeemTypeName" style={{ width: "15%" }} header="Redeem Type" sortable></Column>
                        {/* <Column field="Action" header="Action" >
                                <Button label={this.state.buttonLabel} onClick={this.submitForm} icon="pi pi-check" />
                        </Column> */}

                        {this.state.IsEdit === true ?
                            <Button field="Action" header="Action" style={{ width: "12%" }}  body={this.actionBodyTemplate} />
                            : ""
                        }
                    </DataTable>
                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={true} visible={this.props.showDialog} className="popup" onHide={this.onHide}>
                    {/* <form onSubmit={this.submitForm} autocomplete="off"> */}
                    <div className="row">

                        <div className="col-md-6 pt-3">
                            <FormControl variant="outlined" size="small" className="demo-simple-select-outlined-label">
                                <Dropdown value={this.state.UserId} filter={true}
                                    filterPlaceholder="Search User"
                                    emptyFilterMessage="No Result Found"
                                    options={this.props.Userdataa}
                                    onChange={this.getUserList}
                                    placeholder="Select User" />
                            </FormControl>
                            {this.state.formError.UserId !== '' ?
                                <div className='error'>{this.state.formError.UserId}</div> : ''}
                        </div>

                        <div className="col-md-6 pt-3">
                            <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                {/* <InputLabel id="demo-simple-select-outlined-label">Select Redeem Type*</InputLabel> */}
                                <Select onChange={this.handleChange} name="RedeemType" value={this.state.RedeemType} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Redeem Type">
                                    <MenuItem>
                                        <em>None</em>
                                    </MenuItem>
                                    {this.state.RedeemTypeData.map(key => (
                                        <MenuItem value={key.value}>{key.label}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            {this.state.formError.RedeemType !== '' ?
                                <div className='error'>{this.state.formError.RedeemType}</div> : ''}
                        </div>


                        <div className="col-md-6 pt-3">
                            <TextField disabled id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                name="UserPoints" value={this.state.UserPoints} className="fullwidth" label="User Points*" />
                            {/* {this.state.formError.Userpnts !==
                                '' ?
                                <div className='error'>{this.state.formError.Userpnts}</div> : ''} */}
                        </div>
                        <div className="col-md-6 pt-3">
                            <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                name="RedeemPoint" value={this.state.RedeemPoint} className="fullwidth" label="Redeem Points*" />
                            {this.state.formError.RedeemPoint !== '' ?
                                <div className='error'>{this.state.formError.RedeemPoint}</div> : ''}
                        </div>

                        <div className="col-md-6 pt-3">
                            <Checkbox
                                name="IsActive"
                                checked={this.state.IsActive}
                                onClick={this.handleChange}
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            /> <label>Is Active</label>
                        </div>


                        <div className="col-md-12 mt-4 text-center">
                            <div className="mb-2">Please Click Below Button To Submit*</div>

                            <Button label={this.state.buttonLabel} onClick={this.submitForm} icon="pi pi-check" />
                        </div>

                        <div className="col-md-12">
                            Note: Fields marked as (*) are required
                        </div>
                    </div>
                    {/* </form> */}
                </Dialog>

                <Dialog header="Confirmation" modal dismissableMask={true} visible={this.props.showDialogue} className="popup"
                    style={{ width: '350px' }} onHide={this.onHidee}>
                    <div className="row">

                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                            <span>Are you sure you want to Approve?</span>
                        </div>

                        <div className="col-md-12 mt-4 text-center">

                            <Button label="Cancel" icon="pi pi-times" onClick={this.onHidee} className="p-button-text" />
                            <Button id="Approve" label="Approve" icon="pi pi-check" onClick={this.RedemptionRequest} autoFocus />

                        </div>
                    </div>
                </Dialog>

                <Dialog header="Confirmation" modal dismissableMask={true} visible={this.props.canceldialog} className="popup"
                    style={{ width: '350px' }} onHide={this.onHideclose}>
                    <div className="row">

                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3 ml-3 confirm-icon" style={{ fontSize: '2rem' }} />
                            <span>Are you sure you want to Reject?</span>
                        </div>

                        <div className="col-md-12 pt-3">
                            <TextField
                                id="filled-textarea"
                                className="fullwidth"
                                rows={2}
                                label="Cancel Reason*"
                                name="CancellationReason" value={this.state.CancellationReason}
                                placeholder="Cancel Reason*"
                                onChange={this.handleChange}
                                multiline
                                variant="outlined"
                            />
                        </div>
                        <div className="col-md-12 mt-4 text-center">

                            <Button label="Cancel" icon="pi pi-times" onClick={this.onHideclose} className="p-button-text" />
                            <Button label="Reject" icon="pi pi-check" onClick={this.RedemptionReject} autoFocus />

                        </div>
                    </div>
                </Dialog>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    ////console.log('state.RedemptionApproval.items', state.RedemptionApproval.items);
    ////console.log('state.RedemptionApproval.Userdataa', state.RedemptionApproval.Userdataa);
    ////console.log('state.RedemptionApproval.Userpoints', state.RedemptionApproval.Userpoints);
    return {
        SchememappingData: state.RedemptionApproval.items,
        Userpoints: state.RedemptionApproval.Userpoints,
        showDialogue: state.RedemptionApproval.showDialogue,
        productData: state.product.prdctddl,
        showDialog: state.role.showDialog,
        loading: state.company.loding,
        showIsActiveDialog: state.RedemptionApproval.showIsActiveDialog,
        ApprovedData: state.RedemptionApproval.itemss,
        CanceledData: state.RedemptionApproval.itemss,
        Userdataa: state.RedemptionApproval.Userdataa,
        //approvedialog: state.RedemptionApproval.approvedialog,
        canceldialog: state.RedemptionApproval.canceldialog,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(RedemptionApprovalActions.getAll(obj))
        },
        getProductDDL: () => {
            dispatch(productMasterActions.getPrdctDDL())
        },
        GetUserPoints: (data) => {
            dispatch(RedemptionApprovalActions.GetUserPoints(data))
        },
        ApproveRedeem: data => dispatch(RedemptionApprovalActions.ApproveRedeem(data)),
        CancelRedeem: data => dispatch(RedemptionApprovalActions.CancelRedeem(data)),
        UserData: data => dispatch(RedemptionApprovalActions.UserData(data)),
        createRedemption: data => dispatch(RedemptionApprovalActions.createRedemption(data)),
        onHideShow: isbool => dispatch(RedemptionApprovalActions.onHideShow(isbool)),
        onHideShoww: isbool => dispatch(RedemptionApprovalActions.onHideShoww(isbool)),
        onHideclose: isbool => dispatch(RedemptionApprovalActions.onHideclose(isbool)),
        onHideShowIsActive: isbool => dispatch(RedemptionApprovalActions.onHideShowIsActive(isbool)),

        loadingData: (isLoding) => dispatch(RedemptionApprovalActions.loadingData(isLoding))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RedemptionApproval)