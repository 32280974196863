import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { GiftMasterActions } from "../../actions/GiftMasterAction";
import MenuItem from "@material-ui/core/MenuItem";
import tableRequest from "../../models/tableRequest";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';

class GiftMaster extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);

    this.state = {
      selectedQuarter: "",
      ProductDDL:"",
      GiftName: "",
      Qty:0,
      CreateBy: "",
      IsAdd: true,
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,  
      switchAction: false,
      formHeading: "Add Loyalty Master",
      CreatedBy:0,    
      formError: {
        Qty: "",
      },
    };
  }

  validateForm = () => {
    const { GiftName, Qty, ProductDDL, selectedQuarter } = this.state;
    const errors = {};
    let isValid = true;
  
    if (!GiftName) {
      errors.GiftName = "Gift Name is required.";
      isValid = false;
    }
    if (Qty <= 0) {
      errors.Qty = "Quantity must be greater than 0.";
      isValid = false;
    }
    if (!selectedQuarter) {
      errors.selectedQuarter = "Please select a quarter.";
      isValid = false;
    }
    if (ProductDDL.length === 0) {
      errors.ProductDDL = "Please select at least one product.";
      isValid = false;
    }
  
    this.setState({ formError: errors });
    return isValid;
  };
  
  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => x.ActionName === pageName);

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) this.setState({ IsEdit: false });
        if (!obj[0].IsAdd) this.setState({ IsAdd: false });
      }
    }

    var date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 7);
    this.tblResponse.FromDate = date.toLocaleDateString();
    this.tblResponse.ToDate = new Date().toLocaleDateString();
    this.props.fetchApi(this.tblResponse);
    this.props.GetQuarterDDl(); // Fetch products and other data for dropdowns
    this.props.GetProductDDl();

  }

  handleChange = (e) => {
    //this.setState({ [e.target.name]: e.target.value });
    this.setState({ ProductDDL: e.value });
}


submitForm = (e) => {
  e.preventDefault();
  if (!this.validateForm()) {
    toast.error("Please fix validation errors.");
    return;
  }
  const user = JSON.parse(sessionStorage.getItem("user"));
  const userId = user ? user.UserId : null; 

  // Convert the selected products array to a comma-separated string
  const selectedProductsString = this.state.ProductDDL.join(',');

  const formData = {
      QuarterName: this.state.selectedQuarter,
      GiftName: this.state.GiftName,
      Qty: this.state.Qty,
      ProductIds: selectedProductsString,  // Add the selected products as a string
      CreatedBy: userId
  };

  // Dispatch the action with formData
  this.props.createScheme(formData);
console.log(formData);
  // Reset the form after submission
  this.resetForm();
  setTimeout(() => {
    this.props.fetchApi(this.tblResponse);
}, 2000);
}

  resetForm() {
    this.setState({     
      selectedQuarter: "",     
      GiftName: "",
      Qty: "",
      CreateBy: "",
      IsActive: true,
      formError: {      
        Qty: "",    
      },
    });
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.props.fetchApi(this.tblResponse);
  }

  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }

  openDialog() {
    this.resetForm();
    this.props.onHideShow(true);
    this.setState({
      buttonLabel: "Submit",
      formHeading: "Add Scheme",
      isEditing: false,
    });
  } 

  onRowSelect(event) {
    this.setState({
      FromNo: event.data.FromNo,
      ToNo: event.data.ToNo,
      Qty: event.data.ToNo - event.data.FromNo + 1,
    });
    this.onHidee();
  }

  statusBodyTemplate(rowData) {
    if (rowData) {
      // Status template code
    }
  }
  

  actionBodyTemplate(rowData) {
    const editMode = () => {
      this.props.onHideShow(true);
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Scheme",      
        RoleId: rowData.RoleId,       
        Qty: rowData.Qty,
        CreateBy: rowData.CreateBy,
        selectedQuarter: rowData.QuarterName,
        GiftName: rowData.GiftName,
        IsActive: rowData.IsActive, 
        isEditing: true,
      });
    };
    
    // Edit
    if (rowData) {
      return (
        <>
          <Button icon="pi pi-pencil" onClick={editMode} />
        </>
      );
    }
  }

  
  render() {
    const header = (
      <div className="table-header row">
        <div className="col-md-6">
          <h4 className="user">Gift Master</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>

          {this.state.IsAdd && (
            <Button
              className="mr-2"
              label="Add Scheme"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          )}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />
        <div className="card mb-5">
          <DataTable
            className="mb-2"
            value={this.props.SchememappingData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}           
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
          >  
            <Column style={{width:"33%"}} field="QuarterName" header="Quarter Name" />
            <Column style={{width:"33%"}} field="GiftName" header="Gift Name" />
            <Column style={{width:"33%"}} field="Qty" header="Quantity" />
            </DataTable>
        </div>
        <Dialog
  visible={this.props.showDialog}
  header={this.state.formHeading}
  modal
  onHide={this.onHide}
  className="add_schememodal"
  style={{ width: '627px', padding: '20px' }}  // Added padding
>
  <form onSubmit={this.submitForm} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>  
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {/* Quarter Field */}
      <FormControl fullWidth error={Boolean(this.state.formError.selectedQuarter)}>
        <InputLabel>Quarter</InputLabel>
        <Select
          value={this.state.selectedQuarter}
          onChange={(e) => this.setState({
            selectedQuarter: e.target.value,
            formError: { ...this.state.formError, selectedQuarter: "" } // Clear the error
          })}
        >
          {this.props.quarterDDl &&
            this.props.quarterDDl.map((quarter) => (
              <MenuItem key={quarter.value} value={quarter.value}>
                {quarter.label}
              </MenuItem>
            ))}
        </Select>
        {this.state.formError.selectedQuarter && (
          <p style={{ color: "red", fontSize: "12px" }}>{this.state.formError.selectedQuarter}</p>
        )}
      </FormControl>

      {/* Product Field */}
      <FormControl fullWidth error={Boolean(this.state.formError.ProductDDL)}>
        <MultiSelect
          appendTo={document.getElementById('root')}
          value={this.state.ProductDDL}
          onChange={(e) => this.setState({
            ProductDDL: e.value,
            formError: { ...this.state.formError, ProductDDL: "" } // Clear the error
          })}
          options={this.props.ProductDDL}
          optionValue="value"
          optionLabel="label"
          className="form-control"
          placeholder="Select Products"
          name="product"
          filter
        />
        {this.state.formError.ProductDDL && (
          <p style={{ color: "red", fontSize: "12px" }}>{this.state.formError.ProductDDL}</p>
        )}
      </FormControl>
    </div>

    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {/* Gift Name Field */}
      <TextField
        fullWidth
        label="Gift Name"
        value={this.state.GiftName}
        onChange={(e) => this.setState({
          GiftName: e.target.value,
          formError: { ...this.state.formError, GiftName: "" } // Clear the error
        })}
        error={Boolean(this.state.formError.GiftName)}
        helperText={this.state.formError.GiftName}
      />

      {/* Quantity Field */}
      <TextField
        fullWidth
        type="number"
        label="Qty"
        value={this.state.Qty}
        onChange={(e) => this.setState({
          Qty: e.target.value,
          formError: { ...this.state.formError, Qty: "" } // Clear the error
        })}
        error={Boolean(this.state.formError.Qty)}
        helperText={this.state.formError.Qty}
      />
    </div>

    <Button type="submit" label="Save Gift" style={{ alignSelf: 'flex-end' }} />
  </form>
</Dialog>


      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.GiftMaster.ProductDDList)
  return {
    SchememappingData: state.GiftMaster.items,
    quarterData: state.GiftMaster.quarterData, // Added for quarter dropdown
    quarterDDl: state.GiftMaster.quarterDDL, 
    ProductDDL: state.GiftMaster.ProductDDList,
    showDialog: state.GiftMaster.showDialog,
    loading: state.company.loding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(GiftMasterActions.getAll(obj));
    },
    GetQuarterDDl: () => {
      dispatch(GiftMasterActions.QuarterDDL());
    },
    GetProductDDl: () => {
      dispatch(GiftMasterActions.ProductDDL());
    },
    createScheme: (user) => dispatch(GiftMasterActions.createScheme(user)),
    updateScheme: (user) => dispatch(GiftMasterActions.updateScheme(user)),
    onHideShow: (isbool) => dispatch(GiftMasterActions.onHideShow(isbool)),   
    loadingData: (isLoding) => dispatch(GiftMasterActions.loadingData(isLoding)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftMaster);
