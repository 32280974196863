import { SecondaryPackingRptService } from '../services';
import { SecondaryPackingRptConstants } from '../constants';
import { alertActions } from '.';

export const SecondaryPackingRptActions = {
    getAll,
    loadingData,
    exportData,
    GetVendorListDDL
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());

        SecondaryPackingRptService.fetchSecondaryPackingData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))


            var data = response.ResponseData;   //Changes
            if (data != null) {
                data.forEach(function (v) { delete v.TotalRows });
            }
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: SecondaryPackingRptConstants.GETALL_REQUEST } }
    function success(datas) { return { type: SecondaryPackingRptConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: SecondaryPackingRptConstants.GETALL_FAILURE, error } }
}

function GetVendorListDDL(UserId) {
    return dispatch => {
        dispatch(request());
        SecondaryPackingRptService.GetVendorListDDL(UserId).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: SecondaryPackingRptConstants.GETVENDORDDL_REQUEST } }
    function success(datas) { return { type: SecondaryPackingRptConstants.GETVENDORDDL_SUCCESS, datas } }
    function failure(error) { return { type: SecondaryPackingRptConstants.GETVENDORDDL_FAILURE, error } }
}

function exportData(obj) {
    return dispatch => {
        dispatch(request());
        SecondaryPackingRptService.GetSecondaryPackingReportExport(obj).then(response => {
            dispatch(loadingData(false));
            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) { delete v.TotalRows });
            }
            dispatch(success(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };
    function request() { return { type: SecondaryPackingRptConstants.GETEXPORT_REQUEST } }
    function success(datas) { return { type: SecondaryPackingRptConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: SecondaryPackingRptConstants.GETEXPORT_FAILURE, error } }
}

function loadingData(isbool) {
    return {
        type: SecondaryPackingRptConstants.LOADING_REQUEST, isbool
    }
}

