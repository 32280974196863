import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MuiAlert from "@material-ui/lab/Alert";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";
import { categoryActions, productMasterActions } from "../../actions";
import Switch from "@material-ui/core/Switch";
import { TabView, TabPanel } from "primereact/tabview";
import Moment from "react-moment";
import tableRequest from "../../models/tableRequest";
import { DateTime } from "luxon";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import JoditEditor from 'jodit-react';
import { category } from "../../reducers/categoryReducer";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class productMaster extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.photoBodyTemplate = this.photoBodyTemplate.bind(this);
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.setGenuineAudioPath = this.setGenuineAudioPath.bind(this);
    this.setAlreadyAudioPath = this.setAlreadyAudioPath.bind(this);
    this.setVideoPath = this.setVideoPath.bind(this);
    this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
    this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.state = {
      IsAdd: true,
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
      confirm: false,
      // productSpecItem: [{ key: "", value: "" }],
      activeIndex: 1,
      buttonLabel: "Submit",
      switchAction: false,
      displayConfirmation: false,
      formHeading: "Add Product",
      displayModal: false,
      // productCode: '',
      productName: "",
      productDescription: "",
      AboutThisItem: "",
      PackageContent: "",
      permissibleScan: "",
      genuineMessage: "",
      shareMessage: "",
      shareurl: "",
      alreadyScannedMsg: "",
      //warranty: '',
      promotionalMessage: "",
      promotionalStartDate: "",
      promotionalEndDate: "",
      audioFile: "",
      videoFile: "",
      productImage: "",
      genuineAudio: "",
      alreadyScannedAudio: "",
      isActive: true,
      productSpecification: [{ key: "", value: "" }],
      productConfiguration: null,
      createdDate: null,
      updatedDate: null,
      isUploadLocal: true,
      youTubeURL: "",
      categoryId: 0,
      SubCategoryId: 0,
      PackingSize: 0,
      mrp: 0,
      SubCategory: "",
      //oem: "",
      ProductWarranty: 0,
      formError: {
        // productCode: '',
        productName: "",
        // productDescription: '',
        // promotionalMessage:'',
        // permissibleScan: '',
        genuineMessage: "",
        // shareMessage:'',
        alreadyScannedMsg: "",
        // shareurl:'',
        productImage: "",
        //genuineAudio: "",
        alreadyScannedAudio: "",
        categoryId: "",
        SubCategoryId: "",
        mrp: 0,
        SubCategory: "",

      },
    };
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);
      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);
    this.props.getCategoryDDL();
  }

  componentDidUpdate(prevProps) {
    if (this.props.ProductData) {
      if (prevProps.ProductData !== this.props.ProductData) {
        // this.props.myProp has a different value
        if (this.props.ProductData) {
          var e = this.props.ProductData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    this.props.inActiveRow(this.state.productId, this.state.switchAction);
  };

  // handle input change
  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.productSpecification];
    list[index][name] = value;
    this.setState({ productSpecification: list });
  };
  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const list = [...this.state.productSpecification];
    list.splice(index, 1);
    this.setState({ productSpecification: list });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    this.setState({
      productSpecification: [
        ...this.state.productSpecification,
        { key: "", value: "" },
      ],
    });
  };


  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  getDateYYYYMMDD(date) {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  }

  UpdatedDtTemplate(rowData) {
    if (rowData.UpdatedDt !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    }
  }

  CreatedDtTemplate(rowData) {
    return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
  }
  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    switch (name) {
      case "isActive":
        value = event.target.checked;
        break;
      case "categoryId":
        this.props.getSubCategoryDDL(value)
        break;
      default:
        break;
    }
    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      // case 'productCode':
      //     if (!value || value.length < 1) {
      //         IsValid = false;
      //         errors.productCode = "Please enter your product code.";
      //     } else errors.productCode = ''
      //     break;
      case "productName":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.productName = "Please enter your product name.";
        } else errors.productName = "";
        break;
      // case 'productDescription':
      //     if (!value || value.length < 1) {
      //         IsValid = false;
      //         errors.productDescription = "Please enter product description.";
      //     } else errors.productDescription = ''
      //     break;
      // case 'permissibleScan':
      //     if (!value || value.length < 1) {
      //         IsValid = false;
      //         errors.permissibleScan = "Please enter permissible scan";
      //     }
      //     else errors.permissibleScan = ''
      //     break;
      case "categoryId":
        if (!value || value < 1) {
          IsValid = false;
          errors.categoryId = "Please select product category.";
        } else errors.categoryId = "";
        break;

      case "SubCategoryId":
        if (!value || value < 1) {
          IsValid = false;
          errors.SubCategoryId = "Please select product sub-category.";
        } else errors.SubCategoryId = "";
        break;
      // case 'oem':
      //     if (!value || value < 1) {
      //         IsValid = false;
      //         errors.oem = "Please enter oem part number.";
      //     } else errors.oem = ''
      //     break;
      // case "hpCode":
      //   if (!value || value < 1) {
      //     IsValid = false;
      //     errors.hpCode = "Please enter HP Code.";
      //   } else errors.hpCode = "";
      //   break;
      // case 'mrp':
      //     if (!value || value < 1) {
      //         IsValid = false;
      //         errors.mrp = "Please enter mrp.";
      //     } else if (!value.toString().match(/^[0-9]+$/.exec(value))) {
      //         IsValid = false;
      //         errors.mrp = "Please enter only number.";
      //         this.setState({ mrp: 0 })
      //     }
      //     else errors.mrp = ''
      //     break;
      // case 'genuineMessage':
      //     if (!value || value < 1) {
      //         IsValid = false;
      //         errors.genuineMessage = "Please enter genuine message.";
      //     } else errors.genuineMessage = ''
      //     break;
      // case 'shareMessage':
      //     if (!value || value < 1) {
      //         IsValid = false;
      //         errors.shareMessage = "Please enter genuine message.";
      //     } else errors.shareMessage = ''
      //     break;
      // case 'shareurl':
      //     if (!value || value < 1) {
      //         IsValid = false;
      //         errors.shareurl = "Please enter Share URL.";
      //     } else errors.shareurl = ''
      //     break;
      // case 'alreadyScannedMsg':
      //     if (!value || value.toString().length < 1) {
      //         IsValid = false;
      //         errors.alreadyScannedMsg = "Please enter already scanned message.";
      //     } else errors.alreadyScannedMsg = ''
      //     break;
      case 'PackingSize':
           if (value.length < 1) {
               IsValid = false;
               errors.PackingSize = "Please enter PackingSize";
           }
           else
           if (value.length >= 1) {
               if (!value.match(/^([1-9]|[123][0-9])$/)) {
                   IsValid = false;
                   errors.PackingSize = "Please Enter +ve Packing Size.";
               } else errors.PackingSize = ''
           } else errors.PackingSize = ''
           break;
      case "productImage":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.productImage = "Please upload product image.";
        } else errors.productImage = "";
        break;
      case "genuineAudio":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.genuineAudio = "Please upload genuine audio.";
        } else errors.genuineAudio = "";
        break;
      // case 'alreadyScannedAudio':
      //     if (!value || value.length < 1) {
      //         IsValid = false;
      //         errors.alreadyScannedAudio = "Please upload already scanne audio.";
      //     } else errors.alreadyScannedAudio = ''
      //     break;

      default:
        break;
    }

    return IsValid;
  }
  resetForm() {
    this.setState({
      productId: 0,
      categoryId: 0,
      SubCategoryId: "",
      mrp: 0,
      //oem: "",
      productName: "",
      productDescription: "",
      AboutThisItem: "",
      PackageContent: "",
      permissibleScan: "",
      genuineMessage: "",
      alreadyScannedMsg: "",
      shareMessage: "",
      shareurl: "",
      productImage: "",
      genuineAudio: "",
      alreadyScannedAudio: "",
      promotionalMessage: "",
      promotionalStartDate: "",
      promotionalEndDate: "",
      audioFile: "",
      isActive: true,
      productSpecification: [],
      isUploadLocal: true,
      youTubeURL: "",
      PackingSize: 0,
      SubCategory: "",
      ProductWarranty: 0,
      formError: {
        productName: "",
        // productDescription: "",
        genuineMessage: "",
        alreadyScannedMsg: "",
        productImage: "",
        categoryId: "",
        SubCategoryId: "",
        mrp: 0,

        //  oem: "",
        ProductWarranty: "",
        SubCategory: "",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }
  onHide() {
    this.resetForm();
    this.props.onHideShow(false);
  }

  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add Product" });
    this.resetForm();
  }

  submitForm(event) {
    ;
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      const ddlValuCategory = this.getDropValueOfCategory();
      const ddlValueSubCategory = this.getDropValueOfSubCategory();
      const formData = {
        ProductId: this.state.productId,
        ProductCode: this.state.productCode,
        CategoryId: this.state.categoryId,

        MRP: this.state.mrp == "" ? 0 : this.state.mrp,
        SubCategoryId: this.state.SubCategoryId == null ? 0 : this.state.SubCategoryId,
        ProductName: this.state.productName,
        ProductDescription: this.state.productDescription,
        AboutThisItem: this.state.AboutThisItem,
        PackageContent: this.state.PackageContent,
        PermissibleScan:
          this.state.permissibleScan == "" ? 0 : this.state.permissibleScan,
        GenuineMessage: this.state.genuineMessage,
        AlreadyScannedMsg: this.state.alreadyScannedMsg,
        ShareMessage: this.state.shareMessage,
        ShareURL: this.state.shareurl,
        ProductImage: this.state.productImage,
        GenuineAudio: this.state.genuineAudio,
        AlreadyScannedAudio: this.state.alreadyScannedAudio,
        ProductSpecification: JSON.stringify(this.state.productSpecification),
        ProductConfiguration: this.state.productConfiguration,
        IsActive: this.state.isActive,
        PackingSize: this.state.PackingSize,
        CreatedDate:
          this.state.ProductId > 0
            ? this.state.CreatedDate
            : this.getCurrentDateMMDDYYY(),
        UpdatedDate:
          this.state.ProductId > 0 ? this.getCurrentDateMMDDYYY() : null,
        PromotionalMessage: this.state.promotionalMessage,
        PromotionalStartDate: this.state.promotionalStartDate,
        PromotionalEndDate: this.state.promotionalEndDate,
        // CategoryName: ddlValuCategory != null ? ddlValuCategory : "",
        TotalRows: this.state.totalRecords,
        ProductWarranty: this.state.ProductWarranty,
      };
      console.log(formData)
      if (this.state.productId == 0) {
        this.props.createProduct(formData);
      } else {
        this.props.updateProduct(formData, this.tblResponse);
      }
    }
  }

  getDropValueOfCategory() {
    let categoryName = "";
    this.props.CategoryData.filter((x) => {
      if (x.value === this.state.categoryId.toString()) {
        categoryName = x.label;
      }
    });

    return categoryName;
  }

  getDropValueOfSubCategory() {
    let subcategoryName = "";
    this.props.subCategoryData.filter((x) => {
      if (x.value === this.state.SubCategoryId.toString()) {
        subcategoryName = x.label;
      }
    });

    return subcategoryName;
  }




  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }

  onPage(event) {
    this.props.loadingData(true);

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  /*resetForm() {
        this.setState({
            productId: 0,
            productDescription: '',
            genuineMessage: '',
            alreadyScannedMsg: '',
            productImage: '',
            isActive: true
        })
    }*/
  photoBodyTemplate(e) {
    if (e.ProductImage) {
      return <img src={e.ProductImage} width="50px" />;
    }
  }
  statusBodyTemplate(rowData) {
    return (
      <Checkbox
        defaultChecked
        disabled
        checked={rowData.isActive}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    );
  }
  // set Product Image Path
  setImagePath(event) {
    let errors = this.state.formError;
    errors.productImage = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              productImage: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.productImage = "Only image format is accepted.";
            this.setState({
              productImage: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  //set Video Path
  setVideoPath(event) {
    let errors = this.state.formError;
    errors.videoFile = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              videoFile: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.videoFile = "Only video format is accepted.";
            this.setState({
              videoFile: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  // set Genuine Audio Path
  setGenuineAudioPath(event) {
    this.setState({
      genuineAudio: null,
    });
    let errors = this.state.formError;
    errors.genuineAudio = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              genuineAudio: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.genuineAudio = "Only audio format is accepted.";
            this.setState({
              genuineAudio: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  // set Already Audio Path
  setAlreadyAudioPath(event) {
    this.setState({
      alreadyScannedAudio: null,
    });
    let errors = this.state.formError;
    errors.alreadyScannedAudio = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              alreadyScannedAudio: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.alreadyScannedAudio = "Only audio format is accepted.";
            this.setState({
              alreadyScannedAudio: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  getProductDescription(rowData) {

    return (
      <span>
        <div className="summary">
          <p className="collapse" id={rowData.ProductId}>
            {rowData.ProductDescription}
          </p>
          <a className="collapsed" data-toggle="collapse" href={'#' + rowData.ProductId} aria-expanded="false" aria-controls="collapseSummary" />
        </div>
      </span>);
  };

  actionBodyTemplate(rowData) {

    const editMode = (e) => {
      this.props.getCategoryDDL();
      this.props.onHideShow(true);
      this.props.getSubCategoryDDL(rowData.CategoryId);
      setTimeout(() => {
        this.setState({
          buttonLabel: "Update",
          formHeading: "Update Product",
          productId: rowData.ProductId,
          // productCode: rowData.ProductCode,
          productName: rowData.ProductName,
          categoryId: rowData.CategoryId.toString(),
          SubCategoryId: rowData.SubCategoryId == null ? 0 : rowData.SubCategoryId.toString(),
          mrp: rowData.MRP,
          productDescription: rowData.ProductDescription,
          AboutThisItem: rowData.AboutThisItem,
          PackageContent: rowData.PackageContent,
          permissibleScan: rowData.PermissibleScan,
          genuineMessage: rowData.GenuineMessage,
          alreadyScannedMsg: rowData.AlreadyScannedMsg,
          //warranty: rowData.Warranty,
          shareMessage: rowData.ShareMessage,
          shareurl: rowData.ShareURL,
          productImage: rowData.ProductImage,
          genuineAudio: rowData.GenuineAudio,
          alreadyScannedAudio: rowData.AlreadyScannedAudio,
          productSpecification:
            rowData.ProductSpecification != null
              ? JSON.parse(rowData.ProductSpecification)
              : [{ key: "", value: "" }],
          productConfiguration:
            rowData.ProductConfiguration != null
              ? rowData.ProductConfiguration
              : null,
          promotionalMessage: rowData.PromotionalMessage,
          promotionalStartDate: this.getDateYYYYMMDD(
            rowData.PromotionalStartDate
          ),
          promotionalEndDate: this.getDateYYYYMMDD(rowData.PromotionalEndDate),
          videoFile: rowData.IsUploadLocal == true ? rowData.VideoFile : null,
          youTubeURL: rowData.IsUploadLocal == false ? rowData.VideoFile : null,
          PackingSize: rowData.PackingSize,
          isUploadLocal:
            rowData.IsUploadLocal == (null || undefined)
              ? false
              : rowData.IsUploadLocal,
          isActive: rowData.IsActive,
          createdDate: rowData.CreatedDate,
          ProductWarranty: rowData.ProductWarranty,
        });
      }, 1000)

    };

    const switchHandleChange = (e) => {
      this.setState({
        switchAction: !rowData.IsActive,
        productId: rowData.ProductId,
      });
      this.props.onHideShowIsActive(true);
    };





    return (
      <>
        <Switch
          checked={rowData.IsActive}
          color="primary"
          name={rowData.ProductId}
          onChange={switchHandleChange}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />

        <Button icon="pi pi-pencil" onClick={editMode} />
      </>
    );
  }

  render() {
    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };
    const header = (
      <div className="table-header row">
        <div className="col-md-6">
          <h4 className="user">Product Master</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
          {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              icon="pi pi-plus"
              label="Add"
              onClick={() => this.openDialog()}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card">
          <DataTable stripedRows
            value={this.props.ProductData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >{/* <Column field="ProductCode" header="Product Code" sortable></Column> */}
            <Column field="ProductName" style={{ width: "15%" }} header="Product Name" sortable></Column>
            <Column style={{ width: "25%" }}
              // field="ProductDescription"
              // header="Product Description"
              // sortable


              body={this.getProductDescription}
              header="Product Description"
              sortable

            ></Column>
            <Column style={{ width: "15%" }}
              field="CategoryName"
              header="Category Name"
              sortable
            ></Column>


            {/* <Column field="CreatedDt" body={this.CreatedDtTemplate} header="Created Date" sortable></Column>
                        <Column field="UpdatedDt" body={this.UpdatedDtTemplate} header="Updated Date" sortable></Column> */}
            <Column field="SubCategoryName" style={{ width: "20%" }} header="Sub-Category Name" sortable></Column>

            <Column id="zoomA" style={{ width: "13%" }}
              field="ProductImage"
              header="Product Image"

              body={this.photoBodyTemplate}
            ></Column>
            {/* <Column field="Status" style={{ width: '70px' }} header="Status" body={this.statusBodyTemplate}></Column> */}
            {this.state.IsEdit === true ? (
              <Column style={{ width: "12%" }}
                field="Action"
                header="Action"

                body={this.actionBodyTemplate}
              ></Column>
            ) : (
              <Column
                field="Action"
                header="Action"
                style={{ display: "none" }}
              ></Column>
            )}
          </DataTable>
        </div>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={false}
          visible={this.props.showDialog}
          className="popup1"
          onHide={this.onHide}
        >
          <TabView>
            <TabPanel header="Product">
              <form onSubmit={this.submitForm}>
                <div className="row">
                  <div className="col-md-6 pt-2">
                    {/* <TextField id="standard-basic" size="small" variant="outlined" name="productCode" value={this.state.productCode} onChange={this.handleChange} className="fullwidth" label="Product Code*" />
                                        {this.state.formError.productCode !==
                                            '' ?
                                            <div className='error'>{this.state.formError.productCode}</div> : ''} */}
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="productName"
                      value={this.state.productName}
                      onChange={this.handleChange}
                      className="fullwidth"
                      label="Product Name*"
                    />
                    {this.state.formError.productName !== "" ? (
                      <div className="error">
                        {this.state.formError.productName}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-2">
                    <FormControl
                      variant="outlined"
                      size="small"
                      className="formControl col-md-12"
                    >
                      <Dropdown
                        appendTo={document.getElementById("root")}
                        value={this.state.categoryId}
                        //value={this.props.ProductData}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        options={this.props.CategoryData}
                        optionValue="value"
                        optionLabel="label"
                        className="form-control"
                        placeholder={"Select Category "}
                        name="categoryId"
                        filter
                      />
                    </FormControl>
                    {this.state.formError.categoryId !== 0 ? (
                      <div className="error">
                        {this.state.formError.categoryId}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-6 pt-2">
                    <FormControl
                      variant="outlined"
                      size="small"
                      className="formControl col-md-12"
                    >
                      <Dropdown
                        appendTo={document.getElementById("root")}
                        value={this.state.SubCategoryId}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        options={this.props.subCategoryData}
                        optionValue="value"
                        optionLabel="label"
                        className="form-control"
                        placeholder={"Select Sub-Category "}
                        name="SubCategoryId"
                        filter
                      />
                    </FormControl>
                    {this.state.formError.SubCategoryId !== 0 ? (
                      <div className="error">
                        {this.state.formError.SubCategoryId}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 pt-3">
                    <TextField
                      id="filled-textarea"
                      className="fullwidth"
                      rows={4}
                      label="Product Description"
                      name="productDescription"
                      value={this.state.productDescription}
                      placeholder="Product Description*"
                      onChange={this.handleChange}
                      multiline
                      variant="outlined"
                    />
                    {this.state.formError.productDescription !== "" ? (
                      <div className="error">
                        {this.state.formError.productDescription}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-6 pt-3">
                    <label for="content"> About This Item </label>
                    <JoditEditor
                      value={this.state.AboutThisItem}
                      name="AboutThisItem"
                      onChange={(value) => this.setState({ AboutThisItem: value })} // preferred to use only this option to update the content for performance reasons
                    //  onChange={newContent => { }}
                    />
                  </div>
                  <div className="col-md-6 pt-3">
                    <label for="content"> Package Content </label>
                    <JoditEditor
                      value={this.state.PackageContent}
                      name="AboutThisItem"
                      onChange={(value) => this.setState({ PackageContent: value })} // preferred to use only this option to update the content for performance reasons
                    //  onChange={newContent => { }}
                    />
                  </div>
                  {/* <div className="col-md-4 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      value={this.state.hpCode}
                      name="hpCode"
                      variant="outlined"
                      className="fullwidth"
                      label="HP Code"
                    />
                    {this.state.formError.hpCode !== "" ? (
                      <div className="error">{this.state.formError.hpCode}</div>
                    ) : (
                      ""
                    )}
                  </div> */}

                  {/* <div className="col-md-4 pt-3">
                    <TextField
                      onChange={this.handleChange}
                      value={this.state.oem}
                      name="oem"
                      variant="outlined"
                      className="fullwidth"
                      label="Oem Part No"
                    />
                     {this.state.formError.oem !== '' ?
                                            <div className='error'>{this.state.formError.oem}</div> : ''} 
                  </div> */}
                  <div className="col-md-4 pt-3">
                    <TextField
                      onChange={this.handleChange}

                      value={this.state.mrp}
                      name="mrp"
                      size="small"
                      variant="outlined"
                      className="fullwidth"
                      label="MRP"
                    />
                    {/* {this.state.formError.mrp !== '' ?
                                            <div className='error'>{this.state.formError.mrp}</div> : ''} */}
                  </div>
                  <div className="col-md-4 pt-3">
                    <TextField
                      onChange={this.handleChange}
                      value={this.state.ProductWarranty}
                      name="ProductWarranty"
                      size="small"
                      type="number"
                      variant="outlined"
                      className="fullwidth"
                      label="Product Warranty(In Months)"
                    />
                    {/* {this.state.formError.mrp !== '' ?
                                            <div className='error'>{this.state.formError.mrp}</div> : ''} */}
                  </div>
                  <div className="col-md-4 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      value={this.state.permissibleScan}
                      name="permissibleScan"
                      variant="outlined"
                      className="fullwidth"
                      label="Permissible Scan"
                    />
                    {this.state.formError.permissibleScan !== "" ? (
                      <div className="error">
                        {this.state.formError.permissibleScan}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-4 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      value={this.state.genuineMessage}
                      name="genuineMessage"
                      variant="outlined"
                      className="fullwidth"
                      label="Genuine Message *"
                    />
                    {this.state.formError.genuineMessage !== "" ? (
                      <div className="error">
                        {this.state.formError.genuineMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-4 pt-3">
                    <TextField
                      id="revision"
                      size="small"
                      onChange={this.handleChange}
                      value={this.state.alreadyScannedMsg}
                      name="alreadyScannedMsg"
                      variant="outlined"
                      className="fullwidth"
                      label="Already Scanned Msg *"
                    />
                    {this.state.formError.alreadyScannedMsg !== "" ? (
                      <div className="error">
                        {this.state.formError.alreadyScannedMsg}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="col-md-4 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} value={this.state.warranty}
                                            name="warranty" variant="outlined" className="fullwidth" label="Warranty (In Months)*" />
                                        {this.state.formError.warranty !== '' ?
                                            <div className='error'>{this.state.formError.warranty}</div> : ''}
                                    </div> */}

                  <div className="col-md-4 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      value={this.state.shareurl}
                      name="shareurl"
                      variant="outlined"
                      className="fullwidth"
                      label="Share Url"
                    />
                    {this.state.formError.shareurl !== "" ? (
                      <div className="error">
                        {this.state.formError.shareurl}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-4 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      value={this.state.shareMessage}
                      name="shareMessage"
                      variant="outlined"
                      className="fullwidth"
                      label="Share Msg"
                    />
                    {this.state.formError.shareMessage !== "" ? (
                      <div className="error">
                        {this.state.formError.shareMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-4 pt-3">
                    <TextField
                      id="standard-basic"
                      size="small"
                      onChange={this.handleChange}
                      value={this.state.PackingSize}
                      name="PackingSize"
                      variant="outlined"
                      className="fullwidth"
                      label="Packing Size(No. of item in Carton)"
                    />
                    {this.state.formError.PackingSize !== "" ? (
                      <div className="error">
                        {this.state.formError.PackingSize}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row col-md-12">
                    <div className="col-md-4 pt-3">
                      <div className="mb-3">Upload Product Image*</div>

                      <div className="mb-4">
                        <FileUpload
                          name="productImage"
                          mode="basic"
                          url={
                            process.env.REACT_APP_API_URL +
                            "UploadFile/ImageUpload"
                          }
                          auto={true}
                          onUpload={this.setImagePath}
                          onError={this.onErrorImage}
                          accept="image/*"
                        />

                        {this.state.productImage ? (
                          <div
                            className="p-fileupload-content mt-3 p-1"
                            style={{
                              display: "table",
                              border: "1px solid #ccc",
                            }}
                          >
                            <div>
                              <img
                                alt="pro.jfif"
                                role="presentation"
                                src={this.state.productImage}
                                width="100"
                              />
                            </div>
                          </div>
                        ) : null}

                        {this.state.formError.productImage !== null ? (
                          <div className="error">
                            {this.state.formError.productImage}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 pt-3">
                      <div className="mb-3">Genuine Audio File</div>

                      <div className="mb-4">
                        <FileUpload
                          name="genuineAudio"
                          mode="basic"
                          url={
                            process.env.REACT_APP_API_URL +
                            "UploadFile/AudioUpload"
                          }
                          auto={true}
                          onUpload={this.setGenuineAudioPath}
                          onError={this.onErrorImage}
                          accept="audio/*"
                        />

                        {this.state.genuineAudio ? (
                          <div
                            className="p-fileupload-content mt-3 p-1"
                            style={{
                              display: "table",
                              border: "1px solid #ccc",
                            }}
                          >
                            <div>
                              <audio controls ref="genuineAudio">
                                <source src={this.state.genuineAudio} />
                              </audio>
                            </div>
                          </div>
                        ) : null}

                        {this.state.formError.genuineAudio !== null ? (
                          <div className="error">
                            {this.state.formError.genuineAudio}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 pt-3">
                      <div className="mb-3">Already Scanned Audio File</div>

                      <div className="mb-3">
                        <FileUpload
                          name="alreadyScannedAudio"
                          mode="basic"
                          url={
                            process.env.REACT_APP_API_URL +
                            "UploadFile/AudioUpload"
                          }
                          auto={true}
                          onUpload={this.setAlreadyAudioPath}
                          onError={this.onErrorImage}
                          accept="audio/*"
                        />

                        {this.state.alreadyScannedAudio ? (
                          <div
                            className="p-fileupload-content mt-3 p-1"
                            style={{
                              display: "table",
                              border: "1px solid #ccc",
                            }}
                          >
                            <div>
                              <audio controls ref="alreadyScanneAudio">
                                <source src={this.state.alreadyScannedAudio} />
                              </audio>
                            </div>
                          </div>
                        ) : null}

                        {this.state.formError.alreadyScannedAudio !== null ? (
                          <div className="error">
                            {this.state.formError.alreadyScannedAudio}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 pt-3">
                    <Checkbox
                      checked={this.state.isActive}
                      onClick={this.handleChange}
                      color="primary"
                      name="isActive"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <label>Is Active</label>
                  </div>
                  <div className="col-md-12 text-center">
                    <Button label={this.state.buttonLabel} icon="pi pi-check" />
                  </div>
                  <div className="col-md-12">
                    Note: Fields marked as (*) are required
                  </div>
                </div>
              </form>
            </TabPanel>
            <TabPanel header="Product Specification">
              <div className="col-md-12 text-right">
                <Button
                  onClick={this.handleAddClick}
                  label="Add"
                  icon="pi pi-plus"
                />
              </div>

              {this.state.productSpecification.map((x, i) => {
                return (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Product Specification:</label>
                        <input
                          name="key"
                          className="form-control"
                          placeholder="Enter Product Specification"
                          value={x.key}
                          onChange={(e) => this.handleInputChange(e, i)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Value:</label>
                        <input
                          className="form-control"
                          name="value"
                          placeholder="Enter Value"
                          value={x.value}
                          onChange={(e) => this.handleInputChange(e, i)}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 pt-4">
                      {this.state.productSpecification.length !== 1 && (
                        <Button
                          icon="pi pi-times"
                          onClick={() => this.handleRemoveClick(i)}
                          className="p-button-rounded p-button-danger p-button-text"
                        />
                      )}
                    </div>
                  </div>
                );
              })}

              <div className="col-md-12 text-center">
                {this.state.IsEdit === true ? (
                  <Button
                    onClick={(e) => this.submitForm(e)} d
                    label={this.state.buttonLabel}
                    icon="pi pi-check"
                  />
                ) : (
                  <Button
                    label={this.state.buttonLabel}
                    icon="pi pi-check"
                    style={{ display: "none" }}
                  />
                )}
              </div>
            </TabPanel>
          </TabView>
        </Dialog>

        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ProductData: state.product.items,
    showDialog: state.role.showDialog,
    loading: state.product.loding,
    showIsActiveDialog: state.product.showIsActiveDialog,
    CategoryData: state.category.categoryddl,
    subCategoryData: state.category.subcategoryddl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(productMasterActions.getAll(obj));
    },
    createProduct: (product) =>
      dispatch(productMasterActions.createProduct(product)),
    updateProduct: (product, obj) =>
      dispatch(productMasterActions.updateProduct(product, obj)),
    inActiveRow: (product, isActive) =>
      dispatch(productMasterActions.inActiveRow(product, isActive)),
    onHideShow: (isbool) => dispatch(productMasterActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(productMasterActions.onHideShowIsActive(isbool)),
    loadingData: (isLoding) =>
      dispatch(productMasterActions.loadingData(isLoding)),
    getCategoryDDL: (data) =>
      dispatch(categoryActions.getCategoryDDL(data)),
    getSubCategoryDDL: (data) =>
      dispatch(categoryActions.getSubCategoryDDL(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(productMaster);
