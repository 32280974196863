import { GiftMasterConstants } from "../constants/GiftMasterConstants";

export function GiftMaster(state = {}, action) {
    switch (action.type) {
        case GiftMasterConstants.LOADING_REQUEST:
            return {
                ...state,
                loading: action.isbool
            };
        case GiftMasterConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
        case GiftMasterConstants.DIALOGUE_REQUEST:
            return {
                ...state,
                showDialogue: action.isbool
            };
        case GiftMasterConstants.DIALOG_ISACTIVE_REQUEST:
            return {
                ...state,
                showIsActiveDialog: action.isbool
            };
        case GiftMasterConstants.UPDATE_PAGE_STORE:
            return {
                ...state,
                pages: action.pageList
            };
        case GiftMasterConstants.GETALL_REQUEST:
        case GiftMasterConstants.GETALL_AVAILABLE_REQUEST:
        case GiftMasterConstants.GETROLEDDL_REQUEST:
        case GiftMasterConstants.GETPRDCTDDL_REQUEST:
        case GiftMasterConstants.GET_PAGES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GiftMasterConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas,
                loading: false
            };
        case GiftMasterConstants.GETALL_AVAILABLE_SUCCESS:
            return {
                ...state,
                Mappseries: action.datas,
                loading: false
            };
        case GiftMasterConstants.GETROLEDDL_SUCCESS:
            return {
                ...state,
                rollddl: action.datas,
                loading: false
            };
        case GiftMasterConstants.GETPRDCTDDL_SUCCESS:
            return {
                ...state,
                prdctddl: action.datas,
                loading: false
            };
        case GiftMasterConstants.GET_PAGES_SUCCESS:
            return {
                ...state,
                pages: action.pages,
                loading: false
            };
        case GiftMasterConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case GiftMasterConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: [action.data, ...(state.items || [])],
                creating: false
            };
        case GiftMasterConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data.RoleId ? { ...item, updating: true } : item
                )
            };
        case GiftMasterConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data.RoleId ? action.data : item
                )
            };
        case GiftMasterConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case GiftMasterConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.filter(item => item.RoleId !== action.data)
            };
        case GiftMasterConstants.ISACTIVE_REQUEST:
            return {
                ...state,
                activating: true
            };
        case GiftMasterConstants.ISACTIVE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.RoleId === action.data.RoleId ? { ...item, IsActive: action.data.IsActive } : item
                ),
                activating: false
            };

     
            //\
        
            case GiftMasterConstants.GETQDDL_REQUEST:
                return {
                    ...state,
                    loading: true
                };
            case GiftMasterConstants.GETQDDL_SUCCESS:
                return {
                    ...state,
                    quarterDDL: action.datas
                };
            case GiftMasterConstants.GETQDDL_FAILURE:
                return {
                    ...state,
                    error: action.error
                };
            //

           // ProductDDl

              //\
        
              case GiftMasterConstants.GETPDDL_REQUEST:
                return {
                    ...state,
                    loading: true
                };
            case GiftMasterConstants.GETPDDL_SUCCESS:
                return {
                    ...state,
                    ProductDDList: action.datas
                };
            case GiftMasterConstants.GETPDDL_FAILURE:
                return {
                    ...state,
                    error: action.error
                };
            //


        case GiftMasterConstants.CREATE_FAILURE:
        case GiftMasterConstants.UPDATE_FAILURE:
        case GiftMasterConstants.DELETE_FAILURE:
        case GiftMasterConstants.GETALL_FAILURE:
        case GiftMasterConstants.GETALL_AVAILABLE_FAILURE:
        case GiftMasterConstants.GETROLEDDL_FAILURE:
        case GiftMasterConstants.GETPRDCTDDL_FAILURE:
        case GiftMasterConstants.GET_PAGES_FAILURE:
        case GiftMasterConstants.ISACTIVE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                creating: false,
                updating: false,
                deleting: false,
                activating: false
            };
        default:
            return state;
    }
}
