import React, { Component } from "react";
import { connect } from "react-redux";
import { SliderProMsgActions } from "../actions";
import { Link } from "react-router-dom";
import Slider from "react-slick";

//import "./HomePage.css";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      origin: window.location.origin,
    };
  }

  componentDidMount() {
    this.props.fetchSliderApi();
  }
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      height: "100%",
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <>
        <div className="sticky-top">
          <section className="top_header lslhome1">
            <div className="container">
              <div className="top_header_main">
                <form
                  action="https://xtrapowertools.com/search-result"
                  method="GET"
                  style={{ margin: 0 }}
                >
                  <div className="head_search">
                    <input
                      type="text"
                      id="search"
                      name="search"
                      placeholder="Search here..."
                      required="true"
                    />
                    <div id="search-result" />
                    <button type="submit">
                      <i className="fa-solid fa-magnifying-glass" />
                    </button>
                  </div>
                </form>
                <a className="hide_tab" href="mailto:Sales@xtrapowertools.com">
                  {" "}
                  Sales@xtrapowertools.com
                </a>
                <a className="hide_tab" href="tel:+911244000608>">
                  +91 124 4000608
                </a>{" "}
                <a
                  href={`${this.state.origin}/Login`}
                  target="_blank"
                  className="btn btn_login"
                >
                  {" "}
                  Login Now
                </a>
              </div>
            </div>
          </section>
          <nav className="navbar navbar-expand-lg navbar-light bg-white">
            <div className="container">
              <a className="navbar-brand">
                <Link to="/">
                  <img
                    src="site-img/logo/header-logo.png"
                    alt="XtraPowerTools Logo"
                  />
                </Link>
              </a>
              <p className="m-0 text-light h_desktop">MENU</p>
              <div className="search_menu">
                <form
                  action="https://xtrapowertools.com/search-result"
                  method="GET"
                >
                  <div className="dropdown">
                    <button
                      className="sm_search"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="icofont-search-1" />
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-end top_header p-2"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="head_search mobile_search">
                        <input
                          type="text"
                          name="search"
                          placeholder="Search here..."
                          required="true"
                        />
                        <button type="submit">
                          <i className="fa-solid fa-magnifying-glass" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <span className="navbar-toggler-icon" />
                </button>
              </div>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="index.html">Home</a> */}
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  {/* <li className="nav-item ">
            <a className="nav-link" href="about.html">About</a>
          </li> */}
                  <li className="nav-item ">
                    <Link className="nav-link" to="/about">
                      About us
                    </Link>
                  </li>

                  {/* <li className="nav-item dropdown ">
            <a href="category-list.html" onclick="location.href = 'category-list.html';" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Product
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li className="sub_items"><a className="dropdown-item" href="category/power-tools.html">Power Tools</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/xtra-power-professional.html">Xtra Power Professional</a></li>
                  <li><a className="dropdown-item" href="sub-category/xtra-power-gold.html">Xtra Power Gold</a></li>
                  <li><a className="dropdown-item" href="sub-category/xtra-power-heavy-duty.html">Xtra Power Heavy Duty</a></li>
                  <li><a className="dropdown-item" href="sub-category/hi-max-power-tools.html">HI-MAX Power Tools</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/cleaning-tools.html">Cleaning Tools</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/pressure-washer.html">Pressure Washer</a></li>
                  <li><a className="dropdown-item" href="sub-category/xp-wet-dry-vacuum-cleaner.html">XP Wet &amp; Dry Vacuum Cleaner</a></li>
                  <li><a className="dropdown-item" href="sub-category/xp-heavy-duty-wet-dry-vacuum-cleaner.html">XP Heavy Duty Wet &amp; Dry Vacuum Cleaner</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/welding-machine.html">Welding Machine</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/arc-welding-machine.html">ARC Welding Machine</a></li>
                  <li><a className="dropdown-item" href="sub-category/mig-welding-machine.html">MIG Welding Machine</a></li>
                  <li><a className="dropdown-item" href="sub-category/tig-welding-machine.html">TIG Welding Machine</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/air-compressor.html">Air Compressor</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/air-compressor.html">Air Compressor</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/spare-parts.html">Spare Parts</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/spare-parts.html">Spare Parts</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/abrasives.html">Abrasives</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/wa-wheel.html">WA Wheel</a></li>
                  <li><a className="dropdown-item" href="sub-category/dc-wheel.html">DC Wheel</a></li>
                  <li><a className="dropdown-item" href="sub-category/cut-off-wheel.html">Cut Off Wheel</a></li>
                  <li><a className="dropdown-item" href="sub-category/flap-disc.html">Flap Disc</a></li>
                  <li><a className="dropdown-item" href="sub-category/mounted-flap-wheel.html">Mounted Flap Wheel</a></li>
                  <li><a className="dropdown-item" href="sub-category/fiber-disc.html">Fiber Disc</a></li>
                  <li><a className="dropdown-item" href="sub-category/gc-wheel.html">GC Wheel</a></li>
                  <li><a className="dropdown-item" href="sub-category/polishing-pad.html">Polishing Pad</a></li>
                  <li><a className="dropdown-item" href="sub-category/backing-pad.html">Backing Pad</a></li>
                  <li><a className="dropdown-item" href="sub-category/chain-saw-grinding-wheel.html">Chain Saw Grinding Wheel</a></li>
                  <li><a className="dropdown-item" href="sub-category/diamond-stone.html">Diamond Stone</a></li>
                  <li><a className="dropdown-item" href="sub-category/sand-paper.html">Sand Paper</a></li>
                  <li><a className="dropdown-item" href="sub-category/non-woven-pad.html">Non Woven Pad</a></li>
                  <li><a className="dropdown-item" href="sub-category/non-woven-wheel.html">Non Woven Wheel</a></li>
                  <li><a className="dropdown-item" href="sub-category/flap-wheel.html">Flap Wheel</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/accessories.html">Accessories</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/cup-wheel-brush.html">Cup &amp; Wheel Brush</a></li>
                  <li><a className="dropdown-item" href="sub-category/jig-saw-blade.html">Jig Saw Blade</a></li>
                  <li><a className="dropdown-item" href="sub-category/hole-saw.html">Hole Saw</a></li>
                  <li><a className="dropdown-item" href="sub-category/core-bits.html">Core Bits</a></li>
                  <li><a className="dropdown-item" href="sub-category/tile-cutter-blade.html">Tile Cutter Blade</a></li>
                  <li><a className="dropdown-item" href="sub-category/adjustable-circle-cutter.html">Adjustable Circle Cutter</a></li>
                  <li><a className="dropdown-item" href="sub-category/lock-installation-kit.html">Lock Installation Kit</a></li>
                  <li><a className="dropdown-item" href="sub-category/sds-adaptor.html">SDS Adaptor</a></li>
                  <li><a className="dropdown-item" href="sub-category/core-drill.html">Core Drill</a></li>
                  <li><a className="dropdown-item" href="sub-category/screw-driver-bits.html">Screw Driver Bits</a></li>
                  <li><a className="dropdown-item" href="sub-category/magnetic-bit-holder.html">Magnetic bit Holder</a></li>
                  <li><a className="dropdown-item" href="sub-category/drill-bits.html">Drill Bits</a></li>
                  <li><a className="dropdown-item" href="sub-category/hss-step-drill-bits.html">HSS Step Drill Bits</a></li>
                  <li><a className="dropdown-item" href="sub-category/wood-flat-bit.html">Wood Flat Bit</a></li>
                  <li><a className="dropdown-item" href="sub-category/drill-chuck.html">Drill Chuck</a></li>
                  <li><a className="dropdown-item" href="sub-category/caulking-gun.html">Caulking Gun</a></li>
                  <li><a className="dropdown-item" href="sub-category/grease-gun.html">Grease Gun</a></li>
                  <li><a className="dropdown-item" href="sub-category/spray-guns.html">Spray Guns</a></li>
                  <li><a className="dropdown-item" href="sub-category/router-bits.html">Router Bits</a></li>
                  <li><a className="dropdown-item" href="sub-category/trimmer-bits.html">Trimmer Bits</a></li>
                  <li><a className="dropdown-item" href="sub-category/glass-suction-cup.html">Glass Suction Cup</a></li>
                  <li><a className="dropdown-item" href="sub-category/chain-chain-bar.html">Chain &amp; Chain Bar</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/measuring-tools.html">Measuring Tools</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/measuring-tape.html">MEASURING TAPE</a></li>
                  <li><a className="dropdown-item" href="sub-category/sprit-level.html">SPRIT LEVEL</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/tct-saw-blades.html">Tct Saw Blades</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/xtra-power-x2.html">Xtra Power X2</a></li>
                  <li><a className="dropdown-item" href="sub-category/xtra-power.html">Xtra Power</a></li>
                  <li><a className="dropdown-item" href="sub-category/tct-xtra-power-gold.html">Xtra Power Gold</a></li>
                  <li><a className="dropdown-item" href="sub-category/tct-awant.html">Awant</a></li>
                  <li><a className="dropdown-item" href="sub-category/-sgw-tct.html"> SGW TCT</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/diamond-saw-blades.html">Diamond Saw Blades</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/diamond-xtra-power.html">Xtra Power</a></li>
                  <li><a className="dropdown-item" href="sub-category/sgw.html">SGW</a></li>
                  <li><a className="dropdown-item" href="sub-category/awant.html">Awant</a></li>
                  <li><a className="dropdown-item" href="sub-category/concut-series.html">Concut Series</a></li>
                  <li><a className="dropdown-item" href="sub-category/rewop.html">Rewop</a></li>
                  <li><a className="dropdown-item" href="sub-category/fast-cut.html">Fast Cut</a></li>
                  <li><a className="dropdown-item" href="sub-category/laser-cut.html">Laser Cut</a></li>
                  <li><a className="dropdown-item" href="sub-category/b-p.html">B&amp;P</a></li>
                  <li><a className="dropdown-item" href="sub-category/sand-stone.html">Sand Stone</a></li>
                  <li><a className="dropdown-item" href="sub-category/for-granite.html">For Granite</a></li>
                  <li><a className="dropdown-item" href="sub-category/glass-cutting-blade.html">Glass Cutting Blade</a></li>
                  <li><a className="dropdown-item" href="sub-category/vacuum-welded-multi-purpose.html">Vacuum Welded Multi Purpose</a></li>
                  <li><a className="dropdown-item" href="sub-category/vacuum-brazed-blade.html">Vacuum Brazed Blade</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/sds-drill-bits-chisels.html">Sds Drill Bits &amp; Chisels</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/sds-drill-bits.html">SDS Drill Bits</a></li>
                  <li><a className="dropdown-item" href="sub-category/chisels.html">Chisels</a></li>
                </ul>
              </li>
              <li className="sub_items"><a className="dropdown-item" href="category/agriculture-machines.html">Agriculture Machines</a>
                <ul className="sub_ul">
                  <li><a className="dropdown-item" href="sub-category/power-weeders.html">Power Weeders</a></li>
                  <li><a className="dropdown-item" href="sub-category/back-rotary.html">Back Rotary</a></li>
                  <li><a className="dropdown-item" href="sub-category/power-reaper.html">Power Reaper</a></li>
                  <li><a className="dropdown-item" href="sub-category/brush-cutter.html">Brush Cutter</a></li>
                  <li><a className="dropdown-item" href="sub-category/chain-saw.html">Chain Saw</a></li>
                  <li><a className="dropdown-item" href="sub-category/water-and-htp-pump.html">Water And HTP Pump</a></li>
                  <li><a className="dropdown-item" href="sub-category/engine.html">Engine</a></li>
                  <li><a className="dropdown-item" href="sub-category/mill-machine.html">Mill Machine</a></li>
                  <li><a className="dropdown-item" href="sub-category/sprayer.html">Sprayer</a></li>
                </ul>
              </li>
            </ul>
          </li> */}

                  <li className="nav-item ">
                    <a className="nav-link" href="career.html">
                      Career
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a className="nav-link" href="dealer.html">
                      Dealer
                    </a>
                  </li>

                  {/* <li className="nav-item dropdown ">
            <a className="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Resources
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><a className="dropdown-item" href="gallery.html">Gallery</a></li>
              <li><a className="dropdown-item" href="event.html">Events</a></li>
              <li><a className="dropdown-item" href="csr.html">Secretarial</a></li>
            </ul>
          </li> */}

                  <li className="nav-item ">
                    <a className="nav-link" href="contact.html">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        {/* Mobile side bar  */}
        <div
          className="offcanvas offcanvas-end bsr_SideBar"
          tabIndex={-1}
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header">
            <div className="side_red" />
            <img
              className="sidebar_logo"
              src="site-img/logo/header-logo.png"
              alt="XtraPowerTools Logo"
            />
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body">
            <div className="side_login_a">
              <a className="side_login" href="login.html">
                Login
              </a>{" "}
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <a
                  href="index.html"
                  className="accordion-button collapsed link"
                >
                  Home
                </a>
              </h2>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <a
                  href="about-us.html"
                  className="accordion-button collapsed link"
                >
                  About
                </a>
              </h2>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsepro"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Product
                </button>
              </h2>
              <div
                id="collapsepro"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-0">
                  <nav className="nav flex-column">
                    <a className="nav-link" href="category/power-tools.html">
                      Power Tools
                    </a>
                    <a className="nav-link" href="category/cleaning-tools.html">
                      Cleaning Tools
                    </a>
                    <a
                      className="nav-link"
                      href="category/welding-machine.html"
                    >
                      Welding Machine
                    </a>
                    <a className="nav-link" href="category/air-compressor.html">
                      Air Compressor
                    </a>
                    <a className="nav-link" href="category/spare-parts.html">
                      Spare Parts
                    </a>
                    <a className="nav-link" href="category/abrasives.html">
                      Abrasives
                    </a>
                    <a className="nav-link" href="category/accessories.html">
                      Accessories
                    </a>
                    <a
                      className="nav-link"
                      href="category/measuring-tools.html"
                    >
                      Measuring Tools
                    </a>
                    <a className="nav-link" href="category/tct-saw-blades.html">
                      Tct Saw Blades
                    </a>
                    <a
                      className="nav-link"
                      href="category/diamond-saw-blades.html"
                    >
                      Diamond Saw Blades
                    </a>
                    <a
                      className="nav-link"
                      href="category/sds-drill-bits-chisels.html"
                    >
                      Sds Drill Bits &amp; Chisels
                    </a>
                    <a
                      className="nav-link"
                      href="category/agriculture-machines.html"
                    >
                      Agriculture Machines
                    </a>
                  </nav>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <a
                  href="career.html"
                  className="accordion-button collapsed link"
                >
                  Career
                </a>
              </h2>
            </div>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <a
                    href="dealer.html"
                    className="accordion-button collapsed link"
                  >
                    Dealer
                  </a>
                </h2>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <a
                    href="gallery.html"
                    className="accordion-button collapsed link"
                  >
                    Gallery
                  </a>
                </h2>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Resources
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-0">
                    <nav className="nav flex-column">
                      <a className="nav-link" href="gallery.html">
                        Gallery
                      </a>
                      <a className="nav-link" href="event.html">
                        Events
                      </a>
                      <a className="nav-link" href="csr.html">
                        Secretarial
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <a
                    href="contact.html"
                    className="accordion-button collapsed link"
                  >
                    Contact Us
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* end of side bar  */}
        {/* 

<div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="img/carousel-1.jpg" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src="img/carousel-1.jpg" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src="img/carousel-1.jpg" className="d-block w-100" alt="..." />
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div> */}
        <section id="venue">
          <div className="container-fluid ">
            <div className="row slidere-bg animate-in-down">
              <div className="p-0 col-md-12">
                <div
                  className="carousel slide carousel-fade"
                  data-ride="carousel"
                  id="carousel1"
                >
                  <div className="carousel-inner" role="listbox">
                    <div className="carousel-item">
                      {" "}
                      <img
                        className="d-block img-fluid w-100"
                        src="img/carousel-1.jpg"
                        alt="first slide"
                      />
                      <div className="carousel-caption"></div>
                    </div>
                    <div className="carousel-item active">
                      {" "}
                      <img
                        className="d-block img-fluid w-100"
                        src="img/carousel-1.jpg"
                        data-holder-rendered="true"
                      />
                      <div className="carousel-caption"></div>
                    </div>
                    <div className="carousel-item">
                      {" "}
                      <img
                        className="d-block img-fluid w-100"
                        src="img/carousel-2.jpg"
                        data-holder-rendered="true"
                      />
                      <div className="carousel-caption"></div>
                    </div>
                    <div className="carousel-item">
                      {" "}
                      <img
                        className="d-block img-fluid w-100"
                        src="img/carousel-3.jpg"
                        data-holder-rendered="true"
                      />
                      <div className="carousel-caption"></div>
                    </div>
                    <div className="carousel-item">
                      {" "}
                      <img
                        className="d-block img-fluid w-100"
                        src="img/carousel-4.jpg"
                        data-holder-rendered="true"
                      />
                      <div className="carousel-caption"></div>
                    </div>
                    <div className="carousel-item">
                      {" "}
                      <img
                        className="d-block img-fluid w-100"
                        src="img/carousel-5.jpg"
                        data-holder-rendered="true"
                      />
                      <div className="carousel-caption"></div>
                    </div>
                    <div className="carousel-item">
                      {" "}
                      <img
                        className="d-block img-fluid w-100"
                        src="img/carousel-6.jpg"
                        data-holder-rendered="true"
                      />
                      <div className="carousel-caption"></div>
                    </div>
                  </div>{" "}
                  <a
                    className="carousel-control-prev"
                    href="#carousel1"
                    role="button"
                    data-slide="prev"
                  >
                    {" "}
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Previous</span>{" "}
                  </a>{" "}
                  <a
                    className="carousel-control-next"
                    href="#carousel1"
                    role="button"
                    data-slide="next"
                  >
                    {" "}
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />{" "}
                    <span className="sr-only">Next</span>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="index_main_section lslhome2">
          <section>
            <div id="home-slider" className="owl-carousel owl-theme">
              <div className="item">
                <img
                  src="site-img/banner/1701845219304590450657018e37171b.jpg"
                  alt
                />
              </div>
              <div className="item">
                <img
                  src="site-img/banner/17018452961701962866570193056677.jpg"
                  alt
                />
              </div>
              <div className="item">
                <img
                  src="site-img/banner/17018453274764354246570194fcfe66.jpg"
                  alt
                />
              </div>
              <div className="item">
                <img
                  src="site-img/banner/170548763577692001165a7ad13a7f35.jpg"
                  alt
                />
              </div>
              <div className="item">
                <img
                  src="site-img/banner/170650929419137677065b743eed82ba.jpg"
                  alt
                />
              </div>
            </div>
          </section>

          {/* <section className="powering_section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-9 text-center">
          <h3>Discover the Difference</h3>
          <p>We take pride in supplying genuine and best-quality products through our extensive network of suppliers. With over 500 channel partners across India, we have emerged as the market leader in supplying world-class power tools, abrasives, accessories, and more.</p>
          <a href="about-us.html">Learn More</a>
        </div>
      </div>
    </div>
  </section> */}

          {/* partial:index.partial.html */}
          <div className="about-section powering_section">
            <div className="inner-container">
              <div className="row text-left">
                <div className="col-12">
                  <h1 className="display-3 fw-bold text-white2">
                    Discover the Difference
                  </h1>
                  <hr
                    className="bg-black mb-1 mt-0 d-inline-block mx-auto "
                    style={{ width: 100, height: 3 }}
                  />
                </div>
              </div>

              <p className="text">
                <p className="lh-lg">
                  We take pride in supplying genuine and best-quality products
                  through our extensive network of suppliers. With over 500
                  channel partners across India, we have emerged as the market
                  leader in supplying world-class power tools, abrasives,
                  accessories, and more.
                </p>
                <a href="about-us.html" className="about">
                  Learn More
                </a>
              </p>
            </div>
          </div>
          {/* partial */}

          <section className="pb-3">
            <div className="container">
              <div className="row text-center">
                <div className="col-12">
                  <h1 className="display-3 fw-bold text-white1">
                    NEW PRODUCTS
                  </h1>
                  <hr
                    className="bg-black mb-4 mt-0 d-inline-block mx-auto "
                    style={{ width: 100, height: 3 }}
                  />
                </div>
              </div>

              <div className="pp_box">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-12 col-lg-6">
                    <div className="p_img">
                      <img
                        src="site-img/product/170470034696567984659ba9ba2eb38.png"
                        alt
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="pp_box_content">
                      <h1>XPT 587 GASOLINE CONCRETE CUTTER</h1>
                      <p>
                        Xtra Power XPT 587 Concrete Cutter is designed for
                        professional and demanding construction tasks. This
                        indispensable tool cuts through concrete, brick...
                      </p>
                      <a href="product-details/xpt-587-gasoline-concrete-cutter.html">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <h1 className="display-3 fw-bold text-white">NEW LINEUP</h1>
                <hr
                  className="bg-black mb-4 mt-0 d-inline-block mx-auto "
                  style={{ width: 100, height: 3 }}
                />
              </div>
            </div>

            <div className="row blog">
              <div className="col-md-12">
                <div
                  id="blogCarousel"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#blogCarousel"
                      data-slide-to={0}
                      className="active"
                    />
                    <li data-target="#blogCarousel" data-slide-to={1} />
                  </ol>
                  {/* Carousel items */}
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row">
                        <div className="col-md-3">
                          <img
                            src="site-img/product/1704103659102593971065928eebced1b.html"
                            alt="Image"
                            className="img-responsive img-fluid okokok"
                          />
                          <div className="captionpara">
                            <h4 className="clr_m">XPT 542 ELECTRIC BL..</h4>
                            <p className="clr_p">
                              The XPT 542 Electric Blower is a state-of-the-art,
                              double-insulated electric corded tool,
                              specifical...
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <img
                            src="site-img/product/170435784757681174965966fd738360.html"
                            alt="Image"
                            className="img-responsive img-fluid okokok"
                          />
                          <div className="captionpara">
                            <h4 className="clr_m">XTRA POWER X10</h4>
                            <p>
                              X10 Diamond Saw Blade is the pinnacle of
                              cutting-edge technology and robust design for both
                              professi...
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <img
                            src="site-img/product/17020163466867722356572b55a51d74.html"
                            alt="Image"
                            className="img-responsive img-fluid okokok"
                          />
                          <div className="captionpara">
                            <h4 className="clr_m">XPT 554 DRY WALL SA..</h4>
                            <p>
                              Designed for those who demand both precision and
                              efficiency, the XPT 554 Drywall Sander is set to
                              re...
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <img
                            src="site-img/product/17024505361523134334657955685069d.html"
                            alt="Image"
                            className="img-responsive img-fluid okokok"
                          />
                          <div className="captionpara">
                            <h4 className="clr_m">XPT 419W MARBLE CUT..</h4>
                            <p>
                              Experience unmatched cutting precision with the
                              XPT 419W Marble Cutter, a powerhouse tool designed
                              f...
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*.row*/}
                    </div>
                    {/*.item*/}
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-3">
                          <img
                            src="site-img/product/1702899930966447674658030da5bcf1.html"
                            alt="Image"
                            className="img-responsive img-fluid okokok"
                          />
                          <div className="captionpara">
                            <h4 className="clr_m">XPT 544 TRIMMER</h4>
                            <p>
                              The XPT 544 trimmer with a no-load speed of 30000
                              RPM, a collet capacity of 6.35MM, and an input
                              pow...
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <img
                            src="site-img/product/17005711942114122926655ca83a93b46.html"
                            alt="Image"
                            className="img-responsive img-fluid okokok"
                          />

                          <div className="captionpara">
                            <h4 className="clr_m">XPT 546 MIXER DRILL</h4>
                            <p>
                              The Xtra Power Mixer Drill is a high-performing
                              and versatile tool, ideal for a variety of mixing
                              ta...
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <img
                            src="site-img/product/1701071512178318635365644a98a766f.html"
                            alt="Image"
                            className="img-responsive img-fluid okokok"
                          />
                          <div className="captionpara">
                            <h4 className="clr_m">XPT 550 CONCRETE VI..</h4>
                            <p>
                              The XPT-550 Concrete Vibrator: A Sturdy Solution
                              for Optimal Concrete Compression This
                              cutting-edge...
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <img
                            src="site-img/product/17010711031450835261656448ff361f5.html"
                            alt="Image"
                            className="img-responsive img-fluid okokok"
                          />
                          <div className="captionpara">
                            <h4 className="clr_m">XPT 547 MIXER DRILL</h4>
                            <p>
                              Equipped with a powerful 1200-watt motor, the
                              XPT-547 delivers an incredible no-load speed of
                              900 RP...
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*.row*/}
                    </div>
                    {/*.item*/}
                  </div>
                  {/*.carousel-inner*/}
                </div>
                {/*.Carousel*/}
              </div>
            </div>
          </div>

          {/*   

  <section className="cate_section">
    <div className="container">
      <div className="counter_right">
        <div id="counter" />
      </div>
      <div className="row align-items-center row_padding">
        <div className="col-md-12 col-lg-2">
          <h2><b>NEW LINEUP</b></h2>
        </div>
        <div className="col-md-12 col-lg-10">
          <div id="liner-slider" className="slider owl-carousel owl-theme">
            <div className="item">
              <a href="product-details/xpt-542-electric-blower.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/1704103659102593971065928eebced1b.html" alt />
                <h4 className="clr_m">XPT 542 ELECTRIC BL..</h4>
                <p>The XPT 542 Electric Blower is a state-of-the-art, double-insulated electric corded tool, specifical...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xtra-power-x10.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/170435784757681174965966fd738360.html" alt />
                <h4 className="clr_m">XTRA POWER X10</h4>
                <p>X10 Diamond Saw Blade is the pinnacle of cutting-edge technology and robust design for both professi...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xpt-554-dry-wall-sander.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/17020163466867722356572b55a51d74.html" alt />
                <h4 className="clr_m">XPT 554 DRY WALL SA..</h4>
                <p>Designed for those who demand both precision and efficiency, the XPT 554 Drywall Sander is set to re...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xpt-419w-marble-cutter.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/17024505361523134334657955685069d.html" alt />
                <h4 className="clr_m">XPT 419W MARBLE CUT..</h4>
                <p>Experience unmatched cutting precision with the XPT 419W Marble Cutter, a powerhouse tool designed f...</p>
              </a>
            </div>    




            <div className="item">
              <a href="product-details/xpt-544-trimmer.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/1702899930966447674658030da5bcf1.html" alt />
                <h4 className="clr_m">XPT 544 TRIMMER</h4>
                <p>The XPT 544 trimmer with a no-load speed of 30000 RPM, a collet capacity of 6.35MM, and an input pow...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xpt-546-mixer-drill.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/17005711942114122926655ca83a93b46.html" alt />
                <h4 className="clr_m">XPT 546 MIXER DRILL</h4>
                <p>The Xtra Power Mixer Drill is a high-performing and versatile tool, ideal for a variety of mixing ta...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xpt-547-mixer-drill.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/17010711031450835261656448ff361f5.html" alt />
                <h4 className="clr_m">XPT 547 MIXER DRILL</h4>site-img/product/17010711031450835261656448ff361f5.html
                <p>Equipped with a powerful 1200-watt motor, the XPT-547 delivers an incredible no-load speed of 900 RP...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xpt-550-concrete-vibrator.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/1701071512178318635365644a98a766f.html" alt />
                <h4 className="clr_m">XPT 550 CONCRETE VI..</h4>
                <p>The XPT-550 Concrete Vibrator: A Sturdy Solution for Optimal Concrete Compression
                  This cutting-edge...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xpt-560-marble-cutter.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/1702708228237169589657d440482dbd.html" alt />
                <h4 className="clr_m">XPT 560 MARBLE CUTT..</h4>
                <p>Equipped with a 1450 Watts motor, the Xtra Power XPT 560 marble cutter delivers powerful and steady ...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xpt-588-chain-saw.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/1700572039974183051655cab87d8c96.png" alt />
                <h4 className="clr_m">XPT 588 CHAIN SAW</h4>
                <p>Xtra Power XPT 588 Chain Saw Machine, a state-of-the-art tool tailored for challenging cutting tasks...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xpt-589-chain-saw-.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/17005721911455358447655cac1fa46f9.png" alt />
                <h4 className="clr_m">XPT 589 CHAIN SAW </h4>
                <p>Xtra Power XPT 589 Chain Saw Machine, a state-of-the-art tool tailored for challenging cutting tasks...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xtra-power-flap-disc-t29.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/170116209916321369246565ac7302657.html" alt />
                <h4 className="clr_m">XTRA POWER FLAP DIS..</h4>
                <p>The Xtra Power Flap Disc T29 stands out with its distinctive conical shape and an effective angle ac...</p>
              </a>
            </div>    
            <div className="item">
              <a href="product-details/xtra-power-flap-disc-gx.html" className="cate_box mb-0">
                <img className="w-100 okokok" src="site-img/product/17011623216906943976565ad51d026a.html" alt />
                <h4 className="clr_m">XTRA POWER FLAP DIS..</h4>
                <p>The "Xtra Power Flap Disc GX" is an advanced abrasive tool designed specifically for right-angle gri...</p>
              </a>
            </div>    
          </div>       
        </div>
      </div>
    </div>
  </section> */}

          <section
            style={{
              background:
                "url(images/new_index/perfo.jpg) center center no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="container black_banner">
              <div className="text-center">
                {/* <h1>ONE STOP SOLUTION</h1> */}
                <h1 className="title_font_size">
                  HIGH PERFORMANCE TOOLS &amp; ACCESSORIES
                </h1>
              </div>
            </div>
          </section>
          <section className="">
            <div className="container">
              <div className="row text-center">
                <div className="col-12">
                  <h1 className="display-3 fw-bold text-white">
                    PRODUCT CATEGORIES
                  </h1>
                  <hr
                    className="bg-black mb-4 mt-0 d-inline-block mx-auto "
                    style={{ width: 100, height: 3 }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6 col-md-3">
                  <a href="category/power-tools.html" className="cate_box">
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/168872510379008711264a7e66fa2dbc.jpg"
                      alt
                    />
                    <h4>Power Tools</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a href="category/cleaning-tools.html" className="cate_box">
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/168172828413755264643d231c6ba4d.jpg"
                      alt
                    />
                    <h4>Cleaning Tools</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a href="category/welding-machine.html" className="cate_box">
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/166076312977616132562fd3bf9badd1.jpg"
                      alt
                    />
                    <h4>Welding Machine</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a href="category/air-compressor.html" className="cate_box">
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/168923436512304706564afabbd0c3d6.jpg"
                      alt
                    />
                    <h4>Air Compressor</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a href="category/spare-parts.html" className="cate_box">
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/16648748831654180224633bf9830aaff.jpg"
                      alt
                    />
                    <h4>Spare Parts</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a href="category/abrasives.html" className="cate_box">
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/1660763212178790874662fd3c4c36b0e.jpg"
                      alt
                    />
                    <h4>Abrasives</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a href="category/accessories.html" className="cate_box">
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/16648747261194965575633bf8e62564c.jpg"
                      alt
                    />
                    <h4>Accessories</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a href="category/measuring-tools.html" className="cate_box">
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/1681883266974651577643f80828283f.jpg"
                      alt
                    />
                    <h4>Measuring Tools</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a href="category/tct-saw-blades.html" className="cate_box">
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/168958670816063009964b50c148e6d8.jpg"
                      alt
                    />
                    <h4>Tct Saw Blades</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a
                    href="category/diamond-saw-blades.html"
                    className="cate_box"
                  >
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/166316683620614601446321e97440630.jpg"
                      alt
                    />
                    <h4>Diamond Saw Blades</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a
                    href="category/sds-drill-bits-chisels.html"
                    className="cate_box"
                  >
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/1664874800254519559633bf9302384d.jpg"
                      alt
                    />
                    <h4>Sds Drill Bits &amp; Chisels</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a
                    href="category/agriculture-machines.html"
                    className="cate_box"
                  >
                    <img
                      className="w-100 imgs"
                      src="site-img/cat/16648749231873175042633bf9ab34c11.jpg"
                      alt
                    />
                    <h4>Agriculture Machines</h4>
                    {/* <p>...</p> */}
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section
            style={{
              background:
                "url(images/new_index/house.jpg) center center no-repeat",
            }}
          >
            <div className="container black_banner2">
              <div className="text-center">
                {/* <h1>HOUSE OF XTRA POWER</h1> */}
                {/*  <div class="logo_sm">
          <img src="/images/new_index/logo1.png" alt="">
          <img src="/images/new_index/logo2.png" alt="">
          <img src="/images/new_index/logo3.png" alt="">
          <img src="/images/new_index/logo4.png" alt="">
          <img src="/images/new_index/logo5.png" alt="">
          <img src="/images/new_index/logo6.png" alt="">
      </div> */}
              </div>
            </div>
          </section>
          <section className="">
            <div className="container">
              <div className="row text-center">
                <div className="col-12">
                  <h1 className="display-3 fw-bold text-white">POWER TOOLS</h1>
                  <hr
                    className="bg-black mb-4 mt-0 d-inline-block mx-auto "
                    style={{ width: 100, height: 3 }}
                  />
                </div>
              </div>

              <div className="p_tools_main" id="myList">
                <a
                  href="product-category/angle-grinders.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674465541170689962863ce5105e78d4.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Angle Grinder</p>
                  </div>
                </a>
                <a
                  href="product-category/marble-cutters.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674465494164457342263ce50d60a26c.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Marble Cutter</p>
                  </div>
                </a>
                <a
                  href="product-category/circular-saws.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167446552790352495463ce50f781729.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Circular Saw</p>
                  </div>
                </a>
                <a
                  href="product-category/router-machines.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674465569121438761963ce512121aaa.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p> Router machine</p>
                  </div>
                </a>
                <a
                  href="product-category/rotary-hammers.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674466253128351052263ce53cd5bd67.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Rotary Hammer</p>
                  </div>
                </a>
                <a
                  href="product-category/planers.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167446620167097159763ce53992dcc8.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Planers</p>
                  </div>
                </a>
                <a
                  href="product-category/Wood-trimmer.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/16744661908936951263ce538edca98.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Trimmer</p>
                  </div>
                </a>
                <a
                  href="product-category/sanders.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674466219122020119863ce53aba8d7b.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Sander</p>
                  </div>
                </a>
                <a
                  href="product-category/demolition-hammers.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674466279116096695763ce53e7ac657.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Demolition Hammer </p>
                  </div>
                </a>
                <a
                  href="product-category/drill-machine.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464879244774582363d11cd8ec986.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Drill Machine</p>
                  </div>
                </a>
                <a
                  href="product-category/Electric-screwdrivers.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464882352286471363d11cf7dbfe6.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Screw Driver Drill</p>
                  </div>
                </a>
                <a
                  href="product-category/wall-chaser.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464884336328799263d11d0bab2ee.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Wall Chaser</p>
                  </div>
                </a>
                <a
                  href="product-category/mixer.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464885750814789763d11d19b1b63.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Mixer</p>
                  </div>
                </a>
                <a
                  href="product-category/concrete-vibrator.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464887654875759763d11d2c96ad4.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Concrete Vibrator</p>
                  </div>
                </a>
                <a
                  href="product-category/straight-grinders.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674648896102150446163d11d402a5a6.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Straight Grinder</p>
                  </div>
                </a>
                <a
                  href="product-category/die-grinder.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464890778308743063d11d4b964fa.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Die Grinder</p>
                  </div>
                </a>
                <a
                  href="product-category/jigsaw-machine.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674648918130307578063d11d5610a29.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Jig Saw</p>
                  </div>
                </a>
                <a
                  href="product-category/blowers.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464894029852664763d11d6cd030f.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Blower</p>
                  </div>
                </a>
                <a
                  href="product-category/tile-tiling-machine.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464916119952730963d11e493d47f.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>TILE TILING MACHINE</p>
                  </div>
                </a>
                <a
                  href="product-category/cut-off-machine.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674648977106930321563d11d91659c0.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Cut Off Machine</p>
                  </div>
                </a>
                <a
                  href="product-category/concrete-cutting-machine.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674648965212678350063d11d85cf6d3.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Concrete Cutter</p>
                  </div>
                </a>
                <a
                  href="product-category/miter-saws.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674648991129680327063d11d9fdd69b.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Miter Saw</p>
                  </div>
                </a>
                <a
                  href="product-category/impact-wrenches.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/16746490043857972863d11dac88325.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Impact Wrench</p>
                  </div>
                </a>
                <a
                  href="product-category/hot-air-gun.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464903974072053063d11dcf3b123.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Hot Air Gun</p>
                  </div>
                </a>
                <a
                  href="product-category/core-drill-machines.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674649054176512222563d11dde939a3.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Core Drill</p>
                  </div>
                </a>
                <a
                  href="product-category/chain-saw-grinder.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/16746490712759562663d11def1d1ab.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Chain Saw Grinder</p>
                  </div>
                </a>
                <a
                  href="product-category/chain-saws.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674649094152304701063d11e069dec6.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Chain Saw</p>
                  </div>
                </a>
                <a
                  href="product-category/polishing-machines.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464912254826686563d11e22ef47e.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Polisher</p>
                  </div>
                </a>
                <a
                  href="product-category/tool-kit.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1674649136102047616363d11e303ef06.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Tool Kit</p>
                  </div>
                </a>
                <a
                  href="product-category/brush-cutters.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/167464914825231728863d11e3c5d1d0.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Brush Cutter</p>
                  </div>
                </a>
                <a
                  href="product-category/earth-auger.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/1704103871158295590465928fbfd28a0.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Earth Auger</p>
                  </div>
                </a>
                <a
                  href="product-category/hedge-trimmer.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/170427033917239809165951a03c383b.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Hedge Trimmer</p>
                  </div>
                </a>
                <a
                  href="product-category/water-pump.html"
                  className="tools_box"
                  style={{ display: "none !important" }}
                >
                  <img
                    src="site-img/cat/17042781602337151496595389045561.jpg"
                    alt
                  />
                  <div className="tools_content">
                    <p>Water Pump</p>
                  </div>
                </a>
              </div>
              <div className="show_more_btn">
                <a
                  href="javascript:void(0);"
                  id="loadMore"
                  className="know_more"
                >
                  Show More
                </a>
              </div>
            </div>
          </section>
          <section className="catalogue_section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="cata_panding">
                    <img src="images/new_index/master-catalogue-2023.png" alt />
                    <div className="catalogue_btn">
                      <a
                        target="_blank"
                        href="https://drive.google.com/file/d/18BWA4XaR5FqwYfPQ8HlujgbZ6QaXj8yX/view?usp=drive_link"
                      >
                        VIEW
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img className="w-100" src="images/new_index/ms.jpg" alt />
                </div>
              </div>
            </div>
          </section>

          <section className="client pt-3 pb-5">
            <div className="container">
              <div className="row text-center">
                <div className="col-12">
                  <h1 className="display-3 fw-bold text-white">
                    What our customers say
                  </h1>
                  <hr
                    className="bg-black mb-4 mt-0 d-inline-block mx-auto "
                    style={{ width: 100, height: 3 }}
                  />
                </div>
              </div>
              <div className="row align-items-md-center text-white">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div
                    id="carouselExampleCaptions"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    {/* Wrapper for slides */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="row p-2">
                          <div className="t-card">
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <p className="lh-lg">
                              {" "}
                              The quality of the products is excellent. Buying
                              from Xtra Power feels like getting value for
                              money. The new products that Company Offers are
                              attractive. The brand name for Heavy duty, XP, is
                              very appealing. The response of the team is apt
                              and on time. The team behaves appropriately all
                              the time.
                            </p>

                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                            <br />
                          </div>
                          <div className="row">
                            <div className="col-sm-2 pt-1">
                              <img
                                src="img/1.jpg"
                                className="rounded-circle img-responsive img-fluid"
                              />
                            </div>
                            <div className="col-sm-10">
                              <div className="arrow-down d-none d-lg-block" />
                              <h4 className="testimonial_head">
                                <strong> Jitender Kumar</strong>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row p-2">
                          <div className="t-card">
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <p className="lh-lg">
                              Doing business with Xtra Power does not mean being
                              their dealer; it means being a partner for life.
                              Their increase in the digital presence daily is
                              making my life easier, making it easy for me to
                              explain and show to customers what Xtra Power is
                              all about. Their excellent logistics team ensures
                              we get timely delivery every time without any fuss
                              or hamper in our sales.
                            </p>

                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                            <br />
                          </div>
                          <div className="row">
                            <div className="col-sm-2 pt-1">
                              <img
                                src="img/1.jpg"
                                className="rounded-circle img-responsive img-fluid"
                              />
                            </div>
                            <div className="col-sm-10">
                              <div className="arrow-down d-none d-lg-block" />
                              <h4 className="testimonial_head">
                                <strong> Awesh Bansal</strong>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row p-2">
                          <div className="t-card">
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <p className="lh-lg">
                              Xtra Power always tries to deliver something extra
                              to us. The color scheme of products is so unique
                              and varied that it stands out every time compared
                              to its competitors. The quality of the packaging
                              and its combination makes it eye-catching for
                              every customer.
                            </p>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                            <br />
                          </div>
                          <div className="row">
                            <div className="col-sm-2 pt-1">
                              <img
                                src="img/1.jpg"
                                className="rounded-circle img-responsive img-fluid"
                              />
                            </div>
                            <div className="col-sm-10">
                              <div className="arrow-down d-none d-lg-block" />
                              <h4 className="testimonial_head">
                                <strong> Shabbir Hussain</strong>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row p-2">
                          <div className="t-card">
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <p className="lh-lg">
                              For the past 15 years, I had never thought about
                              adding more brands to my portfolio since all my
                              and customers' needs are being satisfied because
                              of the range of products they maintain. And, I am
                              sure I won't have to look anywhere else in the
                              future because that's what means being associated
                              with Xtra Power.
                            </p>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                            <br />
                          </div>
                          <div className="row">
                            <div className="col-sm-2 pt-1">
                              <img
                                src="img/1.jpg"
                                className="rounded-circle img-responsive img-fluid"
                              />
                            </div>
                            <div className="col-sm-10">
                              <div className="arrow-down d-none d-lg-block" />
                              <h4 className="testimonial_head">
                                <strong> Manish Agarwal </strong>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="carousel-item">
                        <div className="row p-2">
                          <div className="t-card">
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <p className="lh-lg">
                              {" "}
                              Believing in Xtra Power has changed my life.
                              Coming to their head office after so many years
                              and seeing their team functioning as one unit in
                              sync has left me astonished. Meeting their team
                              gave me many insights into sales and marketing
                              strategies that I never thought I could apply to
                              my business. The overall experience was an eye
                              opener for me, making me hold them in high regard.
                            </p>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                            <br />
                          </div>
                          <div className="row">
                            <div className="col-sm-2 pt-1">
                              <img
                                src="img/1.jpg"
                                className="rounded-circle img-responsive img-fluid"
                              />
                            </div>
                            <div className="col-sm-10">
                              <div className="arrow-down d-none d-lg-block" />
                              <h4 className="testimonial_head">
                                <strong>Kailash Panda</strong>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row p-2">
                          <div className="t-card">
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <p className="lh-lg">
                              One thing I like about Xtra power is that they
                              always maintain a wide variety of spare parts and
                              machine spare parts. This makes it easy to sell to
                              clients and end consumers without hesitation.
                              Whenever a problem arises, the team ensures that
                              all hands are on deck to help us.
                            </p>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                            <br />
                          </div>
                          <div className="row">
                            <div className="col-sm-2 pt-1">
                              <img
                                src="img/1.jpg"
                                className="rounded-circle img-responsive img-fluid"
                              />
                            </div>
                            <div className="col-sm-10">
                              <div className="arrow-down d-none d-lg-block" />
                              <h4 className="testimonial_head">
                                <strong>Vikas Jain</strong>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="controls push-right">
                    <a
                      className="left fa fa-chevron-left btn btn-outline-light"
                      href="#carouselExampleCaptions"
                      data-bs-slide="prev"
                    />
                    <a
                      className="right fa fa-chevron-right btn btn-outline-light"
                      href="#carouselExampleCaptions"
                      data-bs-slide="next"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="testimonials testbg">
    <div className="container">
      <h2><b>TESTIMONIALS</b></h2>
      <div className="owl-testimonials-carousel owl-theme owl-carousel">
        <div className="item first-item">
          <div className="inner">
            <img src="site-img/testimonial/16748928864898471363d4d656b0c5d.jpg" className="img-fluid" />
            <div className="name">
              Jitender Kumar                  </div>
            <div className="comma fa fa-quote-left" />
            <p>The quality of the products is excellent. Buying from Xtra Power feels like getting value for money. The new products that Company Offers are attractive. The brand name for Heavy duty, XP, is very appealing. The response of the team is apt and on time. The team behaves appropriately all the time.</p>
          </div>
        </div>
        <div className="item second-item">
          <div className="inner">
            <img src="site-img/testimonial/167489285614229983463d4d6383e1b3.jpg" className="img-fluid" />
            <div className="name">
              Awesh Bansal                  </div>
            <div className="comma fa fa-quote-left" />
            <p>Doing business with Xtra Power does not mean being their dealer; it means being a partner for life. Their increase in the digital presence daily is making my life easier, making it easy for me to explain and show to customers what Xtra Power is all about. Their excellent logistics team ensures we get timely delivery every time without any fuss or hamper in our sales.</p>
          </div>
        </div>
        <div className="item third-item">
          <div className="inner">
            <img src="site-img/testimonial/1674892837163677399463d4d6255b703.jpg" className="img-fluid" />
            <div className="name">
              Shabbir Hussain                  </div>
            <div className="comma fa fa-quote-left" />
            <p>Xtra Power always tries to deliver something extra to us. The color scheme of products is so unique and varied that it stands out every time compared to its competitors. The quality of the packaging and its combination makes it eye-catching for every customer.</p>
          </div>
        </div>



        <div className="item first-item">
          <div className="inner">
            <img src="site-img/testimonial/1674892811119148746763d4d60bece68.jpg" className="img-fluid" />
            <div className="name">
              Manish Agarwal                  </div>
            <div className="comma fa fa-quote-left" />
            <p>For the past 15 years, I had never thought about adding more brands to my portfolio since all my and customers' needs are being satisfied because of the range of products they maintain. And, I am sure I won't have to look anywhere else in the future because that's what means being associated with Xtra Power.</p>
          </div>
        </div>
        <div className="item second-item">
          <div className="inner">
            <img src="site-img/testimonial/167489277054382811463d4d5e20ce02.jpg" className="img-fluid" />
            <div className="name">
              Kailash Panda                  </div>
            <div className="comma fa fa-quote-left" />
            <p>Believing in Xtra Power has changed my life. Coming to their head office after so many years and seeing their team functioning as one unit in sync has left me astonished. Meeting their team gave me many insights into sales and marketing strategies that I never thought I could apply to my business. The overall experience was an eye opener for me, making me hold them in high regard.</p>
          </div>
        </div>
        <div className="item third-item">
          <div className="inner">
            <img src="site-img/testimonial/167465000471358839463d12194676bb.jpg" className="img-fluid" />
            <div className="name">
              Vikas Jain                  </div>
            <div className="comma fa fa-quote-left" />
            <p>One thing I like about Xtra power is that they always maintain a wide variety of spare parts and machine spare parts. This makes it easy to sell to clients and end consumers without hesitation. Whenever a problem arises, the team ensures that all hands are on deck to help us.</p>
          </div>
        </div>
      </div>
    </div>
  </section> */}
          <section className="footer_red">
            <h2>Upgrade Your Toolbox</h2>
          </section>
        </section>{" "}
        {/* Index Main Section */}
        <div>
          <footer>
            <div className="container">
              {/* <div class="footer_logo">
          <div>
              <img src="/site-img/logo/logo-footer.png" alt="">
          </div>
          <ul class="f_top_menu">
                                      <li><a href=""></a></li>
                                      <li><a href="">Secretarial</a></li>
          </ul>
      </div> */}
              <div className="row justify-content-between">
                <div className="col-md-6 col-xl-3 mt-5">
                  <h5>Contact info</h5>
                  <ul className="footer_contact">
                    <li>
                      <i className="fa-solid fa-location-dot" /> Xtra Power
                      Tools PRIVATE LIMITED
                      <br />
                      Regd. Office:PLOT NO. 394, UDYOG VIHAR, PHASE III,
                      <br />
                      GURUGRAM - 122016 <br />
                      HARYANA, INDIA
                      <br />
                      CIN : U28100HR1995PTC088529
                    </li>
                    <li>
                      <i className="fa-solid fa-phone" />
                      +91 124 4000608
                      <br />
                      +91 8743800144
                    </li>
                    <li>
                      <i className="fa-solid fa-envelope" />
                      <b>
                        For enquiry/Requirement:
                      </b> Sales@xtrapowertools.com <br />{" "}
                      <b>For query/Feedback:</b> care@xtrapowertools.com
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-xl-3 mt-5">
                  <h5>Map</h5>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.126488866594!2d77.0866324149203!3d28.50584188246796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19451a7fc31d%3A0xbda28e726a11a616!2sXtra%20Power%20Tools%20(LSL%20Tools%20Private%20Limited)!5e0!3m2!1sen!2sin!4v1664267416376!5m2!1sen!2sin"
                    width="100%"
                    height={250}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
                <div className="col-md-6 col-xl-3 mt-5">
                  <h5>For Query</h5>
                  <form
                    action="https://xtrapowertools.com/contact-action"
                    method="POST"
                  >
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <div className></div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            name="name"
                            id="full_name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email address"
                            name="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            name="phone"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className>
                          <textarea
                            className="form-control"
                            id
                            rows={2}
                            placeholder="Your Message"
                            name="message"
                            required
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          type="submit"
                          name="submit"
                          className="btn_submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-4 col-xl-2 mt-5">
                  <h5 className>Follow Us</h5>
                  <ul className="footer_social">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/toolsxtrapower"
                      >
                        <img src="site-img/social/169200081794528350264d9e231a92cc.jpg" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/xtra_power_tools/"
                      >
                        <img src="site-img/social/1692000897176265785264d9e281b2458.jpg" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/xtrapower-tools-pvt-ltd/mycompany"
                      >
                        <img src="site-img/social/169200125419182265364d9e3e61feee.jpg" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@xtrapowertools"
                      >
                        <img src="site-img/social/1692001677127090056364d9e58debf9d.jpg" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://api.whatsapp.com/send/?phone=918743800144&text&type=phone_number&app_absent=0"
                      >
                        <img src="site-img/social/1693542307178805031864f167a349cf3.jpg" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://twitter.com/ToolsXtraPower"
                      >
                        <img src="site-img/social/169503533618309058865082fc8bcef5.jpg" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer_disc">
              <h3>Disclaimer</h3>
              <p>
                Despite every effort has been made to provide accurate images of
                each product color, design, description, and packaging, but
                actual colors, design, description, and packaging may vary
                slightly, due to different device screen settings, the lighting
                in the installation location, slight differences in product
                finishes over time and other factors. Our company will not
                accept responsibility for any color or design differences that
                are not factory faults. In purchasing from our company, you
                agree to accept the small risk that there will be a slight
                variation between the actual color, design, size, description,
                packing, and the representation on our website/catalog.
              </p>
            </div>
            <div className="copyright">
              Copyright © 2024 | Powered by Xtra Power Tools Private Limited
            </div>
          </footer>
          <div className="h_mobile">
            <div className="left_social">
              <a href="https://www.facebook.com/toolsxtrapower" target="_blank">
                <img src="site-img/social/169200081794528350264d9e231a92cc.jpg" />
                <span>Facebook</span>
              </a>
              <a
                href="https://www.instagram.com/xtra_power_tools/"
                target="_blank"
              >
                <img src="site-img/social/1692000897176265785264d9e281b2458.jpg" />
                <span>Instagram</span>
              </a>
              <a
                href="https://www.linkedin.com/company/xtrapower-tools-pvt-ltd/mycompany"
                target="_blank"
              >
                <img src="site-img/social/169200125419182265364d9e3e61feee.jpg" />
                <span>linkedin</span>
              </a>
              <a href="https://www.youtube.com/@xtrapowertools" target="_blank">
                <img src="site-img/social/1692001677127090056364d9e58debf9d.jpg" />
                <span>Youtube</span>
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=918743800144&text&type=phone_number&app_absent=0"
                target="_blank"
              >
                <img src="site-img/social/1693542307178805031864f167a349cf3.jpg" />
                <span>Whatsapp</span>
              </a>
              <a href="https://twitter.com/ToolsXtraPower" target="_blank">
                <img src="site-img/social/169503533618309058865082fc8bcef5.jpg" />
                <span>Twitter</span>
              </a>
            </div>
          </div>
          <div className="left_toast"></div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data:
      state.SliderProMsg.item == (null || undefined)
        ? []
        : state.SliderProMsg.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSliderApi: () => {
      dispatch(SliderProMsgActions.GetSliderImage());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
