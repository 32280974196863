import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import TextField from "@material-ui/core/TextField";
import { FileUpload } from "primereact/fileupload";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import Moment from "react-moment";
import { ToastContainer, } from "react-toastify";
import { roleMasterActions, BinMasterActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
// import { CSVLink } from "react-csv";
import Switch from "@material-ui/core/Switch";
// import "./Master.css";
import { Dropdown } from "primereact/dropdown";

class BinMaster extends Component {
  constructor(props) {
    super(props);
    this.tblResponseExport = new tableRequest();
    this.tblResponse = new tableRequest();
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
    this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);

    this.state = {
      editing: false,
      IsAdd: true,
      IsEdit: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      CreatedDate: null,
      UpdatedDate: null,
      sortOrder: "",
      buttonLabel: "Submit",
      switchAction: false,
      formHeading: "Add Bin Location",
      wareHouseId: "",
      UserName: "",
      RowName: "",
      RowNo: "",
      ColNo: "",
      BinId: 0,

      StoreData: [{ value: 1, label: "Finished Goods Store" },
      { value: 2, label: "Semi Finished Goods Store" },
      { value: 3, label: "Row Material Store" }],
      StoreType: "",
      Height: "",
      Width: "",
      Length: "",
      Valume: "",

      ColStatus: true,
      IsActive: true,
      formError: {
        RowName: "",
        RowNo: "",
        ColNo: "",
        wareHouseId: "",
        StoreType: "",
        Height: "",
        Width: "",
        Length: "",
        Valume: "",
      }
    };
  }

  // this line use to ssions
  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);
      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });
      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }

    this.props.fetchApi(this.tblResponse);
    this.props.getWarehouseDDL();
    //this.props.fetchAllBinData();
    console.log(this.props.Binitems);
    this.tblResponseExport.PageSize = -1;
    this.tblResponseExport.First = 1;
    this.tblResponseExport.IsActive = true;
    this.tblResponseExport.SearchValue = "";
    this.tblResponseExport.SortOrder = "ASC";
    this.props.exportingData(this.tblResponseExport);
    // const userId = JSON.parse(sessionStorage.getItem("UserData"));

  }

  componentDidUpdate(prevProps) {
    if (this.props.BinList) {
      if (prevProps.BinList !== this.props.BinList) {
        // this.props.myProp has a different value
        if (this.props.BinList) {
          var e = this.props.BinList;
          this.setState({
            totalRecords: e[0]?.TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  switchToggleConfirm = (e) => {
    
    this.props.inActiveRow(this.state.BinId, this.state.switchAction);
    console.log("BINID", this.state.BinId, this.state.switchAction)
  };

  handleChange(event) {
    let { name, value } = event.target;
    this.validateForm(value, name);
    this.setState({ [name]: value });

  }

  UpdatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>;
    } else {
    }
  }

  CreatedDateTemplate(rowData) {
    if (rowData.UpdatedDate !== null) {
      return <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>;
    }
  }
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "wareHouseId":
        if (value === 0) {
          IsValid = false;
          errors.wareHouseId = "Please select Warehouse";
        } else errors.wareHouseId = null;
        break;
      case "Height":
        if (value.length < 1) {
          IsValid = false;
          errors.Height = "Please enter height ";
        } else errors.Height = "";
        break;
      case "Width":
        if (value.length < 1) {
          IsValid = false;
          errors.Width = "Please enter width ";
        } else errors.Width = "";
        break;
      // case "Length":
      //   if (value.length < 1) {
      //     IsValid = false;
      //     errors.Length = "Please enter length ";
      //   } else errors.Length = "";
      //   break;
      // case "Valume":
      //   if (value.length < 1) {
      //     IsValid = false;
      //     errors.OrderQty = "Please enter order qty";
      //   } else errors.OrderQty = "";
      //   break;

      case "Length":
        if (value.trim().length < 1) {
          IsValid = false;
          errors.Length = "Please enter your lenth.";
        } else {
          if (!value.match(/^[0-9]+$/)) {
            IsValid = false;
            errors.Length = "Please enter only number.";
            this.setState({ Length: 0 });
          } else {
            errors.Valume = null;
            errors.Height = null;
            errors.Length = null;
            errors.Width = null;
            const Length = value.trim() === '' ? 0 : parseInt(value.trim());
            if (this.state.Height >= 1 && Length >= 1 && this.state.Width >= 1) {
              const Valume = (this.state.Height * Length * this.state.Width);
              console.log("DATABHAI", Valume);
              if (this.state.Valume >= 0) {
                this.setState({ Valume: Valume })
              } else {
                IsValid = false;
                errors.Length = "Length qty should be less than or equal to Received number.";
                this.setState({ Length: 0 })
              }
            }
          }
        } //errors.DamageQty = "";
        break;
      case "RowNo":
        if (!value === value) {
          IsValid = false;
        } else {
          if (value < 1) {
            IsValid = false;
            errors.RowNo = "Please enter your Row No.";
          } else {
            if (!value.toString().match(/^[0-9]+$/.exec(value))) {
              IsValid = false;
              errors.RowNo = "Please enter only number.";
              this.setState({ RowNo: 0 });
            }

          }
        }
      default:
        break;
    }
    return IsValid;
  }

  validateFormMobile(value, name) {
    let errors = this.state.formErrorMobile;
    let IsValid = true;
    switch (name) {
      case "city":
        if (value.length < 1) {
          IsValid = false;
          errors.city = "Please enter City name.";
        } else errors.city = "";
        break;
      case "DealerName":
        if (value.length < 1) {
          IsValid = false;
          errors.DealerName = "Please Enter Dealer Name.";
        } else errors.DealerName = "";
        break;
      case "DealerNo":
        if (value.length < 1) {
          IsValid = false;
          errors.DealerNo = "Please enter Dealer Contact no.";
        } else if (value.length >= 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = true;
            errors.DealerNo = "Please enter valid Contact no.";
          } else errors.DealerNo = "";
        } else errors.DealerNo = "";
        break;
      case "aadharImage":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.aadharImage = "Please upload Aadhar scan copy.";
        } else errors.aadharImage = "";
        break;
      case "aadharNo":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.aadharNo = "Please enter Aadhar Number.";
        } else if (!value.match(/^[2-9]{1}[0-9]{3}[0-9]{8}$/)) {
          IsValid = false;
          errors.aadharNo = "Please enter valid Aadhar Number.";
        } else errors.aadharNo = "";
        break;
      default:
        break;
    }

    return IsValid;
  }

  getDropValueOfRole() {
    let roleName = "";
    this.props.UserRoleData.filter((x) => {
      if (x.value === this.state.RoleId.toString()) {
        roleName = x.label;
      }
    });

    return roleName;
  }

  getDropValueOfUserType() {
    let usertype = "";
    this.state.UserTypeData.filter((x) => {
      if (x.value === this.state.UserTypeId) {
        usertype = x.label;
      }
    });

    return usertype;
  }

  submitForm(event) {
    ;
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });

    if (isfrmvalid) {
      // const ddlValu = this.getDropValue();
      const formData = {
        wareHouseId: this.state.wareHouseId,
        RowName: this.state.RowName,
        RowNo: this.state.RowNo,
        ColNo: this.state.ColNo,
        BinId: this.state.BinId,

        StoreType: this.state.StoreType,
        Height: this.state.Height,
        Width: this.state.Width,
        Length: this.state.Length,
        Valume: this.state.Valume,

      };
      //  this.props.createBin(formData)
      if (formData.BinId > 0) {
        ;
        this.props.updateBin(formData);

      } else {
        this.props.createBin(formData);
      }

      if (!this.props.showDialog) {
        this.resetForm();
      }

      setTimeout(() => {
        this.props.fetchApi(this.tblResponse);
      }, 1000);
      this.onHide();
      console.log(formData);
    }
  }

  // getRoleDDL(event) {
  //     this.props.getRoleDDL();
  // }
  resetForm() {
    this.setState({
      editing: false,
      RowName: "",
      RowNo: "",
      ColNo: "",

      StoreType: "",
      Height:"",
      Width:"",
      Length:"",
      Valume:"",

      IsActive: true,
      formError: {
        RowName: "",
        RowNo: "",
        ColNo: "",

        Storetype:"",
        Height:"",
        Width:"",
        Length:"",
        Valume:"",
      },
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key]) => {
      errors[key] = "";
      return errors[key];
    });
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);
    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  // modal open  & close----------
  onHide() {
    this.props.onHideShow(false);
    this.resetForm();
  }
  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add BIN" });
  }
  // modal open  & close--------------
  statusBodyTemplate(rowData) {
    if (rowData) {
      return (
        <Checkbox
          disabled
          checked={rowData.IsActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    }
  }
  getCurrentDateMMDDYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  getStatus(rowData) {
    var value = rowData?.ColStatus;
    // console.log('-----------------' + value)
    // console.log(rowData.ColStatus);
    if (value == 1) {
      return <span className="span-status full"> {'\u00A0'} Full{'\u00A0'}</span>;
    }
    {
      return (
        <span className="span-status empty">Empty </span>
      )
    }
  }
  // getDropValue() {
  //   let warehouseId = "";
  //   this.props.Warehouseddl.filter((x) => {
  //     if (x.warehouseId === this.state.warehouseId) {
  //       warehouseId = x.warehouseId;
  //     }
  //   });
  //   return warehouseId;
  // }

  actionBodyTemplate(rowData) {
    console.log(rowData)
    const editMode = (e) => {
      //const ddlValuRole = this.getDropValueOfRole();
      this.props.onHideShow(true);
      this.setState({
        buttonLabel: "Bin Update",
        formHeading: "Bin Address Update",
        BinId: rowData.BinId,
        WareHouseId: rowData.WareHouseId,
        RowName: rowData.RowName,
        RowNo: rowData.RowNo,
        ColNo: rowData.ColNo,
        StoreData: rowData.StoreData,
        Height: rowData.Height,
        Width: rowData.Width,
        Length: rowData.Length,
        Valume: rowData.Valume,
        ColStatus: rowData.ColStatus,
        IsActive: rowData.IsActive,
      });

    };
    const switchHandleChange = (e) => {
      
      this.setState({
        switchAction: !rowData?.IsActive,
        BinId: rowData.BinId,
      });
      this.props.onHideShowIsActive(true);
    };

    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Switch
                checked={rowData.IsActive}
                color="primary"
                name={rowData.BinId}
                onChange={switchHandleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Button icon="pi pi-pencil" onClick={editMode} />
            </div>
          ) : (
              ""
            )}
        </>
      );
    }
  }

  render() {

    const renderFooter = () => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.props.onHideShowIsActive(false)}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.switchToggleConfirm}
            autoFocus
          />
        </div>
      );
    };
    const header = (
      <div className="table-header row">
        <div className="col-md-6">
        <h4 className="user">Bin Master </h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>

          {this.state.IsAdd === true ? (
            <Button
              className="mr-2"
              label="Add Bin"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          ) : (
              <Button
                className="mr-2"
                label="Add Bin"
                icon="pi pi-plus-circle"
                style={{ display: "none" }}
                onClick={this.openDialog}
              />
            )}
          {/* <CSVLink
            data={this.props.List}
            headers={this.state.headers}
            filename={"Bin Report.csv"}
            target="_blank"
          >
            <button className="p-button p-component btn-success mr-2 ">
              Export
            </button>
          </CSVLink> */}
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />

        <div className="card">
          <DataTable
            value={this.props.BinList}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            // rowsPerPageOptions={[5, 10, 20, parseInt(this.state.totalRecords)]}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column style={{ width: '20%' }}excludeGlobalFilter field="RowName" header="Row Name" sortable></Column>
            <Column style={{ width: '15%' }} field="RowNo" header="Row No." sortable></Column>
            <Column style={{ width: '15%' }}field="ColNo" header="Col No." sortable></Column>
            <Column style={{ width: '15%' }}field="ColAddress" icon="pi pi-cube" header="Col Address" sortable> </Column>
            {/* <Column field="WareHouseName" icon="pi pi-cube" header="Warehouse Name" sortable> </Column> */}


            <Column style={{ width: '15%' }}
              body={this.getStatus}
              className="text-center"
              header="Bin Status" sortable></Column>

            {/* 
                       <Column     
            field="CompId"
             header="Com" sortable></Column> */}


            {this.state.IsEdit === true ? (
              <Column
                field="Action"
                header="Action"
                style={{ width: "20%" }}
                body={this.actionBodyTemplate}
              ></Column>

            ) : (
                <Column
                  field="Action"
                  header="Action"
                  style={{ width: "20%", display: "none" }}
                ></Column>
              )}
          </DataTable>
        </div>

        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          {/* open form */}
          <form onSubmit={this.submitForm} autocomplete="off">
            <div className="row">
              {/* <div className="col-md-6 pt-2">
              <FormControl
                variant="outlined"
                size="small"
                className="formControl col-md-12"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Warehouse Name
                </InputLabel>
                <Select
                  onChange={this.handleChange}
                  name="warehouseid"
                  value={this.state.warehouseid}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Select Inspector"
                >
                  <MenuItem>
                    <em>None</em>
                  </MenuItem>
                  {(typeof this.props.Warehouseddl == "undefined" ||
                    this.props.Warehouseddl == null) === true
                    ? null
                    : this.props.Warehouseddl?.map((key) => (
                        <MenuItem value={key.value}>{key.label}</MenuItem>
                      ))}
                </Select>
              </FormControl>
              {this.state.formError.warehouseId !== "" ? (
                <div className="error">{this.state.formError.warehouseId}</div>
              ) : (
                ""
              )}
            </div> */}
              {/* <div className="col-md-6 pt-2">
                <FormControl
                  variant="outlined"
                  size="small"
                  className="formControl col-md-12"
                >
                  <Dropdown
                    appendTo={document.getElementById("root")}
                    value={this.state.wareHouseId}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    options={this.props.Warehouseddl}
                    optionValue="value"
                    optionLabel="label"
                    className="form-control custom-select"
                    placeholder={"Select Warehouse "}
                    name="wareHouseId"
                    filter
                  />
                </FormControl>
                {this.state.formError.wareHouseId !== "" ? (
                  <div className="error">{this.state.formError.wareHouseId}</div>
                ) : (
                    ""
                  )}
              </div> */}

              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.RowName}
                  name="RowName"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Row Name *"
                />
                {this.state.formError.FromNo !== "" ? (
                  <div className="error">{this.state.formError.RoleName}</div>
                ) : (
                    ""
                  )}
              </div>

              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.RowNo}
                  name="RowNo"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Row Number *"
                />
                {this.state.formError.FromNo !== "" ? (
                  <div className="error">{this.state.formError.RowNo}</div>
                ) : (
                    ""
                  )}
              </div>

              <div className="col-md-6 pt-3">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.ColNo}
                  name="ColNo"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Column Number*"
                />
                {this.state.formError.ColNo !== "" ? (
                  <div className="error">{this.state.formError.ColNo}</div>
                ) : (
                    ""
                  )}
              </div>

              {/* <div className="col-md-6 pt-2">
                <FormControl
                  variant="outlined"
                  size="small"
                  className="formControl col-md-12"
                >
                  <Dropdown
                    appendTo={document.getElementById("root")}
                    value={this.state.StoreType}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    options={this.state.StoreData}
                    optionValue="value"
                    optionLabel="label"
                    className="form-control custom-select"
                    placeholder={"Store Type* "}
                    name="StoreType"
                    filter
                  />
                </FormControl>
                {this.state.formError.StoreType !== "" ? (
                  <div className="error">{this.state.formError.StoreType}</div>
                ) : (
                    ""
                  )}
              </div> */}


              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.Height}
                  name="Height"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Height(CM)*"
                />
                {this.state.formError.Height !== "" ? (
                  <div className="error">{this.state.formError.Height}</div>
                ) : (
                    ""
                  )}
              </div>

              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.Width}
                  name="Width"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Width(CM)*"
                />
                {this.state.formError.Width !== "" ? (
                  <div className="error">{this.state.formError.Width}</div>
                ) : (
                    ""
                  )}
              </div>

              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.Length}
                  name="Length"
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Length(CM)*"
                />
                {this.state.formError.Length !== "" ? (
                  <div className="error">{this.state.formError.Length}</div>
                ) : (
                    ""
                  )}
              </div>

              <div className="col-md-6 pt-2">
                <TextField
                  onChange={this.handleChange}
                  value={this.state.Valume}
                  name="Valume"
                  disabled
                  size="small"
                  variant="outlined"
                  className="fullwidth"
                  label="Valume(m^3)*"
                />
                {this.state.formError.Valume !== "" ? (
                  <div className="error">{this.state.formError.Valume}</div>
                ) : (
                    ""
                  )}
              </div>
              <div className="col-md-6 mt-4 ">
                <Button label={this.state.buttonLabel} onClick={this.submitForm} icon="pi pi-check"
                />
              </div>
            </div>
          </form>
        </Dialog>
           {/* Form Close */}
        <Dialog
          header="Confirmation"
          visible={this.props.showIsActiveDialog}
          modal
          style={{ width: "350px" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => this.props.onHideShowIsActive(false)}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3 confirm-icon"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to{" "}
              {this.state.switchAction == true ? "Active" : "InActive"}?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('UserData' , state.BinMaster.Binitems,)
  //console.log('User', JSON.parse(sessionStorage.getItem("UserData")))
  return {
    List: state.BinMaster.exportitems == undefined ? [] : state.user.exportitems,
    BinList: state.BinMaster.items,
    Warehouseddl: state.BinMaster.WarehouseDDL,
    showDialog: state.role.showDialog,
    loading: state.company.loding,
    showIsActiveDialog: state.BinMaster.showIsActiveDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(BinMasterActions.getAll(obj));
    },
    // fetchAllBinData: () => {
    //   dispatch(BinMasterActions.fetchAllBinData());
    // },
    createBin: (bin) => dispatch(BinMasterActions.createBin(bin)),
    updateBin: (bin) => dispatch(BinMasterActions.updateBin(bin)),
    inActiveRow: (bin, isActive) =>
      dispatch(BinMasterActions.inActiveRow(bin, isActive)),
    onHideShow: (isbool) => dispatch(BinMasterActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(BinMasterActions.onHideShowIsActive(isbool)),

    getWarehouseDDL: () => dispatch(BinMasterActions.getWarehouseDDL()),


    loadingData: (isLoding) =>
      dispatch(BinMasterActions.loadingData(isLoding)),
    exportingData: (obj) => dispatch(BinMasterActions.exportData(obj)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BinMaster);
