import { categoryConstants } from "../constants/categoryConstants";

export function category(state = {}, action) {
    switch (action.type) {
        case categoryConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case categoryConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
        case categoryConstants.DIALOG_REQUEST2:
            return {
                ...state,
                showDialog2: action.isbool
            };
        case categoryConstants.DIALOG_ISACTIVE_REQUEST:
            return {
                ...state,
                showIsActiveDialog: action.isbool
            };
        case categoryConstants.DIALOG_ISACTIVE_REQUEST2:
            return {
                ...state,
                showIsActiveDialog2: action.isbool
            };
        case categoryConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case categoryConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };

        case categoryConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };


        case categoryConstants.GETSUBALL_REQUEST:
            return {
                ...state,
                loading2: true
            };

        case categoryConstants.GETSUBALL_SUCCESS:
            return {
                ...state,
                subItems: action.datas
            };

        case categoryConstants.GETSUBALL_FAILURE:
            return {
                ...state,
                error: action.error
            };



        case categoryConstants.GETALL_AVAILABLE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case categoryConstants.GETALL_AVAILABLE_SUCCESS:
            return {
                ...state,
                Mappseries: action.datas,

            };
        case categoryConstants.GETALL_AVAILABLE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case categoryConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case categoryConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: state.items == null ? [action.data] : [action.data, ...state.items]
            };
        case categoryConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case categoryConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CategoryId === action.data.CategoryId ? { ...item, updating: true } : item
                )
            };
        case categoryConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CategoryId === action.data.CategoryId ? action.data : item
                )
            };
        case categoryConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case categoryConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case categoryConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CategoryId === action.data ? {
                        CategoryName: item.CategoryName,
                        isActive: item.isActive,
                    } : item
                )
            };
        case categoryConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case categoryConstants.ISACTIVE_REQUEST:
            return {
                ...state,
                activating: true
            };
        case categoryConstants.ISACTIVE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.CategoryId === action.data.CategoryId ? {
                        CategoryName: item.CategoryName,
                        FilePath: item.FilePath,
                        TotalRows: item.TotalRows,
                        IsActive: action.data.IsActive,
                        CreatedDate: item.CreatedDate
                    } : item
                )
            };
        case categoryConstants.ISACTIVE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case categoryConstants.ISACTIVE2_REQUEST:
            return {
                ...state,
                activating: true
            };
        case categoryConstants.ISACTIVE2_SUCCESS:
            return {
                ...state,
                subItems: state.subItems.map(item =>
                    item.SubCatId === action.data.SubCatId ? {
                        CategoryName: item.CategoryName,
                        SubCategoryName: item.SubCategoryName,
                        SubCateFilePath: item.SubCateFilePath,
                        TotalRows: item.TotalRows,
                        IsActive: action.data.IsActive,
                        CreatedDate: item.CreatedDate
                    } : item
                )
            };
        case categoryConstants.ISACTIVE2_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case categoryConstants.GETCATEGORYDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case categoryConstants.GETCATEGORYDDL_SUCCESS:
            
            return {
                ...state,
                categoryddl: action.datas
            };
        case categoryConstants.GETCATEGOYRDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };

        // DELETE_REQUEST:

        case categoryConstants.GETSUBCATEGORYDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case categoryConstants.GETSUBCATEGORYDDL_SUCCESS:
            return {
                ...state,
                subcategoryddl: action.datas
            };
        case categoryConstants.GETSUBCATEGOYRDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}